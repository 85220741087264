import { Backdrop, Box, Grid, ListItemIcon, useTheme } from "@mui/material";
import {
  Item,
  ItemContainer,
  ItemName,
} from "../../styles/assets/ListInlineView.styles";
import { getIconCreationType } from "./FavoriteTypesAccordion";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { useCallback, useRef, useState } from "react";
import { LongPressEventType, useLongPress } from "use-long-press";
import LongPressTypesItemMenu from "./LongPressTypesItemMenu";
import { getSvgIcon } from "../../../util/icons";
import FavoriteStarComponent from "../../assets/asset-list/FavoriteStarComponent";

const TypeListInlineViewItem = ({ row, handleClickItem }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const currentTheme = useSelector(selectTheme);

  // State
  const [anchorEl, setAnchorEl] = useState(null);
  const [longPressed, setLongPressed] = useState(false);

  // Refs
  const elementRef = useRef();

  // Callbacks
  const callback = useCallback(() => {
    handleOpen();
  }, []);

  // Longpress
  const bind = useLongPress(row?.organizationId ? callback : null, {
    filterEvents: (event) => true, // All events can potentially trigger long press
    threshold: 1000,
    captureEvent: true,
    cancelOnMovement: false,
    cancelOutsideElement: false,
    detect: LongPressEventType.Pointer,
  });

  // Other variables
  const { id, name, organizationId, isFavorite } = row;
  const handlers = bind("longpress context");
  const open = longPressed;

  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleOpen = () => {
    setAnchorEl(elementRef?.current);
    setLongPressed(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLongPressed(false);
  };

  return (
    <>
      {open && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={open}
          onClick={handleClose}
        />
      )}

      <LongPressTypesItemMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        typeId={row?.id}
      />

      <Grid item xs={0} sm={1}></Grid>

      <ItemContainer item xs={36} sm={10} longPressed={open}>
        <Item
          key={`resource-${id}`}
          ref={elementRef}
          onClick={() => handleClickItem(id)}
          {...handlers}
        >
          <Box sx={{ position: "relative" }}>
            <ListItemIcon>
              {getIconCreationType(organizationId, globalFontSize, theme)}
            </ListItemIcon>

            {isFavorite && (
              <FavoriteStarComponent
                bottom="-8px"
                right="8px"
                multiplier={1.2}
              />
            )}
          </Box>

          <ItemName>{name}</ItemName>

          {getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.primary.main
          )}
        </Item>
      </ItemContainer>

      <Grid item xs={0} sm={1}></Grid>
    </>
  );
};

export default TypeListInlineViewItem;
