import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getTranslation } from "../../../../../util/utils";
import {
  selectImportStrategy,
  setImportStrategy,
} from "../../../../../store/slices/importExportSlice";

const AssetImportWizardThirdStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const importStrategy = useSelector(selectImportStrategy);

  // Handlers
  const handleChangeImportStrategy = (e) => {
    dispatch(setImportStrategy(e.target.value));
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      <FormLabel
        sx={{ color: theme.palette.text.secondary }}
        id="demo-controlled-radio-buttons-group"
      >
        {getTranslation("IMPORT_SELECT_OVERWRITE_OR_SKIP", t, i18n)}
      </FormLabel>

      <FormControl fullWidth>
        <RadioGroup
          sx={{ alignItems: "start", marginTop: "20px" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={importStrategy}
          onChange={handleChangeImportStrategy}
        >
          <FormControlLabel
            value="overwrite"
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("OVERWRITE_IMPORT_OPTION", t, i18n)}
          />

          <FormControlLabel
            value="skip"
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("SKIP_IMPORT_OPTION", t, i18n)}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default AssetImportWizardThirdStep;
