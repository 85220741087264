import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
  styled,
} from "@mui/material";

export const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.secondary.active : "transparent",
  display: "block",
}));

export const StyledListItemButton = styled(ListItemButton)(
  ({ isdraweropen }) => ({
    minHeight: "48px",
    justifyContent: isdraweropen ? "initial" : "center",
    paddingInline: "12px",
  })
);

export const StyledListItemIcon = styled(ListItemIcon)(({ isdraweropen }) => ({
  minWidth: 0,
  justifyContent: "center",
}));

export const StyledListItemText = styled(ListItemText)(
  ({ theme, active, isdraweropen }) => ({
    opacity: isdraweropen ? 1 : 0,
    marginLeft: isdraweropen ? "16px" : "auto",
    color: active ? theme.palette.secondary.contrastText : "",
  })
);
