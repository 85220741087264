import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import PageTransition from "../../components/PageTransition";
import { transitionDirections } from "../../util/utils";
import ReportsHeader from "../../navigation/header/reports/ReportsHeader";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import DesktopReportsHeader from "../../navigation/header/reports/DesktopReportsHeader";
import ReportTableList from "../../components/reports/ReportTableList";
import ReportDetails from "../../components/reports/ReportDetails";
import {
  resetWizard,
  selectIsReportWizardDialogOpen,
  selectMobileDialogOpen,
} from "../../store/slices/reportsSlice";
import ReportsWizard from "./ReportsWizard";
import { useDispatch } from "react-redux";
import { selectReportId, setReportId } from "../../store/slices/reportSlice";
import MobileCreateReportDialog from "../../components/reports/mobile-view/MobileCreateReportDialog";
import ReportsStickySearch from "../../components/reports/mobile-view/ReportsStickySearch";
import ReportList from "../../components/reports/ReportList";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const ReportsPage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const isReportsWizardDialogOpen = useSelector(selectIsReportWizardDialogOpen);
  const reportId = useSelector(selectReportId);
  const mobileDialogOpen = useSelector(selectMobileDialogOpen);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [fullscreen, setFullscreen] = useState(false);

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const iconSize = globalFontSize;

  // Handlers
  const goBackHandler = () => {
    navigate(`/more?direction=${transitionDirections.LEFT_TO_RIGHT}`);
  };

  const handleToggleFullscreen = () => {
    setFullscreen(!fullscreen);
  };

  const handleResetSelected = () => {
    dispatch(setReportId(null));
    setFullscreen(false);
  };

  const handleCloseDialog = () => {
    dispatch(resetWizard());
  };

  return (
    <AppAccess>
      <Layer2Access>
        <PageTransition direction={transitionDirection}>
          {mobileMatches ? (
            <ReportsHeader goBackHandler={goBackHandler} />
          ) : (
            <DesktopReportsHeader />
          )}

          <HomePagePadding>
            {!mobileMatches ? (
              <>
                {isReportsWizardDialogOpen && (
                  <Dialog
                    sx={{ "& .MuiDialogContent-root": { padding: 0 } }}
                    fullWidth={true}
                    maxWidth={false}
                    open={isReportsWizardDialogOpen}
                    // onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <IconButton
                      id="close-reports-dialog"
                      aria-label="close"
                      onClick={handleCloseDialog}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        zIndex: 1,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      {getSvgIcon(
                        "CLEAR",
                        iconSize,
                        iconSize,
                        theme.palette.secondary.dark
                      )}
                    </IconButton>
                    <DialogContent>
                      <ReportsWizard />
                    </DialogContent>
                  </Dialog>
                )}

                <Grid
                  container
                  sx={{ paddingInline: "16px", overflowY: "hidden" }}
                  columnSpacing={2}
                >
                  {!fullscreen && (
                    <>
                      <Grid
                        item
                        xs
                        sx={{
                          height: "calc(100vh - 144px)",
                          overflow: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        <ReportTableList />
                      </Grid>

                      <Grid item>
                        <Divider
                          sx={{ height: "100%" }}
                          orientation="vertical"
                          flexItem
                        />
                      </Grid>
                    </>
                  )}

                  <Grid
                    item
                    xs
                    sx={{
                      overflow: "hidden",
                    }}
                  >
                    {reportId && (
                      <ReportDetails
                        key={reportId} // used to identify the change of reportId and successfully unmount
                        reportId={reportId}
                        fullscreen={fullscreen}
                        handleToggleFullscreen={handleToggleFullscreen}
                        handleResetSelected={handleResetSelected}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <Stack rowGap="8px" sx={{ marginBottom: "64px" }}>
                <ReportsStickySearch />
                <ReportList />
                {mobileDialogOpen && <MobileCreateReportDialog />}
              </Stack>
            )}
          </HomePagePadding>
        </PageTransition>
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportsPage;
