import { ListItem, Typography, styled } from "@mui/material";

export const PrimaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const SecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const SecondaryDarkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

export const SectionTitle = styled(SecondaryText)(({ istablet, theme }) => ({
  color: theme.palette.primary.main,
  margin: istablet === 1 ? 0 : "auto",
  textAlign: istablet === 1 ? "left" : "center",
}));
