import {
  Box,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from "@mui/material";
import { styled } from "@mui/system";

export const StyledAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  marginTop: "8px",
  backgroundColor: "transparent",
});

export const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: 0,
  paddingInline: theme.spacing(1),
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export const StyledAccordionDetails = styled(MuiAccordionDetails)(
  ({ theme }) => ({
    padding: theme.spacing(0),
  })
);

export const SplitScreen = styled(Grid)({
  height: "calc(100vh - 128px)",
  overflowY: "auto",
});

export const FirstScreenContainer = styled(SplitScreen)(({ theme }) => ({
  paddingInline: "16px",
  borderRight: `1px solid ${theme.palette.divider}`,
}));

export const AccordionHeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export const LoadMoreWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "24px",
  padding: "8px",
});
