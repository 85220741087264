import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { a11yAssetListTabProps } from "../../../pages/assets-pages/AssetList";
import {
  useGetAllCharacteristicsQuery,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import {
  selectGlobalFontSize,
  selectPageView,
  setPageView,
} from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import CustomTabPanel from "../../CustomTabPanel";
import {
  CreateAssetButton,
  NoAssetsContainer,
} from "../../styles/assets/AssetList.styles";
import {
  LightGrayText,
  SecondaryText,
} from "../../styles/assets/ListInlineView.styles";
import AssetDetailList from "../asset-detail/AssetDetailList";
import AssetListRenderer from "./AssetListRenderer";
import GraphicalRackView from "../graphical-rack-view/GraphicalRackView";
import Heading from "./Heading";
import StickySearch from "./StickySearch";
import {
  selectCarouselIndex,
  selectItems,
  selectMobileTabValue,
  setCarouselIndex,
  setIsGraphicalViewLoading,
  setMobileTabValue,
} from "../../../store/slices/assetListSlice";
import { MobileHeaderContainer } from "../../styles/assets/asset-list/MobileHeader.styles";
import ErrorHandling from "../../common/ErrorHandling";
import CategoryMenu from "../asset-form/CategoryMenu";
import { Box, useTheme } from "@mui/material";
import {
  MobileTab,
  MobileTabs,
} from "../../styles/assets/asset-list/AssetListTabs.styles";
import CarouselSlider from "../asset-detail/CarouselSlider";
import { getSvgIcon } from "../../../util/icons";
import { useGetResourceImagesQuery } from "../../../store/slices/api/resourceImagesApiSlice";

const MobileHeader = ({ resourceid }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  // Selectors
  const currentpageview = useSelector(selectPageView);
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const globalFontSize = useSelector(selectGlobalFontSize);
  const mobileTabValue = useSelector(selectMobileTabValue);
  const carouselIndex = useSelector(selectCarouselIndex);
  const items = useSelector(selectItems);

  // States
  const [anchorEl, setAnchorEl] = useState(null);

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = useGetResourceDetailsQuery(
    {
      resourceid,
      organizationId,
    },
    {
      skip: !Boolean(resourceid),
    }
  );

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    isError: isErrorUserRoles,
  } = useUserRolePermissionsQuery({
    userId: user.id,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  const { data: resourceImagesData, isLoading: isLoadingResourceImages } =
    useGetResourceImagesQuery(
      { resourceid: resourceid, organizationId },
      { skip: !resourceid }
    );

  // Other variables
  const comingFrom = searchParams.get("comingFrom");

  const currentResourceFunction = allFunctionsData?.find(
    (f) => f.id === currentResourceData.functionId
  );

  const openCategoryMenu = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const checkHasRackUnits = () => {
    const rackUnitsCharacteristicId = characteristicDefinitionsData?.find(
      (c) => c.name === "RACK_UNIT_CAPACITY"
    )?.id;

    const resourceRackUnits = currentResourceData?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const typeRackUnits = currentResourceData?.type?.characteristics.find(
      (c) => c.id === rackUnitsCharacteristicId
    );

    const rackUnits = parseInt(
      resourceRackUnits?.value || typeRackUnits?.value
    );

    return rackUnits > 0;
  };

  const handleChange = (event, newValue) => {
    dispatch(setMobileTabValue(newValue));
    dispatch(setCarouselIndex(0));
    dispatch(setIsGraphicalViewLoading(true));
  };

  const handleNavigateToCreateAsset = (category) => {
    if (currentResourceData) {
      const { id } = currentResourceData;

      navigate(
        `/resources/add-new?resourceid=${id}&category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    } else {
      navigate(
        `/resources/add-new?category=${category}&direction=${transitionDirections.BOTTOM_TO_TOP}`
      );
    }
  };

  const formatClickHandler = (value) => {
    dispatch(setPageView(value));
  };

  const handleImageChange = (oldIndex, newIndex) => {
    dispatch(setCarouselIndex(newIndex));
  };

  const handleOpenCategoryMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseCategoryMenu = () => {
    setAnchorEl(null);
  };

  // Effects
  useEffect(() => {
    if (
      comingFrom !== "add-image" &&
      comingFrom !== "edit-image" &&
      comingFrom !== "add-attachment" &&
      comingFrom !== "edit-attachment" &&
      comingFrom !== "edit-tags"
    ) {
      if (currentResourceData?.childrenCount <= 0) {
        dispatch(setMobileTabValue(1));
      } else {
        dispatch(setMobileTabValue(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResourceData?.childrenCount, comingFrom]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingCharacteristicDefinitions ||
        isLoadingCurrent ||
        isLoadingFunctions ||
        isLoadingUserRoles ||
        isLoadingResourceImages
      }
      isError={
        isErrorCharacteristicDefinitions ||
        isErrorCurrent ||
        isErrorFunctions ||
        isErrorUserRoles
      }
    >
      <MobileHeaderContainer mobiletabvalue={mobileTabValue}>
        {/* Assets page header */}
        <Heading
          resourceid={currentResourceData?.id}
          resourceName={
            currentResourceData?.displayId || currentResourceData?.name
          }
          category={currentResourceFunction?.category}
          currentResourceFunction={currentResourceFunction}
          userRoles={userRoles}
          functionName={currentResourceFunction?.name}
          thumbnailUri={currentResourceData?.thumbnailUri}
          isFavorite={currentResourceData?.isFavorite}
        />

        {/* Tabs logic */}
        <Box>
          <MobileTabs
            value={mobileTabValue}
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons="auto"
          >
            <MobileTab
              active={mobileTabValue === 0}
              label={getTranslation("CONTENT", t, i18n)}
              {...a11yAssetListTabProps(0)}
            />

            <MobileTab
              active={mobileTabValue === 1}
              label={getTranslation("DETAILS", t, i18n)}
              {...a11yAssetListTabProps(1)}
            />

            {currentResourceFunction?.category === RESOURCE_CATEGORIES.RACK &&
              checkHasRackUnits() && (
                <MobileTab
                  active={mobileTabValue === 2}
                  label={getTranslation("GRAPHICAL_VIEW", t, i18n)}
                  {...a11yAssetListTabProps(2)}
                />
              )}
          </MobileTabs>
        </Box>

        {/* Assets section */}
        <CustomTabPanel value={mobileTabValue} index={0}>
          <CategoryMenu
            open={openCategoryMenu}
            handleClose={handleCloseCategoryMenu}
            anchorEl={anchorEl}
            parentCategory={currentResourceFunction?.category}
            categoryActionHandler={handleNavigateToCreateAsset}
          />
          <StickySearch
            currentpageview={currentpageview}
            formatClickHandler={formatClickHandler}
            from="resource-child"
            resourceData={currentResourceData}
          />
          {currentpageview !== "column" && items?.length <= 0 && (
            <NoAssetsContainer resourceid={resourceid}>
              <SecondaryText align="center">
                {getTranslation("NO_ASSETS", t, i18n)}
              </SecondaryText>
              <CreateAssetButton
                disabled={
                  !hasAccess(
                    "all",
                    [
                      permissions.ASSET_MANAGEMENT_HARDWARE_ADD,
                      permissions.ASSET_MANAGEMENT_LOCATION_ADD,
                      permissions.ASSET_MANAGEMENT_RACK_ADD,
                    ],
                    getPermissionsFromUserRoles(userRoles)
                  )
                }
                onClick={handleOpenCategoryMenu}
              >
                <div style={{ marginRight: "5px", marginTop: "5px" }}>
                  {getSvgIcon(
                    "CREATE_NEW",
                    iconSize,
                    iconSize,
                    theme.palette.primary.contrastText
                  )}
                </div>

                {getTranslation("CREATE_FIRST_ASSET", t, i18n)}
              </CreateAssetButton>
            </NoAssetsContainer>
          )}
          <AssetListRenderer resourceid={resourceid} />
        </CustomTabPanel>

        {/* Details section */}
        <CustomTabPanel value={mobileTabValue} index={1}>
          <CarouselSlider resourceImages={resourceImagesData} />

          <AssetDetailList resourceData={currentResourceData} />
        </CustomTabPanel>

        {/* Graphical view section */}
        {currentResourceFunction?.category === RESOURCE_CATEGORIES.RACK && (
          <CustomTabPanel value={mobileTabValue} index={2}>
            {checkHasRackUnits() ? (
              <GraphicalRackView currentResourceData={currentResourceData} />
            ) : (
              <NoAssetsContainer resourceid={resourceid}>
                <LightGrayText
                  align="center"
                  margin="auto"
                  width="calc(100% - 56px)"
                >
                  {getTranslation(
                    "GRAPHICAL_REPRESENTATION_NOT_AVAILABLE",
                    t,
                    i18n
                  )}
                </LightGrayText>
              </NoAssetsContainer>
            )}
          </CustomTabPanel>
        )}
      </MobileHeaderContainer>
    </ErrorHandling>
  );
};

export default MobileHeader;
