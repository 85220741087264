import { Typography, Box, Button, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { Doughnut } from "react-chartjs-2";

export const ChartsContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "8px",
  gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",

    gridAutoRows: "1fr",
  },
}));

export const DarkBackgroundWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.active,
  minHeight: "180px",
  paddingBottom: "8px",
  cursor: "pointer",
}));

export const ReportName = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.text.primary,
    textAlign: "center",
    marginBottom: "5px",
  })
);

export const BarChartContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  height: "100%",
});

export const NavigateToCreateAssetPageButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "center",
  margin: "auto",
}));

export const ReportSecondaryText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  paddingInline: "8px",
}));

export const ReportContentContainer = styled(Box)({
  padding: "8px",
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  alignItems: "end",
  justifyContent: "end",
  wordWrap: "break-word",
  wordBreak: "break-all",
});

export const StyledDoughnut = styled(Doughnut)({
  maxHeight: "150px",
});
