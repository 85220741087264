import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import AdvancedSearchQueryBuilder from "../advanced-search/AdvancedSearchQueryBuilder";
import { selectUser } from "../../../store/slices/authSlice";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import {
  BASIC_FIELDS,
  REPORT_FIELDS,
  checkQueryValidity,
  parseQueryExecute,
} from "../../../util/reports-utils";
import {
  selectQuery,
  selectShouldExecute,
  selectTypeId,
  setQueryDefinitionTablePage,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import QueryDefinitionPreviewTable from "./QueryDefinitionPreviewTable";
import { useDispatch } from "react-redux";
import ErrorHandling from "../../common/ErrorHandling";
import BounceLetterSpinner from "../../common/BounceLetterSpinner";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  DefineQueryWizardStepWrapper,
  ReportPreviewTypography,
  ReportPreviewWrapper,
  ReportRecordsFoundTypography,
  ReportRefreshButton,
} from "../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { useEffect } from "react";
import { messageError } from "../../../util/notification";

const DefineQueryWizardStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const shouldExecute = useSelector(selectShouldExecute);
  const typeId = useSelector(selectTypeId);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const fields = BASIC_FIELDS.map((field, index) => ({ ...field, index }));
  const filters = [parseQueryExecute(query)];
  const isQueryValid = checkQueryValidity(query);
  const iconSize = globalFontSize;

  // Queries
  const {
    data: reportExecutionData,
    isLoading,
    isFetching,
    isError,
  } = useReportExecutionBaseQuery(
    {
      organizationId,
      typeId,
      fields,
      filters,
      aggregationResultContent:
        "?aggregation-result-content=GROUP_DETAIL&aggregation-result-content=AGGREGATION",
    },
    { skip: !shouldExecute }
  );

  const { data: reportExecutionResourcesCountData } =
    useReportExecutionBaseQuery({
      organizationId,
      typeId,
      fields,
      aggregations: [
        {
          field: { name: REPORT_FIELDS.ID },
          aggregationType: "COUNT",
        },
      ],
      aggregationResultContent: "?aggregation-result-content=AGGREGATION",
    });

  // Other variables
  const resourcesCount = reportExecutionResourcesCountData?.data[0]
    ? reportExecutionResourcesCountData?.data[0][
        `${REPORT_FIELDS.ID}.aggregation-count`
      ]
    : 0;

  // Handlers
  const handleLoadPreview = () => {
    dispatch(setShouldExecute(true));
    dispatch(setQueryDefinitionTablePage(0));
  };

  // Effects
  useEffect(() => {
    if (isError) {
      messageError(getTranslation("REPORT_FAILED_EXECUTION", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (reportExecutionData?.totalRecord <= 0) {
      messageError(getTranslation("TABLE_NO_RECORDS", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportExecutionData]);

  return (
    <ErrorHandling isLoading={false} isError={false}>
      <DefineQueryWizardStepWrapper>
        <Box>
          <AdvancedSearchQueryBuilder />

          {reportExecutionData && (
            <ReportRecordsFoundTypography variant="body">
              {getTranslation("REPORT_RECORDS_FOUND", t, i18n)
                .replace(
                  "{0}",
                  reportExecutionData?.data[0]?.subRows?.length ?? 0
                )
                .replace("{1}", resourcesCount)}
            </ReportRecordsFoundTypography>
          )}
        </Box>

        <Box>
          <ReportPreviewWrapper>
            <ReportPreviewTypography variant="body">
              {getTranslation("REPORT_PREVIEW", t, i18n)}
            </ReportPreviewTypography>

            <ReportRefreshButton
              id="refresh-preview"
              disabled={!isQueryValid || shouldExecute}
              onClick={handleLoadPreview}
              variant="text"
            >
              {getSvgIcon(
                "REFRESH",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText,
                { marginRight: "5px" }
              )}
              {getTranslation("REPORT_REFRESH", t, i18n)}
            </ReportRefreshButton>
          </ReportPreviewWrapper>

          {isLoading || isFetching ? (
            <BounceLetterSpinner />
          ) : (
            reportExecutionData?.data &&
            reportExecutionData.data.length > 0 && (
              <QueryDefinitionPreviewTable rows={reportExecutionData?.data} />
            )
          )}
        </Box>
      </DefineQueryWizardStepWrapper>
    </ErrorHandling>
  );
};

export default DefineQueryWizardStep;
