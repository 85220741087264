import SectionHeader from "../../components/SectionHeader";
import ListInlineView from "../../components/assets/asset-list/ListInlineView";
import { useGetRecentsQuery } from "../../store/slices/api/assetManagementSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import { SecondaryText } from "../../components/styles/assets/ListInlineView.styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { CreateAssetButton } from "../../components/styles/assets/AssetList.styles";
import ErrorHandling from "../../components/common/ErrorHandling";
import { Box } from "@mui/material";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const RecentsSection = () => {
  // General hooks
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  // Queries
  const {
    data: recentsData,
    isLoading: isLoadingRecents,
    isError: isErrorRecents,
  } = useGetRecentsQuery(
    {
      organizationId,
      index: 1,
      size: 5,
      order: "desc",
      sortBy: "createdAt",
    },
    {
      skip: !Boolean(organizationId),
    }
  );

  // Handlers
  const handleNavigateToRecents = () => navigate("/recents");
  const handleNavigateToAssets = () => navigate("/resources");

  return (
    <ErrorHandling isLoading={false} isError={isErrorRecents}>
      <SectionHeader
        title="RECENTS"
        titleId="recentsHeader"
        actionName="OPEN_RECENTS"
        actionNameId="openRecentsHeader"
        action={handleNavigateToRecents}
        actionNameVisible={recentsData?.totalRecord > 0}
      />

      <Box sx={{ position: "relative" }}>
        {recentsData?.totalRecord <= 0 && (
          <>
            <SecondaryText align="center">
              {getTranslation("NO_RECENTS", t, i18n)}
            </SecondaryText>
            <CreateAssetButton onClick={handleNavigateToAssets}>
              {getTranslation("OPEN_ASSETS", t, i18n)}
            </CreateAssetButton>
          </>
        )}

        <div id="recents-list">
          <ListInlineView
            list={recentsData?.data}
            index={1}
            fetchMoreData={() => {}}
            resourcesData={{}}
            isHome={true}
          />
        </div>

        {isLoadingRecents && <LoadingSpinner />}
      </Box>
    </ErrorHandling>
  );
};

export default RecentsSection;
