import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { useState } from "react";
import { useUserRolePermissionsQuery } from "../../../store/slices/api/userManagementSlice";
import { useDeleteTypeMutation } from "../../../store/slices/api/typesApiSlice";
import {
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  transitionDirections,
} from "../../../util/utils";
import { messageError, messageSuccess } from "../../../util/notification";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { getSvgIcon } from "../../../util/icons";
import EditType from "../type-form/EditType";
import { useDispatch } from "react-redux";
import { deleteItem, setTypeId } from "../../../store/slices/typeSlice";

const LongPressTypesItemMenu = ({ anchorEl, handleClose, typeId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const navigate = useNavigate();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);

  // State
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  // Other variables
  const userId = user?.id;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const open = Boolean(anchorEl);
  const iconSize = globalFontSize * 1.5;

  // Queries
  const { data: userRoles } = useUserRolePermissionsQuery({
    organizationId,
    userId,
  });

  // Mutations
  const [deleteType] = useDeleteTypeMutation();

  // Other variables
  const alert = {
    content: getTranslation("TYPE_DELETE_MESSAGE", t, i18n),
    confirmTitle: getTranslation("DELETE_TYPE", t, i18n),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  // Handlers
  const handleEdit = () => {
    if (mobileMatches) {
      navigate(
        `/library/${typeId}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}&previousPage=type-details`
      );
    } else {
      handleClose();
      setOpenEdit(true);
    }
  };

  const handleOpenDeleteConfirm = () => {
    handleClose();
    setOpenConfirm(true);
  };

  const deleteConfirm = async () => {
    try {
      dispatch(setTypeId(null));

      await deleteType({
        typeId,
        organizationId,
      }).unwrap();

      dispatch(deleteItem(typeId));

      messageSuccess(getTranslation("successfulDeleteType", t, i18n));
    } catch (error) {
      const { error: errorPlaceholder } = error.data;

      messageError(
        `${getTranslation("failedDeleteType", t, i18n)} ${
          errorPlaceholder === "RELATED_RESOURCE_RECORD"
            ? getTranslation("RELATED_RESOURCE_RECORD", t, i18n)
            : ""
        }`
      );

      dispatch(setTypeId(typeId));
    }
  };

  return (
    <>
      {openConfirm && (
        <ConfirmAlert
          isOpen={openConfirm}
          setIsOpen={setOpenConfirm}
          alert={alert}
          handleConfirm={deleteConfirm}
          label="delete"
        />
      )}

      {open && (
        <Menu
          sx={{
            "& .MuiMenu-paper": {
              width: "250px",
              maxWidth: "100%",
              borderRadius: "5px",
              marginTop: "4px",
              marginRight: "4px",
              left: mobileMatches ? "10px !important" : "16px",
            },
          }}
          id="longpress-types-item-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          elevation={2}
        >
          <MenuList sx={{ padding: 0 }}>
            {hasAccess(
              "all",
              [permissions.ASSET_MANAGEMENT_TYPE_EDIT],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  {getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </ListItemIcon>

                <ListItemText
                  sx={{ color: theme.palette.secondary.contrastText }}
                >
                  {getTranslation("EDIT", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}

            {hasAccess(
              "all",
              [permissions.ASSET_MANAGEMENT_TYPE_DELETE],
              getPermissionsFromUserRoles(userRoles)
            ) && (
              <MenuItem onClick={handleOpenDeleteConfirm}>
                <ListItemIcon>
                  {getSvgIcon(
                    "DELETE",
                    iconSize,
                    iconSize,
                    theme.palette.error.main,
                    { marginRight: "10px" }
                  )}
                </ListItemIcon>

                <ListItemText sx={{ color: theme.palette.error.dark }}>
                  {getTranslation("DELETE", t, i18n)}
                </ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      {openEdit && (
        <EditType typeId={typeId} open={openEdit} setOpen={setOpenEdit} />
      )}
    </>
  );
};

export default LongPressTypesItemMenu;
