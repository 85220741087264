import { Stack } from "@mui/material";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";

const SingleValueWidgetContent = ({ value }) => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <SecondaryText fontWeight="bolder">{value}</SecondaryText>
    </Stack>
  );
};

export default SingleValueWidgetContent;
