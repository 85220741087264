import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../util/utils";

const AssetExportWizardFinalStep = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: "40px" }}>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        {getTranslation("EXPORT_SUCCESSFUL", t, i18n)}
      </Typography>
    </Box>
  );
};

export default AssetExportWizardFinalStep;
