import { styled } from "@mui/system";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { EllipseText } from "../home/Home.styles";

export const OrganizationMenuContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: "center",
  fontSize: theme.typography.subtitle2.fontSize,
  color: "#5D5D5D",
  fontWeight: "bold",
}));

export const ListContainer = styled(List)({
  width: "100%",
});

export const OrganizationItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
}));

export const OrganizationItemText = styled(ListItemText)({
  marginLeft: "20px",
  display: "flex",
  justifyContent: "center",
  "& > .MuiTypography-root": {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

export const DialogDivider = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  height: "10px",
}));

export const DialogCancelButton = styled(Button)(({ theme }) => ({
  margin: "auto",
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  width: "100%",
  color: theme.palette.secondary.contrastText,
}));

export const OrganizationNameText = styled(EllipseText)(({ theme }) => ({
  color: theme.palette.primary.main,
  flexGrow: 1,
}));

export const OrganizationNameContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  width: "100%",
});

export const DropDownButton = styled(IconButton)({
  paddingRight: 0,
});
