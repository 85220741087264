import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import app from "./slices/appSlice";
import auth from "./slices/authSlice";
import onboarding from "./slices/onboardingSlice";
import invitations from "./slices/invitationsSlice";
import resourceImage from "./slices/resourceImageSlice";
import resourceInput from "./slices/resourceInputSlice";
import typeInput from "./slices/typeInputSlice";
import resourceAttachment from "./slices/resourceAttachmentSlice";
import typeSearch from "./slices/typeSearchSlice";
import columnView from "./slices/columnViewSlice";
import tags from "./slices/tagsSlice";
import assetList from "./slices/assetListSlice";
import floorplan from "./slices/floorplanSlice";
import undoRedo from "./slices/undoRedoSlice";
import undoRedoGraphicalView from "./slices/undoRedoGraphicalViewSlice";
import tooltip from "./slices/tooltipSlice";
import reports from "./slices/reportsSlice";
import typeSlice from "./slices/typeSlice";
import report from "./slices/reportSlice";
import longPress from "./slices/longPressSlice";
import importExport from "./slices/importExportSlice";
import { baseApiSlice } from "./slices/api/baseApiSlice";

export const reducers = combineReducers({
  app,
  auth,
  onboarding,
  invitations,
  resourceImage,
  resourceInput,
  typeInput,
  resourceAttachment,
  typeSearch,
  columnView,
  tags,
  assetList,
  floorplan,
  undoRedo,
  undoRedoGraphicalView,
  tooltip,
  reports,
  report,
  longPress,
  importExport,
  type: typeSlice,
  [baseApiSlice.reducerPath]: baseApiSlice.reducer,
});

const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      baseApiSlice.middleware
    ),
});

export default store;
