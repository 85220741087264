import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Grid, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import CharacteristicItem from "./CharacteristicItem";
import {
  groupCharacteristics,
  mergeCharacteristics,
} from "../../../util/asset-utils";
import { getTranslation } from "../../../util/utils";
import ErrorHandling from "../../common/ErrorHandling";
import AssetDetailCharacteristicGroupAccordion from "./AssetDetailCharacteristicGroupAccordion";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import AssetDetailLocation from "./AssetDetailLocation";
import { getSvgIcon } from "../../../util/icons";

const AssetDetailCharacteristicGroup = ({
  resourceCharacteristics,
  typeCharacteristics,
  functionId,
  resourceParentId,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // States
  const [textWidth, setTextWidth] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [iconWidth, setIconWidth] = useState(0);
  const [borderWidth, setBorderWidth] = useState("25%");

  // Callbacks
  const textRef = useCallback((node) => {
    if (node) {
      setTextWidth(node.offsetWidth);
      setTotalWidth(node.parentElement.offsetWidth);
    }
  }, []);

  const iconRef = useCallback((node) => {
    if (node) {
      setIconWidth(node.offsetWidth);
    }
  }, []);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: characteristicSetTemplatesData,
    isLoading: isLoadingCharacteristicSetTemplates,
    isError: isErrorCharacteristicSetTemplates,
  } = useGetCharacteristicSetTemplatesQuery({
    resourceFunctionId: functionId,
    organizationId,
  });

  const {
    data: characteristicDefinitionsData,
    isLoading: isLoadingCharacteristicDefinitions,
    isError: isErrorCharacteristicDefinitions,
  } = useGetAllCharacteristicsQuery({ organizationId });

  // Other variables
  const leftPadding = 16;

  const characteristics = useMemo(
    () =>
      mergeCharacteristics(
        resourceCharacteristics,
        typeCharacteristics,
        characteristicDefinitionsData
      ),
    [
      resourceCharacteristics,
      typeCharacteristics,
      characteristicDefinitionsData,
    ]
  );

  const { setTemplates, other } = useMemo(
    () => groupCharacteristics(characteristics, characteristicSetTemplatesData),
    [characteristics, characteristicSetTemplatesData]
  );

  // Effects
  useEffect(() => {
    const percentage =
      ((textWidth + leftPadding + iconWidth) / totalWidth) * 100;

    setBorderWidth(`${percentage}%`);
  }, [iconWidth, textWidth, totalWidth]);

  return (
    <ErrorHandling
      isLoading={
        isLoadingCharacteristicSetTemplates ||
        isLoadingCharacteristicDefinitions
      }
      isError={
        isErrorCharacteristicSetTemplates || isErrorCharacteristicDefinitions
      }
    >
      <div>
        {setTemplates
          .filter(
            (setTemplate) =>
              setTemplate.characteristics?.filter((char) => Boolean(char.value))
                ?.length > 0
          )
          .sort((a, b) => {
            if (a.orderPriority > b.orderPriority) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((setTemplate) => (
            <AssetDetailCharacteristicGroupAccordion
              key={setTemplate.name}
              setTemplate={setTemplate}
            />
          ))}

        <StyledAccordion defaultExpanded>
          <StyledAccordionSummary
            value={borderWidth}
            expandIcon={
              <div
                style={{ marginTop: "5px", marginRight: "5px" }}
                ref={iconRef}
              >
                {getSvgIcon(
                  "ARROW_RIGHT",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>
            }
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle variant="body2" istablet={1} ref={textRef}>
              {getTranslation("OTHER", t, i18n)}
            </SectionTitle>
          </StyledAccordionSummary>

          <StyledAccordionDetails>
            <Grid container columnSpacing={2} id="other">
              {other
                ?.filter((char) => Boolean(char.value))
                ?.slice()
                ?.sort((a, b) => {
                  if (a.id > b.id) {
                    return 1;
                  } else {
                    return -1;
                  }
                })
                ?.map((row) => {
                  return (
                    <Grid key={row.id} item xs={12} sm={6}>
                      <CharacteristicItem
                        characteristic={row}
                        region={user?.region}
                      />
                    </Grid>
                  );
                })}
            </Grid>

            <AssetDetailLocation
              parentId={resourceParentId}
              resourceFunctionId={functionId}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      </div>
    </ErrorHandling>
  );
};

export default AssetDetailCharacteristicGroup;
