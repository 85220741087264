import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { Box } from "@react-three/drei";
import { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import FunnelChartWidgetContent from "../categorized-widgets/FunnelChartWidgetContent";
import { useSelector } from "react-redux";
import {
  addWidget,
  selectGroupResultFields,
  selectResultFields,
  selectWidgetById,
  setWidgetAggregations,
} from "../../../../store/slices/reportsSlice";
import {
  constructAggregations,
  getPaletteColor,
  getReasonablePercentageDistribution,
  mapIdToPalette,
  WIDGET_TYPE,
} from "../../../../util/reports-utils";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import WidgetAggregationsList from "../WidgetAggregationsList";

const FunnelChartWidgetForm = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const resultFields = useSelector(selectResultFields);
  const groupFields = useSelector(selectGroupResultFields);
  const widget = useSelector((state) =>
    selectWidgetById(state, WIDGET_TYPE.FUNNEL_CHART)
  );

  // Other variables
  const finalAggregations = constructAggregations(resultFields);

  // States
  const [title, setTitle] = useState(widget?.title);
  const [displayHome, setDisplayHome] = useState(false);

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    dispatch(addWidget({ ...widget, title: newTitle }));
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  // Other variables
  const dataSources = widget?.dataSources ?? [];

  const aggregationIds =
    dataSources?.map((ds) => ds.generatedAggregationId) ?? [];

  const aggregationId = aggregationIds?.length > 0 ? aggregationIds[0] : "";

  const palette =
    dataSources?.length > 0
      ? mapIdToPalette(dataSources[0]?.paletteId)
      : "pastel";

  const groupKey = groupFields?.map((field) => field.field)?.join(":");

  const groupDisplay =
    groupFields?.length > 0
      ? groupFields
          ?.map((field) => getTranslation(field.field, t, i18n))
          ?.join(", ")
      : "N/A";

  const groups = rows?.map((row) => {
    let joinedGroupValue = "";
    const groupKeys = groupFields?.map((field) => field.field);

    for (let i = 0; i < groupKeys.length; i++) {
      const groupKey = groupKeys[i];
      joinedGroupValue += `${row[groupKey]} `;
    }

    const aggregation = finalAggregations?.find((a) => a.id === aggregationId);

    const key =
      aggregation?.field?.name +
      `.aggregation-${aggregation?.aggregationType?.toLowerCase()}`;

    const value = row[key];

    const group = {
      label: joinedGroupValue,
      count: value,
    };

    return group;
  });

  const portions = getReasonablePercentageDistribution(groups);

  const data = portions?.map((portion, i) => ({
    value: portion?.count,
    name: portion?.label,
    fill: getPaletteColor(palette, theme.palette.mode, i),
  }));

  // Handlers
  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = handleRearangeArr(
      widget?.dataSources,
      source.index,
      destination.index
    );

    dispatch(
      setWidgetAggregations({ widgetTypeId: WIDGET_TYPE.FUNNEL_CHART, items })
    );
  };

  return (
    <Stack spacing={3}>
      <SecondaryText gutterBottom>Configure Funnel Chart</SecondaryText>

      <TextField
        label="Title"
        id="title"
        value={title}
        size="small"
        onChange={handleChangeTitle}
      />

      <FormControl fullWidth>
        <InputLabel id="group-field-select-label">Group field</InputLabel>
        <Select
          labelId="group-field-select-label"
          id="group-field-select"
          value={groupKey}
          label="Group field"
          disabled
        >
          <MenuItem value={groupKey}>{groupDisplay}</MenuItem>
        </Select>
      </FormControl>

      <DragDropContext onDragEnd={handleDragEnd}>
        <WidgetAggregationsList widgetTypeId={WIDGET_TYPE.FUNNEL_CHART} />
      </DragDropContext>

      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />
      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <SecondaryText
              sx={{ padding: "8px" }}
              variant="body2"
              textAlign="center"
            >
              {title}
            </SecondaryText>
            <FunnelChartWidgetContent data={data} />
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default FunnelChartWidgetForm;
