import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { Box } from "@react-three/drei";
import { useState } from "react";
import { SecondaryText } from "../../../styles/assets/ListInlineView.styles";
import { StyledFormControlLabel } from "../../../styles/general/General.styles";
import AreaChartWidgetContent from "../categorized-widgets/AreaChartWidgetContent";
import { useSelector } from "react-redux";
import {
  addWidget,
  selectGroupResultFields,
  selectResultFields,
  selectWidgetById,
  setWidgetAggregations,
} from "../../../../store/slices/reportsSlice";
import {
  constructAggregations,
  getChartData,
  getChartGroups,
  WIDGET_TYPE,
} from "../../../../util/reports-utils";
import { getTranslation } from "../../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import WidgetAggregationsList from "../WidgetAggregationsList";

const AreaChartWidgetForm = ({ rows }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const resultFields = useSelector(selectResultFields);
  const groupFields = useSelector(selectGroupResultFields);
  const widget = useSelector((state) =>
    selectWidgetById(state, WIDGET_TYPE.AREA_CHART)
  );

  // Other variables
  const dataSources = widget?.dataSources ?? [];

  const aggregationIds =
    dataSources?.map((ds) => ds.generatedAggregationId) ?? [];

  const finalAggregations = constructAggregations(resultFields);

  // States
  const [title, setTitle] = useState(widget?.title);
  const [displayHome, setDisplayHome] = useState(false);

  // Other variables
  const groupKey = groupFields?.map((field) => field.field)?.join(":");

  const groupDisplay =
    groupFields?.length > 0
      ? groupFields
          ?.map((field) => getTranslation(field.field, t, i18n))
          ?.join(", ")
      : "N/A";

  const objOfGroups = getChartGroups(
    rows,
    groupFields,
    finalAggregations,
    aggregationIds
  );

  const data = getChartData(
    objOfGroups,
    finalAggregations,
    theme.palette.mode,
    WIDGET_TYPE.AREA_CHART,
    t,
    i18n,
    dataSources
  );

  // Handlers
  const handleChangeTitle = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    dispatch(addWidget({ ...widget, title: newTitle }));
  };

  const handleChangeDisplayHome = (e) => {
    const newDisplayHome = e.target.checked;
    setDisplayHome(newDisplayHome);
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = handleRearangeArr(
      widget?.dataSources,
      source.index,
      destination.index
    );

    dispatch(
      setWidgetAggregations({ widgetTypeId: WIDGET_TYPE.AREA_CHART, items })
    );
  };

  return (
    <Stack spacing={3}>
      <SecondaryText gutterBottom>Configure Area Chart</SecondaryText>
      <TextField
        label="Title"
        id="title"
        value={title}
        size="small"
        onChange={handleChangeTitle}
      />

      <FormControl fullWidth>
        <InputLabel id="group-field-select-label">Group field</InputLabel>
        <Select
          labelId="group-field-select-label"
          id="group-field-select"
          value={groupKey}
          label="Group field"
          disabled
        >
          <MenuItem value={groupKey}>{groupDisplay}</MenuItem>
        </Select>
      </FormControl>

      <DragDropContext onDragEnd={handleDragEnd}>
        <WidgetAggregationsList widgetTypeId={WIDGET_TYPE.AREA_CHART} />
      </DragDropContext>

      <StyledFormControlLabel
        control={
          <Checkbox checked={displayHome} onChange={handleChangeDisplayHome} />
        }
        label="Show on homescreen"
      />

      <Box>
        <SecondaryText gutterBottom>Preview</SecondaryText>
        <Paper
          sx={{
            height: "180px",
            backgroundColor: "#F1F1F1",
          }}
        >
          <Stack sx={{ height: "100%" }}>
            <SecondaryText
              sx={{ padding: "8px" }}
              variant="body2"
              textAlign="center"
            >
              {title}
            </SecondaryText>
            <AreaChartWidgetContent data={data} />
          </Stack>
        </Paper>
      </Box>
    </Stack>
  );
};

export default AreaChartWidgetForm;
