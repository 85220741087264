import { Box, Grid, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import {
  selectExecutionResultFields,
  selectFurtherResultFields,
  selectGroupResultFields,
  selectQuery,
  selectResultFields,
  selectShouldExecute,
  selectTypeId,
  setExecutionResultFields,
  setResultFields,
  setShouldExecute,
} from "../../../store/slices/reportsSlice";
import {
  constructAggregations,
  parseQueryExecute,
} from "../../../util/reports-utils";
import FormatResultPreviewTable from "./FormatResultPreviewTable";
import ResultFieldsList from "./ResultFieldsList";
import GroupFieldsList from "./GroupFieldsList";
import ErrorHandling from "../../common/ErrorHandling";
import { DragDropContext } from "react-beautiful-dnd";
import BounceLetterSpinner from "../../common/BounceLetterSpinner";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  DefineQueryWizardStepWrapper,
  FormatResultWizardStepFieldsListWrapper,
  FormatResultWizardStepGridContainer,
  ReportPreviewTypography,
  ReportPreviewWrapper,
  ReportRefreshButton,
} from "../../styles/reports/Reports.styles";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";
import { useEffect } from "react";
import { messageError } from "../../../util/notification";

const FormatResultWizardStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const shouldExecute = useSelector(selectShouldExecute);
  const resultFields = useSelector(selectResultFields);
  const furtherResultFields = useSelector(selectFurtherResultFields);
  const groupResultFields = useSelector(selectGroupResultFields);
  const executionResultFields = useSelector(selectExecutionResultFields);
  const typeId = useSelector(selectTypeId);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const fields = executionResultFields.map((field, i) => {
    return {
      field: {
        name: field.field,
      },
      displayName: field.displayName,
      index: i,
      orderIndex: field.orderIndex,
      sortMethod: field.sortMethod,
      usedForGrouping: field.usedForGrouping,
    };
  });

  const finalAggregations = constructAggregations(executionResultFields);
  const filters = [parseQueryExecute(query)];
  const continueButtonDisabled = resultFields?.some(
    (field) => !field.displayName
  );
  const iconSize = globalFontSize;

  // Queries
  const {
    data: reportExecutionData,
    isLoading,
    isFetching,
    isError,
  } = useReportExecutionBaseQuery(
    {
      organizationId,
      typeId,
      fields,
      filters,
      aggregations: finalAggregations,
    },
    { skip: !shouldExecute }
  );

  // Handlers
  const handleLoadPreview = () => {
    dispatch(setShouldExecute(true));
    dispatch(setExecutionResultFields(resultFields));
  };

  const handleRearangeArr = (arr, sourceIndex, destIndex) => {
    const arrCopy = [...arr];
    const [removed] = arrCopy.splice(sourceIndex, 1);
    arrCopy.splice(destIndex, 0, removed);

    return arrCopy;
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId !== source.droppableId) {
      // Find the source in items array and change with destination droppable id
      if (destination.droppableId === "result-fields") {
        const newResultFields = resultFields.map((field) =>
          field.uniqueId === result.draggableId
            ? { ...field, usedForGrouping: false }
            : { ...field }
        );

        dispatch(setResultFields(newResultFields));
      } else if (destination.droppableId === "group-fields") {
        const newResultFields = resultFields.map((field) =>
          field.uniqueId === result.draggableId
            ? { ...field, usedForGrouping: true }
            : { ...field }
        );

        dispatch(setResultFields(newResultFields));
      }
    } else {
      // Rearrange the array if it is in the same category
      if (destination.droppableId === "result-fields") {
        const items = handleRearangeArr(
          furtherResultFields,
          source.index,
          destination.index
        );

        dispatch(setResultFields([...groupResultFields, ...items]));
      } else if (destination.droppableId === "group-fields") {
        const items = handleRearangeArr(
          groupResultFields,
          source.index,
          destination.index
        );

        dispatch(setResultFields([...items, ...furtherResultFields]));
      }
    }

    dispatch(setShouldExecute(false));
  };

  // Effects
  useEffect(() => {
    if (isError) {
      messageError(getTranslation("REPORT_FAILED_EXECUTION", t, i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return (
    <ErrorHandling isLoading={false} isError={false}>
      <DefineQueryWizardStepWrapper>
        <Box>
          <DragDropContext onDragEnd={handleDragEnd}>
            <FormatResultWizardStepGridContainer container>
              <Grid item xs={12}>
                <FormatResultWizardStepFieldsListWrapper>
                  <GroupFieldsList />
                </FormatResultWizardStepFieldsListWrapper>
              </Grid>
            </FormatResultWizardStepGridContainer>

            <FormatResultWizardStepGridContainer container>
              <Grid item xs={12}>
                <FormatResultWizardStepFieldsListWrapper>
                  <ResultFieldsList />
                </FormatResultWizardStepFieldsListWrapper>
              </Grid>
            </FormatResultWizardStepGridContainer>
          </DragDropContext>
        </Box>

        <Box>
          <ReportPreviewWrapper>
            <ReportPreviewTypography variant="body">
              {getTranslation("REPORT_PREVIEW", t, i18n)}
            </ReportPreviewTypography>

            <ReportRefreshButton
              id="refresh-preview"
              disabled={continueButtonDisabled || shouldExecute}
              onClick={handleLoadPreview}
              variant="text"
            >
              {getSvgIcon(
                "REFRESH",
                iconSize,
                iconSize,
                theme.palette.secondary.contrastText,
                { marginRight: "5px" }
              )}
              {getTranslation("REPORT_REFRESH", t, i18n)}
            </ReportRefreshButton>
          </ReportPreviewWrapper>

          {isLoading || isFetching ? (
            <BounceLetterSpinner />
          ) : (
            reportExecutionData?.data &&
            reportExecutionData.data.length > 0 && (
              <FormatResultPreviewTable rows={reportExecutionData.data} />
            )
          )}
        </Box>
      </DefineQueryWizardStepWrapper>
    </ErrorHandling>
  );
};

export default FormatResultWizardStep;
