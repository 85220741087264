import { useEffect } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import { messageWarning } from "../../util/notification";
import useCheckCurrentSubscription from "../../hooks/useCheckCurrentSubscription";

const Layer2Access = ({ children }) => {
  // General hooks
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Custom hooks
  const hasSubscription = useCheckCurrentSubscription();

  // Selectors
  const user = useSelector(selectUser);

  // Handlers
  const checkSafeToRender = () => {
    const organizations = user?.organizations;
    const activeOrganization = organizations?.find((o) => o.default);
    const noOrganizations = !organizations || organizations.length <= 0;

    return !(
      noOrganizations ||
      activeOrganization?.restricted ||
      !hasSubscription
    );
  };

  // Other variables
  const isSafeToRender = checkSafeToRender();

  // Handlers
  const handleResponsiveRedirect = () => {
    if (mobileMatches) {
      navigate("/more");
    } else {
      navigate("/profile");
    }
  };

  const handleRedirection = () => {
    if (user?.id) {
      const organizations = user?.organizations;

      if (!organizations || organizations.length <= 0) {
        handleResponsiveRedirect();
      } else {
        const activeOrganization = organizations?.find((o) => o.default);

        if (activeOrganization?.restricted) {
          messageWarning("Your access to the organization is restricted", {
            toastId: "organization-access-restricted",
          });

          handleResponsiveRedirect();
        } else {
          if (!hasSubscription) {
            messageWarning("No active subscription found", {
              toastId: "no-active-subscription",
            });

            handleResponsiveRedirect();
          }
        }
      }
    }
  };

  // Effects
  useEffect(() => {
    handleRedirection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.organizations?.length, isSafeToRender]);

  if (!isSafeToRender) {
    return <></>;
  }

  return <>{children}</>;
};

export default Layer2Access;
