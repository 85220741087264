import { Box, ListItem } from "@mui/material";
import { styled } from "@mui/system";

export const ListItemContainer = styled(ListItem)(({ istablet, theme }) => ({
  display: istablet === 1 ? "flex" : "block",
  marginTop: "2px",
  flexDirection: istablet === 1 ? "row" : "",
  justifyContent: istablet === 1 ? "space-between" : "",
  gap: "8px",
  backgroundColor: theme.palette.secondary.active,
}));

export const OverflowContainer = styled(Box)({
  overflow: "hidden",
});
