import { Box, useTheme, FormLabel, Stack, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../util/utils";
import { StyledDialogTitle } from "../../../../styles/general/General.styles";
import { StackContainer } from "../../../../styles/assets/asset-list/AssetImportExport.styles";
import { SecondaryText } from "../../../../styles/assets/ListInlineView.styles";

const AssetImportWizardFinalStep = ({ isLoadingImport, errors }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{ marginTop: "40px" }}>
      <FormLabel
        sx={{ color: theme.palette.text.secondary }}
        id="demo-controlled-radio-buttons-group"
      >
        {isLoadingImport
          ? getTranslation("IMPORT_IN_PROGRESS_CAPTION", t, i18n)
          : errors && errors.length > 0
          ? getTranslation("UNSUCCESSFUL_IMPORT_CAPTION", t, i18n)
          : getTranslation("IMPORT_FINAL_STEP_CAPTION", t, i18n)}
      </FormLabel>

      {errors && errors.length > 0 && (
        <>
          <StyledDialogTitle>
            {getTranslation("ISSUED_RESOURCES", t, i18n)}
          </StyledDialogTitle>

          <StackContainer spacing={1}>
            {errors.map((error, index) => {
              const { issuedResource, messages } = error;

              return (
                <Box key={`issued-${index}`}>
                  <SecondaryText id={`issued-${index}`}>
                    {issuedResource}
                  </SecondaryText>

                  <Stack spacing={0.5}>
                    {messages.map((error, index) => (
                      <Alert
                        id={`message-${index}`}
                        key={`message-${index}`}
                        variant="standard"
                        severity="error"
                      >
                        {error.message}
                      </Alert>
                    ))}
                  </Stack>
                </Box>
              );
            })}
          </StackContainer>
        </>
      )}
    </Box>
  );
};

export default AssetImportWizardFinalStep;
