import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { SUBSCRIPTION_ERRORS } from "../../../util/utils";
import { loadUserFromBackend } from "../../../util/UserUtil";
import { setUser } from "../authSlice";

const baseQuery = retry(fetchBaseQuery({}), { maxRetries: 0 });

let isHandling403 = false;

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 403) {
      // const isOrganizationUsersUrl =
      //   result?.meta?.request?.method === "GET" &&
      //   args?.url?.includes("/organizations") &&
      //   args?.url?.includes("/users");
      // const isRestricted =
      //   result.error.data?.error ===
      //     SUBSCRIPTION_ERRORS["SUBSCRIPTION_NOT_FOUND"] ||
      //   result.error.data?.error ===
      //     SUBSCRIPTION_ERRORS["CURRENT_SUBSCRIPTION_NOT_FOUND"] ||
      //   result.error.data?.error ===
      //     SUBSCRIPTION_ERRORS["LIMIT_EXCEEDED_USER"] ||
      //   result.error.data?.error ===
      //     SUBSCRIPTION_ERRORS["RESTRICTED_DUE_TO_OVERLIMIT_USER"] ||
      //   isOrganizationUsersUrl;
      // if (isRestricted) {
      // }

      //window.location.reload(); // Reload, so that old data is cleared

      if (!isHandling403) {
        isHandling403 = true;

        try {
          const { dispatch } = api;
          const fetchedUser = await loadUserFromBackend();

          if (fetchedUser) {
            dispatch(setUser(fetchedUser));
          }
        } catch (error) {
          console.log(error);
        } finally {
          setTimeout(() => {
            isHandling403 = false;
          }, 5000);
        }
      }
    }
  }

  return result;
};

export const baseApiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [
    "Invitations",
    "Roles",
    "UserImage",
    "OrganizationImage",
    "OrganizationUsers",
    "Referrals",
    "Favorites",
    "ResourceImage",
    "ResourceAttachment",
    "ResourcesByType",
    "Recents",
    "Reports",
    "LIST",
    "DETAILS",
    "SET_TEMPLATES",
    "FUNCTIONS",
    "TYPES",
    "TYPE",
    "TYPE_CHARACTERISTICS",
    "REPORT_WIDGET",
    "REPORT_LOCATIONS",
    "Tags",
    "Search",
    "GraphicalObjects",
    "FAVORITE_TYPES",
    "RECENT_TYPES",
    "DETAILS",
    "ReportDefinitionExecute",
    "ReportDefinition",
    "ReportDefinitionField",
    "ReportDefinitionFilter",
    "ReportDefinitionAggregations",
    "ADDRESS",
    "ORGANIZATION",
    "Subscription",
    "ReportDefinitionWidget",
  ],
  refetchOnFocus: true,
  endpoints: () => ({}),
});
