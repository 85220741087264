import { useCallback, useEffect, useState } from "react";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import {
  createTheme,
  styled,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import { ConfigProvider } from "antd";
import KeycloakService, {
  TOKEN_MIN_VALIDITY,
} from "./services/KeycloakService";
import Authenticated from "./routes/Authenticated";
import Container from "./navigation/Container";
import Menu from "./navigation/footer/Menu";
import { useSelector } from "react-redux";
import {
  selectIsDrawerOpen,
  selectPageView,
  selectTheme,
  setGlobalFontSize,
  setLanguage,
  setShowNotifications,
  setIsSubscribed,
  setTheme,
  setUserTheme,
  setErrorMessage,
  selectGlobalFontSize,
} from "./store/slices/appSlice";
import {
  COLLAPSED_DRAWER_WIDTH,
  EXPANDED_DRAWER_WIDTH,
  getMappedFontSize,
  mapBrowserFontSizeToMuiTypography,
  THEME,
  THEME_EXPIRATION_DAYS,
} from "./util/utils";
import { useDispatch } from "react-redux";
import { ERROR_MESSAGE, loadUserFromBackend } from "./util/UserUtil";
import { selectUser, setUser } from "./store/slices/authSlice";
import mainTheme from "./themes/main-theme";
import darkTheme from "./themes/dark-theme";
import { userLocalStorageKey } from "./util/utils";
import "react-toastify/dist/ReactToastify.css";
import { CloseIconButton } from "./components/styles/general/General.styles";
import AppDrawer from "./navigation/drawer/Drawer";
import useOrientation from "./hooks/useOrientation";
import { useServiceWorker } from "./hooks/useServiceWorker";
import {
  STANDARD_BOTTOM_SPACE,
  VIEWPORT_MEDIA_QUERIES,
} from "./util/viewport-utils";
import { useLocation, useNavigate } from "react-router-dom";
import UpdateAlert from "./components/common/UpdateAlert";
import { isLocalhost } from "./serviceWorkerRegistration";
import LanguageService from "./services/LanguageService";
import { messageError } from "./util/notification";
import { getCookie, setCookie } from "./util/cookies";
import Navbar from "./navigation/drawer/Navbar";
import useCheckOrganizationRestricted from "./hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "./util/icons";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const PUBLIC_PUSH_KEY = process.env.REACT_APP_PUBLIC_PUSH_KEY;

function App() {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const { pathname } = useLocation();

  // Custom hooks
  const orientation = useOrientation();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const user = useSelector(selectUser);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);
  const view = useSelector(selectPageView);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // State
  // Initialize the font size state with the default font size
  const [fontSize, setFontSize] = useState(16);
  const [showDrawer] = useState(true);

  // Other variables
  const iconSize = globalFontSize;
  const routeCheck = pathname === "/resources";

  const theme =
    currentTheme === THEME.LIGHT
      ? createTheme({
          ...mainTheme,
          typography: mapBrowserFontSizeToMuiTypography(fontSize),
        })
      : createTheme({
          ...darkTheme,
          typography: mapBrowserFontSizeToMuiTypography(fontSize),
        });

  const activeOrganization = user?.organizations?.find((o) => o.default);

  const hasOrganizations =
    user?.organizations && user?.organizations?.length > 0;

  // Custom hooks
  const { isRestricted } = useCheckOrganizationRestricted(activeOrganization);

  // Other variables
  const showNavigation = hasOrganizations && !isRestricted;

  // Handlers
  const controlThemeChange = () => {
    const userSetTheme = getCookie("theme");

    if (!userSetTheme || userSetTheme === THEME.AUTOMATIC) {
      setCookie("theme", THEME.AUTOMATIC, THEME_EXPIRATION_DAYS);
      dispatch(setUserTheme(THEME.AUTOMATIC));

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        // Dark mode
        dispatch(setTheme(THEME.DARK));
      } else {
        // Light mode
        dispatch(setTheme(THEME.LIGHT));
      }
    } else {
      dispatch(setUserTheme(userSetTheme));
      dispatch(setTheme(userSetTheme));
    }
  };

  const handleCurrentUserData = useCallback(async () => {
    try {
      // Decode JWT and get user by ID
      // const { sub } = jwtDecode(KeycloakService.getToken());
      // const userById = await fetchUserById(sub);
      // console.log(userById);
      const fetchedUser = await loadUserFromBackend();

      if (fetchedUser) {
        dispatch(setUser(fetchedUser));
      }

      // Language
      const storedLanguage =
        fetchedUser?.language ||
        localStorage.getItem(userLocalStorageKey("language"))?.split("-")[0];

      if (storedLanguage) {
        dispatch(setLanguage(storedLanguage));
      }

      // Theme
      // if (!fetchedUser?.theme) {
      //   controlThemeChange();
      // } else {
      //   localStorage.setItem("theme", fetchedUser.theme);
      //   controlThemeChange();
      // }
      controlThemeChange();

      return fetchedUser;
    } catch (error) {
      if (error instanceof Error) {
        if (error.message === ERROR_MESSAGE.ERROR_FETCHING_USER) {
          dispatch(setErrorMessage(error.message));
          navigate("/error-page");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckSubscriptionExists = useCallback(async () => {
    if (isLocalhost) {
      dispatch(setShowNotifications(false));

      return;
    }

    if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
      dispatch(setShowNotifications(false));

      messageError("Service workers not supported");

      return;
    }

    const registration = await navigator.serviceWorker.ready;

    if (!registration.pushManager) {
      dispatch(setShowNotifications(false));

      messageError("Push manager unavailable", {
        duration: 4000,
      });

      return;
    }

    const existingSubscription =
      await registration.pushManager.getSubscription();

    if (!existingSubscription) {
      dispatch(setIsSubscribed(false));
    } else {
      dispatch(setIsSubscribed(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViewportDeviceProperties = () => {
    let drawerWidth;
    let mode;
    let isMenuVisible;
    let isAppBarVisible;
    let bottomSpace = 0;

    if (mobileMatches) {
      bottomSpace = STANDARD_BOTTOM_SPACE;
      drawerWidth = 0;
      mode = "mobile-device";
      isMenuVisible = true;
      isAppBarVisible = false;
    } else if (tabletMatches) {
      mode = "tablet-device";
      isMenuVisible = false;
      isAppBarVisible = false;

      if (orientation === "landscape" && isDrawerOpen) {
        drawerWidth = EXPANDED_DRAWER_WIDTH;
      } else {
        drawerWidth = COLLAPSED_DRAWER_WIDTH;
      }
    } else if (desktopMatches) {
      if (isDrawerOpen) {
        drawerWidth = EXPANDED_DRAWER_WIDTH;
      } else {
        drawerWidth = COLLAPSED_DRAWER_WIDTH;
      }

      mode = "desktop-device";
      isMenuVisible = false;
      isAppBarVisible = true;
    }

    return {
      drawerWidth,
      mode,
      isMenuVisible,
      isAppBarVisible,
      bottomSpace,
    };
  };

  const viewPortProperties = getViewportDeviceProperties();

  const handleKeycloackInitialization = async () => {
    try {
      const authenticated = await KeycloakService.keycloak.init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        pkceMethod: "S256",
        redirectUri: !Boolean(
          JSON.parse(localStorage.getItem("keycloak-initialized"))
        )
          ? window.location.origin + "/successful-login"
          : undefined,
      });

      if (!authenticated) {
        await KeycloakService.doLogin();
      } else {
        const fetchedUser = await handleCurrentUserData();
        await KeycloakService.readAuthUrl();
        await LanguageService.initI18n(fetchedUser);
        localStorage.setItem("keycloak-initialized", JSON.stringify(true));
      }
    } catch (e) {
      console.log("Failed to initialize Keycloak", e);
      await KeycloakService.readAuthUrl();
      const authUrl = KeycloakService.getAuthUrl();
      const urlToLogin = `${authUrl}?response_type=code&client_id=web-app&redirect_uri=${window.location.origin}/`;
      window.location.href = urlToLogin;
    }
  };

  // Effects
  useEffect(() => {
    handleKeycloackInitialization();

    if (KeycloakService.keycloak) {
      KeycloakService.keycloak.onTokenExpired = async () => {
        try {
          await KeycloakService.keycloak.updateToken(TOKEN_MIN_VALIDITY);
        } catch (tokenError) {
          console.error("Token refresh failed", tokenError);
          await KeycloakService.doLogin(); // Redirect to login if token refresh fails
        }
      };
    }

    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (event) => {
      const userSetTheme = getCookie("theme");
      if (!userSetTheme || userSetTheme === THEME.AUTOMATIC) {
        if (event.matches) {
          // Dark mode
          dispatch(setTheme(THEME.DARK));
        } else {
          // Light mode
          dispatch(setTheme(THEME.LIGHT));
        }
      }
    });

    return () => {
      if (KeycloakService.keycloak)
        KeycloakService.keycloak.onTokenExpired = () => {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen for changes in the browser font size and update the font size state
  useEffect(() => {
    const handleBrowserFontSizeChange = () => {
      // Get the new font size from the browser
      const newFontSize = window.getComputedStyle(document.body).fontSize;

      // Parse the font size value to extract the numeric part
      const parsedFontSize = parseFloat(newFontSize);

      // Update the font size state
      setFontSize(parsedFontSize);

      // Update the global font size
      const mappedFontSize = getMappedFontSize(parsedFontSize);
      dispatch(setGlobalFontSize(mappedFontSize));
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        // Browser tab is visible again, update the font size
        handleBrowserFontSizeChange();
      }
    };

    // Invoke the function on initial render
    handleBrowserFontSizeChange();

    // Add an event listener to listen for changes in the browser font size
    window.addEventListener("resize", handleBrowserFontSizeChange);

    // Add an event listener to handle visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Remove the event listeners when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener("resize", handleBrowserFontSizeChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onCheckSubscriptionExists();
  }, [onCheckSubscriptionExists]);

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.primary.contrastText;
    document.documentElement.style.setProperty(
      "--query-builder-color",
      theme.palette.primary.dark
    );
  }, [currentTheme]);

  useEffect(() => {
    const isColumnView = routeCheck && view === "column";
    document.body.style.overflowY = isColumnView ? "hidden" : "auto";
  }, [routeCheck, view]);

  // decides when to show the toast
  useEffect(() => {
    if (showReload && waitingWorker) {
      console.debug("Update is available");

      toast(
        <UpdateAlert
          title="NEW_VERSION_AVAILABLE"
          action="REFRESH"
          clickHandler={reloadPage}
        />,
        {
          autoClose: false,
          closeButton: false,
          position: "bottom-left",
        }
      );
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <div id={viewPortProperties.mode}>
      {KeycloakService.keycloak?.authenticated && (
        <div id="container-wrapper">
          <ConfigProvider
            theme={{
              algorithm: theme.darkAlgorithm,
            }}
          >
            <ThemeProvider theme={theme}>
              {!pathname.includes("onboarding") ? (
                <Container
                  bottomSpace={viewPortProperties.bottomSpace}
                  leftSpace={
                    showNavigation
                      ? (desktopMatches && showDrawer) || tabletMatches
                        ? viewPortProperties.drawerWidth
                        : 0
                      : 0
                  }
                >
                  {mobileMatches ? (
                    <>{showNavigation && <Menu />}</>
                  ) : (
                    <>
                      <DrawerHeader />
                      <Navbar />
                      {showNavigation && <AppDrawer />}
                    </>
                  )}

                  <Authenticated />
                </Container>
              ) : (
                <Authenticated />
              )}

              <ToastContainer
                containerId="toast-notification"
                closeButton={() => (
                  <CloseIconButton id="close-notification-button">
                    {getSvgIcon("CLEAR", iconSize, iconSize, "#808080")}
                  </CloseIconButton>
                )}
                theme={currentTheme}
              />
            </ThemeProvider>
          </ConfigProvider>
        </div>
      )}
    </div>
  );
}

export default App;
