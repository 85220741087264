import {
  activate_user_role_url,
  BACKEND_URL,
  organization_data_storage_location,
  organization_export,
  organization_export_xlsx,
  organization_import,
  organization_leave_url,
  organization_onboarding_url,
  organization_roles,
  organization_update_url,
  organization_upload_profile_picture_url,
  organization_users_url,
  remove_user_from_organization_url,
} from "../../../Constants";
import KeycloakService from "../../../services/KeycloakService";
import { baseApiSlice } from "./baseApiSlice";

export const organizationsApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizationById: builder.query({
      query: (organizationId) => ({
        url: organization_update_url.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      providesTags: ["ORGANIZATION"],
    }),
    getOrganizationUsers: builder.query({
      query: (organizationId) => ({
        url: organization_users_url.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      providesTags: ["OrganizationUsers"],
    }),
    getOrganizationRoles: builder.query({
      query: (organizationId) => ({
        url: organization_roles.replace(":organizationId", organizationId),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getOrganizationLogo: builder.query({
      query: ({ logoUri, thumbnail }) => ({
        url: BACKEND_URL + logoUri + `?thumbnail=${thumbnail}`,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          accept: "image/png",
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
      providesTags: ["OrganizationImage"],
    }),
    getOrganizationDataStorageLocation: builder.query({
      query: ({ organizationId }) => ({
        url: organization_data_storage_location.replace(
          ":organization-id",
          organizationId
        ),
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      providesTags: ["OrganizationStorageLocation"],
    }),
    onboardOrganization: builder.mutation({
      query: (organizationData) => ({
        url: organization_onboarding_url,
        method: "POST",
        body: {
          name: organizationData.organizationName,
          nickname: Boolean(organizationData.nickname)
            ? organizationData.nickname
            : null,
          contactPhone: organizationData.contactPhone,
          contactEmail: organizationData.contactEmail,
          contactPerson: organizationData.contactPerson,
          dataStorageLocationId: organizationData.dataStorageLocationId,
          vatNumber: organizationData.vatNumber,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["Subscription"],
    }),
    organizationImport: builder.mutation({
      query: ({
        formData,
        organizationId,
        strategy,
        includeGraphicalObjects,
        includeTags,
        includeImages,
        includeAttachments,
      }) => ({
        url: organization_import.replace(":organizationId", organizationId),
        method: "POST",
        body: formData,
        params: {
          action: "IMPORT",
          existing_data_strategy: strategy,
          include_graphical_objects: includeGraphicalObjects,
          include_tags: includeTags,
          include_images: includeImages,
          include_attachments: includeAttachments,
          error_strategy: "ABORT",
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: [
        "DETAILS",
        "Favorites",
        "LIST",
        "REPORT_LOCATIONS",
        "REPORT_WIDGET",
        "Recents",
        "Reports",
        "Tags",
        "ResourceAttachment",
        "ResourceImage",
        "GraphicalObjects",
        "Subscription",
      ],
    }),
    organizationExport: builder.mutation({
      query: ({
        organizationId,
        organizationName,
        includeImages,
        includeAttachments,
        includeGraphicalObjects,
        includeTags,
      }) => ({
        url: organization_export.replace(":organizationId", organizationId),
        method: "POST",
        body: {
          organizationName,
        },
        params: {
          include_images: includeImages,
          include_attachments: includeAttachments,
          include_graphical_objects: includeGraphicalObjects,
          include_tags: includeTags,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
    }),
    organizationExportXLSX: builder.mutation({
      query: ({ organizationId, organizationName, includeTags }) => ({
        url: organization_export_xlsx.replace(
          ":organizationId",
          organizationId
        ),
        method: "POST",
        body: {
          organizationName,
        },
        params: {
          include_tags: includeTags,
        },
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
        responseHandler: (response) => response.blob(),
      }),
      transformResponse: async (response) => {
        // Use response.blob() to create a Blob object
        const blob = await response;
        const objectURL = URL.createObjectURL(blob);
        return objectURL;
      },
    }),
    uploadOrganizationImage: builder.mutation({
      query: ({ formData, organizationId }) => ({
        url: organization_upload_profile_picture_url.replace(
          ":organizationId",
          organizationId
        ),
        method: "POST",
        body: formData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["OrganizationImage", "ORGANIZATION"],
    }),
    deleteOrganizationImage: builder.mutation({
      query: ({ organizationId }) => ({
        url: organization_upload_profile_picture_url.replace(
          ":organizationId",
          organizationId
        ),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
        },
      }),
      invalidatesTags: ["OrganizationImage"],
    }),
    updateOrganization: builder.mutation({
      query: ({ organizationData, organizationId }) => ({
        url: organization_update_url.replace(":organizationId", organizationId),
        method: "PATCH",
        body: organizationData,
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["ORGANIZATION"],
    }),
    leaveOrganization: builder.mutation({
      query: (organizationId) => ({
        url: organization_leave_url.replace(":organizationId", organizationId),
        method: "POST",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
    }),
    removeUserFromOrganization: builder.mutation({
      query: ({ organizationId, userId }) => ({
        url: remove_user_from_organization_url
          .replace(":userId", userId)
          .replace(":organizationId", organizationId),
        method: "DELETE",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["OrganizationUsers", "Subscription"],
    }),
    activateUser: builder.mutation({
      query: ({ organizationId, userId }) => ({
        url: activate_user_role_url
          .replace(":userId", userId)
          .replace(":organizationId", organizationId),
        method: "PUT",
        headers: {
          authorization: `Bearer ${KeycloakService.getToken()}`,
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["OrganizationUsers", "Subscription"],
    }),
  }),
});

export const {
  useGetOrganizationByIdQuery,
  useGetOrganizationUsersQuery,
  useGetOrganizationDataStorageLocationQuery,
  useGetOrganizationLogoQuery,
  useGetOrganizationRolesQuery,
  useOnboardOrganizationMutation,
  useOrganizationExportMutation,
  useOrganizationExportXLSXMutation,
  useOrganizationImportMutation,
  useUploadOrganizationImageMutation,
  useDeleteOrganizationImageMutation,
  useUpdateOrganizationMutation,
  useLeaveOrganizationMutation,
  useRemoveUserFromOrganizationMutation,
  useActivateUserMutation,
} = organizationsApiSlice;
