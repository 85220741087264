import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  useTheme,
  Typography,
  FormGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../../../util/utils";
import { useSelector } from "react-redux";
import {
  EXPORT_OPTIONS,
  selectExportIncludes,
  selectExportOption,
  setExportIncludes,
  setExportOption,
} from "../../../../../store/slices/importExportSlice";
import { ExportIncludesSwitch } from "../../../../styles/assets/asset-list/AssetImportExport.styles";

const AssetExportWizardSecondStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const exportOption = useSelector(selectExportOption);
  const exportIncludes = useSelector(selectExportIncludes);

  // Handlers
  const handleChangeExportOption = (e) => {
    dispatch(setExportOption(e.target.value));
  };

  const handleChangeExportIncludesImages = (event) => {
    dispatch(
      setExportIncludes({ ...exportIncludes, images: event.target.checked })
    );
  };

  const handleChangeExportIncludesAttachments = (event) => {
    dispatch(
      setExportIncludes({
        ...exportIncludes,
        attachments: event.target.checked,
      })
    );
  };

  const handleChangeExportIncludesTags = (event) => {
    dispatch(
      setExportIncludes({ ...exportIncludes, tags: event.target.checked })
    );
  };

  const handleChangeExportIncludesGraphicalObjects = (event) => {
    dispatch(
      setExportIncludes({
        ...exportIncludes,
        graphicalObjects: event.target.checked,
      })
    );
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      <FormLabel
        sx={{ color: theme.palette.text.secondary }}
        id="demo-controlled-radio-buttons-group"
      >
        {getTranslation("EXPORT_SELECT_FILE_TYPE", t, i18n)}
      </FormLabel>

      <FormControl>
        <RadioGroup
          sx={{ alignItems: "start", marginTop: "20px" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={exportOption}
          onChange={handleChangeExportOption}
        >
          <FormControlLabel
            value={EXPORT_OPTIONS.XLSX}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("XLSX_EXPORT_OPTION", t, i18n)}
          />

          <Typography sx={{ color: theme.palette.text.secondary }}>
            {getTranslation("XLSX_EXPORT_OPTION_CAPTION", t, i18n)}
          </Typography>

          <FormControlLabel
            value={EXPORT_OPTIONS.ZIP}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("ZIP_EXPORT_OPTION", t, i18n)}
          />

          <Typography sx={{ color: theme.palette.text.secondary }}>
            {getTranslation("ZIP_EXPORT_OPTION_CAPTION", t, i18n)}
          </Typography>
        </RadioGroup>

        {exportOption === EXPORT_OPTIONS.ZIP && (
          <>
            <Typography
              sx={{ marginTop: "20px", color: theme.palette.text.secondary }}
            >
              {getTranslation("ZIP_EXPORT_ADDITIONAL_CONFIG", t, i18n)}
            </Typography>

            <FormGroup sx={{ marginTop: "20px", alignItems: "start" }}>
              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={exportIncludes["images"]}
                    onChange={handleChangeExportIncludesImages}
                  />
                }
                label={getTranslation("IMAGES", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={exportIncludes["attachments"]}
                    onChange={handleChangeExportIncludesAttachments}
                  />
                }
                label={getTranslation("ATTACHMENTS", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={exportIncludes["tags"]}
                    onChange={handleChangeExportIncludesTags}
                  />
                }
                label={getTranslation("TAGS", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={exportIncludes["graphicalObjects"]}
                    onChange={handleChangeExportIncludesGraphicalObjects}
                  />
                }
                label={getTranslation("GRAPHICAL_OBJECTS", t, i18n)}
              />
            </FormGroup>
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default AssetExportWizardSecondStep;
