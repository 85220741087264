import { Box, FormLabel, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../util/utils";
import DragNDropFileUpload from "./DragNDropFileUpload";
import { useSelector } from "react-redux";
import { selectImportFile } from "../../../../../store/slices/importExportSlice";

const AssetImportWizardFourthStep = () => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const importFile = useSelector(selectImportFile);

  return (
    <Box sx={{ marginTop: "40px" }}>
      <FormLabel
        sx={{ color: theme.palette.text.secondary }}
        id="demo-controlled-radio-buttons-group"
      >
        {importFile
          ? getTranslation("FILE_UPLOADED", t, i18n)
          : getTranslation("PLEASE_UPLOAD_FILE", t, i18n)}
      </FormLabel>

      <DragNDropFileUpload width="100%" height="100px" />
    </Box>
  );
};

export default AssetImportWizardFourthStep;
