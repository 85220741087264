import { Typography, Grid, ListItem, Button, Box, styled } from "@mui/material";

export const EllipseText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
}));

export const OrganizationDropDownName = styled(EllipseText)({
  maxWidth: `300px`,
});

export const ListItemName = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    color: theme.palette.text.primary,
  })
);

export const AssetIdNumberText = styled(Typography)(
  ({ theme }) =>
    theme.unstable_sx({
      marginRight: "auto",
    }),
  ({ theme }) => ({
    color: theme.palette.secondary.main,
  })
);

export const AssetClassText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const AssetTypeText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const AssetProgressStatus = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
  ({ theme, progressStatusColor }) =>
    theme.unstable_sx({
      backgroundColor: progressStatusColor,
    })
);

export const ReportCardItem = styled(ListItem)(
  ({ theme, index, longPressed }) => ({
    position: "relative",
    borderTop:
      index === 0 ? `3px solid ${theme.palette.secondary.active}` : null,
    borderBottom: `3px solid ${theme.palette.secondary.active}`,
    zIndex: longPressed ? 1200 : "auto",
    backgroundColor: longPressed
      ? theme.palette.secondary.active
      : "transparent",
  })
);

export const AddButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  display: "flex",
  justifyContent: "center",
  margin: "auto",
  marginTop: "20px",
}));

export const ChartContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  height: "100%",
});
