import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import ReportFullscreenDialog from "../../components/reports/ReportFullscreenDialog";

const ReportPopUpPage = () => {
  const {
    reportDefinitionData,
    reportDefinitionFieldsData,
    reportDefinitionFilterData,
    reportDefinitionAggregationsData,
    executionFurtherFields,
    executionGroupFields,
    rows,
    whereClause,
    userRoles,
  } = window;

  return (
    <AppAccess>
      <Layer2Access>
        <ReportFullscreenDialog
          open={true}
          handleClose={() => {}}
          reportDefinitionData={reportDefinitionData}
          reportDefinitionFieldsData={reportDefinitionFieldsData}
          reportDefinitionFilterData={reportDefinitionFilterData}
          reportDefinitionAggregationsData={reportDefinitionAggregationsData}
          executionFurtherFields={executionFurtherFields}
          executionGroupFields={executionGroupFields}
          rows={rows}
          whereClause={whereClause}
          userRoles={userRoles}
          showClose={false}
        />
      </Layer2Access>
    </AppAccess>
  );
};

export default ReportPopUpPage;
