import { useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getTranslation } from "../../../util/utils";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import TagsInput from "../tags/TagsInput";
import EditTagsDialog from "./EditTagsDialog";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getSvgIcon } from "../../../util/icons";
import { selectIsInDuplicate } from "../../../store/slices/assetListSlice";
import DuplicateTagsDialog from "./DuplicateTagsDialog";

const TagsAssetFormSection = ({
  id,
  setOpenConfirm,
  setRedirectRoute,
  mode,
}) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const isInDuplicate = useSelector(selectIsInDuplicate);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // State
  const [borderWidth, setBorderWidth] = useState("25%");
  const [open, setOpen] = useState(false);

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleInputClick = () => setOpen(true);

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary
        value={borderWidth}
        expandIcon={
          <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SectionTitle variant="body2" istablet={1} ref={textRef}>
          {getTranslation("TAGS", t, i18n)}
        </SectionTitle>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <TagsInput
          id={id}
          setOpenConfirm={setOpenConfirm}
          setRedirectRoute={setRedirectRoute}
          handleClick={!mobileMatches ? handleInputClick : null}
          autocomplete={!open}
          formMode={mode}
        />

        {!isInDuplicate
          ? open && <EditTagsDialog open={open} setOpen={setOpen} />
          : open && (
              <DuplicateTagsDialog open={open} setOpen={setOpen} id={id} />
            )}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default TagsAssetFormSection;
