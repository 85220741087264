import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { Stage } from "react-konva";
import { PrimaryText } from "../asset-detail/AssetDetailBasicInfo.styles";

export const GraphicalRackViewContainer = styled(Box)({
  overflowX: "hidden",
  marginTop: "8px",
});

export const GraphicalRackViewStageContainer = styled(Box)({
  overflow: "hidden",
});

export const TopBorderContainer = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.contrastText} 10%, ${theme.palette.primary.dark} 25%, ${theme.palette.primary.dark} 50%, ${theme.palette.primary.contrastText} 90%)`,
  position: "absolute",
  top: "0px",
  left: "0px",
  right: "0px",
  zIndex: "1 !important",
  border: `3px solid #000`,
}));

export const TopBorderWrapper = styled(Grid)({
  position: "relative",
  alignItems: "center",
});

export const TopBorderText = styled(PrimaryText)({
  marginLeft: "8px",
});

export const MenuIconButton = styled(IconButton)({
  marginLeft: "8px",
});

export const LeftContentContainer = styled(Grid)({
  display: "flex",
  justifyContent: "end",
});

export const RackStage = styled(Stage)({
  margin: "20px 0",
  padding: 0,
});

export const GraphicalViewMenuItem = styled(MenuItem)(
  ({ theme, isactive }) => ({
    backgroundColor: isactive
      ? theme.palette.secondary.active
      : theme.palette.primary.contrastText,
  })
);
