import {
  AGGREGATION_COUNT_KEY,
  HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID,
  RACK_WIDGET_REPORT_DEFINITION_ID,
  ZONE_WIDGET_REPORT_DEFINITION_ID,
  ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID,
  ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID,
} from "../Constants";
import { v4 as uuidv4 } from "uuid";
import { getTranslation, localizeDate } from "./utils";

export const DEFAULT_REPORT_RESULTS_PAGE_SIZE = 50;
export const DEFAULT_REPORTS_PAGE_SIZE = 15;
export const REPORT_RESULTS_PAGE_OPTIONS = [15, 50, 100];
export const REPORTS_PAGE_OPTIONS = [15, 50, 100];
export const CHART_GROUPS_COUNT = 5;

export const WIDGET_CATEGORY = {
  LINK: "link",
  SINGLE_VALUE: "single_value",
  DOUBLE_VALUE: "double_value",
  MIN_MAX_AVERAGE: "min_max_average",
  PERCENTAGE: "percentage",
  STATUS: "status",
  PIE_CHART: "pie_chart",
  DONUT_CHART: "donut_chart",
  LINE_CHART: "line_chart",
  BAR_CHART: "bar_chart",
  FUNNEL_CHART: "funnel_chart",
  AREA_CHART: "area_chart",
};

export const WIDGET_TYPE = {
  LINK: 1,
  SINGLE_VALUE: 2,
  DOUBLE_VALUE: 3,
  PERCENTAGE: 5,
  MIN_MAX_AVERAGE: 6,
  STATUS: 7,
  PIE_CHART: 8,
  DONUT_CHART: 9,
  BAR_CHART: 10,
  FUNNEL_CHART: 11,
  LINE_CHART: 12,
  AREA_CHART: 13,
};

export const AGGREGATION_RESULT_CONTENT = {
  AGGREGATION: "AGGREGATION",
  GROUP_DETAILS: "GROUP_DETAIL",
};

export const WIDGETS = [
  HARDWARE_ASSET_WIDGET_REPORT_DEFINITION_ID,
  RACK_WIDGET_REPORT_DEFINITION_ID,
  ZONE_WIDGET_REPORT_DEFINITION_ID,
  ASSET_COUNT_PER_LOCATION_WIDGET_REPORT_DEFINITION_ID,
  ASSET_COUNT_PER_RACK_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_FUNCTION_WIDGET_REPORT_DEFINITION_ID,
  HARDWARE_ASSETS_BY_VENDOR_WIDGET_REPORT_DEFINITION_ID,
];

export const REPORT_FIELDS = {
  NAME: "resource.name",
  DISPLAY_ID: "resource.displayId",
  ID: "resource.id",
  DESCRIPTION: "resource.description",
  LIFECYCLE_STATUS: "resource.characteristic.lifecycleStatus",
  LIFECYCLE_DATE: "resource.characteristic.lifecycleDate",
  CREATED_AT: "resource.createdAt",
  CREATED_BY: "resource.createdBy",
  UPDATED_AT: "resource.updatedAt",
  UPDATED_BY: "resource.updatedBy",
  COALESCE_DISPLAY_ID_AND_NAME: "resource.coalesce.displayIdAndName",
  P_NAME: "resource.parent.name",
  P_DISPLAY_ID: "resource.parent.displayId",
  P_ID: "resource.parent.id",
  TAG_ID: "resource.tag.id",
  TAG_NAME: "resource.tag.name",
  RESOURCE_TAG_MATCH_DATE: "resource.tag.match.date",
  P_TAG_ID: "resource.parent.tag.id",
  P_TAG_NAME: "resource.parent.tag.name",
  P_RESOURCE_TAG_MATCH_DATE: "resource.parent.tag.match.date",
  FUNCTION_NAME: "resource.function.name",
  FUNCTION_ID: "resource.function.id",
  FUNCTION_CATEGORY: "resource.function.category",
  P_FUNCTION_NAME: "resource.parent.function.name",
  P_FUNCTION_ID: "resource.parent.function.id",
  P_FUNCTION_CATEGORY: "resource.parent.function.category",
  TYPE_ID: "resource.type.id",
  TYPE_NAME: "resource.type.name",
  TYPE_DISPLAY_ID: "resource.type.displayId",
  P_TYPE_ID: "resource.parent.type.id",
  P_TYPE_NAME: "resource.parent.type.name",
  P_TYPE_DISPLAY_ID: "resource.parent.type.displayId",
  P_COALESCE_DISPLAY_ID_AND_NAME: "resource.parent.coalesce.displayIdAndName",
  TYPE_FUNCTION_ID: "resource.type.function.id",
  TYPE_FUNCTION_CATEGORY: "resource.type.function.category",
  TYPE_FUNCTION_NAME: "resource.type.function.name",
  P_TYPE_FUNCTION_ID: "resource.parent.type.function.id",
  P_TYPE_FUNCTION_CATEGORY: "resource.parent.type.function.category",
  P_TYPE_FUNCTION_NAME: "resource.parent.type.function.name",
  CHILDREN_RACK_AVAILABILITY: "resource.rackChildren.availability",
  CHILDREN_HARDWARE_ASSET_AVAILABILITY:
    "resource.hardwareAssetChildren.availability",
  ATTACHMENT_NAME: "resource.attachment.name",
  ATTACHMENT_DESCRIPTON: "resource.attachment.description",
  IMAGE_NAME: "resource.image.name",
  IMAGE_DESCRIPTON: "resource.image.description",
  EXT_HIERARCHY_LEVEL: "resource.hierarchy.level",
  EXT_FIRST_LEVEL_CHILD_COUNT: "resource.firstLevel.child.count",
  EXT_TOTAL_CHILD_COUNT: "resource.total.child.count",
  RESOURCE_FAVORITE_DTAE_FOR_USER: "resource.favorite.date.forUser",
  CHARACTERISTIC_POWER_CONSUMPTION: "resource.characteristic.powerConsumption",
  CHARACTERISTIC_WIDTH: "resource.characteristic.width",
  CHARACTERISTIC_HEIGHT: "resource.characteristic.height",
  CHARACTERISTIC_LENGTH: "resource.characteristic.length",
  CHARACTERISTIC_WEIGHT: "resource.characteristic.weight",
  CHARACTERISTIC_SERIAL_NUMBER: "resource.characteristic.serialNumber",
  CHARACTERISTIC_INVENTORY_NUMBER: "resource.characteristic.inventoryNumber",
  CHARACTERISTIC_RACK_UNIT_CAPACITY: "resource.characteristic.rackUnitCapacity",
  CHARACTERISTIC_MANUFACTURER: "resource.characteristic.manufacturer",
  CHARACTERISTIC_HEAT_EMISSION: "resource.characteristic.heatEmission",
  RESOURCE_PARENT_LIFECYCLE_STATUS:
    "resource.parent.characteristic.lifecycleStatus",
  RESOURCE_PARENT_LIFECYCLE_DATE:
    "resource.parent.characteristic.lifecycleDate",
  RESOURCE_PARENT_VERSION: "resource.parent.characteristic.version",
  RESOURCE_PARENT_CREATED_BY: "resource.parent.createdBy",
  RESOURCE_PARENT_CREATED_AT: "resource.parent.createdAt",
  RESOURCE_LOCATION_HARDWARE_ASSET_COUNT:
    "resource.location.hardwareAssetCount",
  RESOURCE_LOCATION_RACK_COUNT: "resource.location.rackCount",
  RESOURCE_RACK_HARDWARE_ASSET_COUNT: "resource.rack.hardwareAssetCount",
  PARENT_HAVE_CHILD_FOR_CATEGORY: "parent.have.child.for.category",
  PARENT_PARENT_NOT_HAVE_CHILD_FOR_CATEGORY:
    "parent.parent.not.have.child.for.category",
  RESOURCE_HAVE_CHILD_FOR_CATEGORY: "resource.have.child.for.category",
  RESOURCE_NOT_HAVE_CHILD_FOR_CATEGORY: "resource.not.have.child.for.category",
  PARENT_CHILDREN_COUNT: "resource.parent.childrenCount",
};

export const NOT_FILTERABLE_REPORT_FIELDS_KEYS = [
  "ID",
  "P_ID",
  "TAG_ID",
  "P_TAG_ID",
  "P_FUNCTION_ID",
  "FUNCTION_ID",
  "TYPE_ID",
  "P_TYPE_ID",
];

export const REPORT_CONDITION_OPERATORS = {
  EQUALS: "EQUALS",
  EQUALS_IGNORE_CASE: "EQUALS_IGNORE_CASE",
  NOT_EQUALS: "NOT_EQUALS",
  MATCH: "MATCH",
  EXACT_MATCH: "EXACT_MATCH",
  STARTS_WITH: "STARTS_WITH",
  ENDS_WITH: "ENDS_WITH",
  LIKE: "LIKE",
  LIKE_IGNORE_CASE: "LIKE_IGNORE_CASE",
  GREATER_THAN: "GREATER_THAN",
  LESS_THAN: "LESS_THAN",
  IN: "IN",
  NOT_IN: "NOT_IN",
  // EXISTS: "EXISTS",
  // NOT_EXISTS: "NOT_EXISTS",
};

export const REPORT_LOGICAL_OPERATORS = {
  AND: "AND",
  // NAND: "NAND",
  OR: "OR",
  // NOR: "NOR",
  // XOR: "XOR",
  // XNOR: "XNOR",
};

export const REPORT_AGGREGATION_TYPES = {
  COUNT: "COUNT",
  MINIMUM: "MINIMUM",
  MAXIMUM: "MAXIMUM",
  SUM: "SUM",
  AVERAGE: "AVERAGE",
};

export const FIELDS = Object.keys(REPORT_FIELDS)
  .filter(
    (key) => !NOT_FILTERABLE_REPORT_FIELDS_KEYS.some((item) => item === key)
  )
  .map((key) => {
    return {
      name: REPORT_FIELDS[key],
      label: REPORT_FIELDS[key],
    };
  });

export const RESULT_FIELDS = Object.keys(REPORT_FIELDS).map((key) => {
  return {
    name: REPORT_FIELDS[key],
    label: REPORT_FIELDS[key],
  };
});

export const COMBINATORS = Object.keys(REPORT_LOGICAL_OPERATORS).map((key) => {
  return {
    name: REPORT_LOGICAL_OPERATORS[key],
    label: REPORT_LOGICAL_OPERATORS[key],
  };
});

export const OPERATORS = Object.keys(REPORT_CONDITION_OPERATORS).map((key) => {
  return {
    name: REPORT_CONDITION_OPERATORS[key],
    label: REPORT_CONDITION_OPERATORS[key],
  };
});

export const AGGREGATION_TYPES = Object.keys(REPORT_AGGREGATION_TYPES).map(
  (key) => {
    return {
      name: REPORT_AGGREGATION_TYPES[key],
      label: REPORT_AGGREGATION_TYPES[key],
    };
  }
);

export const INITIAL_QUERY = {
  combinator: REPORT_LOGICAL_OPERATORS["AND"],
  rules: [],
};

export const BASIC_FIELDS = [
  {
    field: {
      name: REPORT_FIELDS["DISPLAY_ID"],
    },
    orderIndex: null,
    index: 0,
    sortMethod: null,
  },
  {
    field: {
      name: REPORT_FIELDS["NAME"],
    },
    orderIndex: null,
    index: 0,
    sortMethod: null,
  },
  {
    field: {
      name: REPORT_FIELDS["FUNCTION_NAME"],
    },
    orderIndex: null,
    index: 0,
    sortMethod: null,
  },
  {
    field: {
      name: REPORT_FIELDS["TYPE_NAME"],
    },
    orderIndex: null,
    index: 0,
    sortMethod: null,
  },
  {
    field: {
      name: REPORT_FIELDS["CREATED_AT"],
    },
    orderIndex: null,
    sortMethod: null,
    index: 0,
  },
];

export const ASSIGNMENT_LIST = [
  {
    label: "X-axis",
    value: "x",
  },
  {
    label: "Y-axis",
    value: "y",
  },
];

export const FUNCTION_CATEGORIES = ["LOCATION", "HARDWARE_ASSET", "RACK"];

export const LIFECYCLE_STATUSES = [
  "ACTIVE",
  "REQUESTED",
  "PLANNED",
  "FAILED",
  "SPARE",
  "DECOMMISSIONED",
  "UNRACKED",
  "RECYCLED",
];

export const DEFAULT_RESULT_FIELD = {
  displayName: "",
  field: "",
  orderIndex: null,
  sortMethod: null,
  usedForGrouping: false,
  aggregationTypes: [],
};

export const DEFAULT_GROUP_FIELD = {
  field: "",
};

export const DEFAULT_AGGREGATION = {
  field: REPORT_FIELDS["ID"],
  aggregationType: REPORT_AGGREGATION_TYPES["COUNT"],
  filters: [],
};

export const DEFAULT_ASSIGNMENT = ASSIGNMENT_LIST[0].value;

export const DEFAULT_WIDGET_AGGREGATION = {
  reportDefinitionAggregationId: "",
  generatedAggregationId: "",
  color: "#FFC1CC",
  paletteId: 1,
};

export const INITIAL_RESULT_FIELDS = [
  {
    ...DEFAULT_RESULT_FIELD,
    uniqueId: uuidv4(),
    field: REPORT_FIELDS["DISPLAY_ID"],
    displayName: "Nickname",
  },
  {
    ...DEFAULT_RESULT_FIELD,
    uniqueId: uuidv4(),
    field: REPORT_FIELDS["NAME"],
    displayName: "Name",
  },
  {
    ...DEFAULT_RESULT_FIELD,
    uniqueId: uuidv4(),
    field: REPORT_FIELDS["FUNCTION_NAME"],
    displayName: "Function",
  },
  {
    ...DEFAULT_RESULT_FIELD,
    uniqueId: uuidv4(),
    field: REPORT_FIELDS["TYPE_NAME"],
    displayName: "Type",
  },
];

export const INITIAL_GROUP_FIELDS = [];

export const INITIAL_AGGREGATIONS = [
  { uniqueId: uuidv4(), ...DEFAULT_AGGREGATION },
];

export const WIDGET_COLORS_DARK_THEME = [
  "#4C72B0",
  "#DD8452",
  "#55A867",
  "#C44E52",
  "#8172B3",
  "#937860",
  "#DA8BC3",
  "#8C8C8C",
  "#CCB974",
  "#64B5CD",
];

export const WIDGET_COLORS_LIGHT_THEME = [
  "#A1C9F4",
  "#F9B482",
  "#8DE5A1",
  "#F79F9B",
  "#D0BBFF",
  "#DEBB9B",
  "#F8B0E4",
  "#CFCFCF",
  "#FFFEA3",
  "#B9F2F0",
];

export const colorPalettes = {
  pastel: {
    light: {
      softPink: "#FFC1CC",
      mintGreen: "#B2F2BB",
      babyBlue: "#A3D8F4",
      lavender: "#D4A5FF",
      paleYellow: "#FFF9B6",
    },
    dark: {
      softPink: "#8B6570",
      mintGreen: "#507F5D",
      babyBlue: "#527A8B",
      lavender: "#705A8B",
      paleYellow: "#857C48",
    },
  },
  // Skip for now
  // gradient: {
  //   lightMode: {
  //     sunset: ["#FF9A8B", "#FF6A88"],
  //     ocean: ["#84FAB0", "#8FD3F4"],
  //     lavenderField: ["#D9AFD9", "#97D9E1"],
  //     warmGlow: ["#FFD89B", "#FFAD76"],
  //     mintBreeze: ["#B9FBC0", "#5EE7DF"],
  //   },
  //   darkMode: {
  //     sunset: ["#8B5451", "#8A4944"],
  //     ocean: ["#3C6F61", "#486A75"],
  //     lavenderField: ["#8A718F", "#566774"],
  //     warmGlow: ["#8A6C50", "#7D4F37"],
  //     mintBreeze: ["#2E5C5D", "#347E6E"],
  //   },
  // },
  monochromatic: {
    light: {
      lightBlue: "#B3E5FC",
      mediumBlue: "#81D4FA",
      deepBlue: "#4FC3F7",
      darkerBlue: "#29B6F6",
      deepestBlue: "#039BE5",
    },
    dark: {
      lightBlue: "#345A75",
      mediumBlue: "#2C4E68",
      deepBlue: "#25435A",
      darkerBlue: "#1F374C",
      deepestBlue: "#192C3E",
    },
  },
  "solid fill": {
    light: {
      vibrantRed: "#FF5252",
      brightOrange: "#FFAB40",
      vividGreen: "#69F0AE",
      boldBlue: "#448AFF",
      strongPurple: "#B388FF",
    },
    dark: {
      vibrantRed: "#B23B3B",
      brightOrange: "#B07B2E",
      vividGreen: "#4C8B73",
      boldBlue: "#2F5379",
      strongPurple: "#6F4C8B",
    },
  },
  bubble: {
    light: {
      bubblegumPink: "#FF92C2",
      skyBlue: "#85E3FF",
      softLime: "#C8FF91",
      peachy: "#FFD1B9",
      lilac: "#C299FF",
    },
    dark: {
      bubblegumPink: "#8A5369",
      skyBlue: "#46697D",
      softLime: "#6E7F59",
      peachy: "#7A5D50",
      lilac: "#594A7A",
    },
  },
};

export const WIDGET_MODES = {
  PREVIEW: "preview",
  FETCH: "fetch",
};

// Get top 4 items with bigger height and rest add to others
export const getReasonablePercentageDistribution = (data = []) => {
  const top4Items = data
    .sort((a, b) => {
      if (a.count !== b.count) {
        return b.count - a.count;
      }

      return a.label.localeCompare(b.label);
    })
    .slice(0, 4);

  const restItems = data.slice(4);

  if (restItems.length > 0) {
    return [
      ...top4Items,
      {
        label: "Others",
        count: restItems.reduce((acc, value) => {
          return acc + value.count;
        }, 0),
      },
    ];
  }

  return [...top4Items];
};

export const getMin = (data) => {
  if (!data || data.length === 0) return null;

  let minValue = data[0][AGGREGATION_COUNT_KEY];

  for (let i = 1; i < data.length; i++) {
    const value = data[i][AGGREGATION_COUNT_KEY];

    if (value < minValue) {
      minValue = value;
    }
  }

  return minValue;
};

export const getMax = (data) => {
  if (!data || data.length === 0) return null;

  let maxValue = data[0][AGGREGATION_COUNT_KEY];

  for (let i = 1; i < data.length; i++) {
    const value = data[i][AGGREGATION_COUNT_KEY];

    if (value > maxValue) {
      maxValue = value;
    }
  }

  return maxValue;
};

export const getAverage = (data) => {
  if (!data || data.length === 0) return null;

  let sum = 0;

  for (let i = 0; i < data.length; i++) {
    sum += data[i][AGGREGATION_COUNT_KEY];
  }
  return Math.round(sum / data.length);
};

export const handleMapDynamicColumns = (data) => {
  if (!data || data.length === 0) return [];

  const firstObject = data[0];
  const dynamicColumns = [];

  Object.keys(firstObject)
    .filter((key) => key !== "subRows")
    .forEach((key) => {
      const dynamicColumn = {
        id: key,
        label: key,
        minWidth: 170,
      };

      dynamicColumns.push(dynamicColumn);
    });

  return dynamicColumns;
};

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) {
      return order;
    }

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const parseQueryExecute = (query, reportingFields = []) => {
  const result = {
    logicalOperator: query.combinator.toUpperCase(),
    conditions: [],
    children: [],
  };

  for (const rule of query.rules) {
    if (rule.field) {
      // Simple condition
      const reportingField = reportingFields.find(
        (reportingField) => reportingField.name === rule.field
      );

      const multipleValuesOperator =
        rule.operator === REPORT_CONDITION_OPERATORS["IN"] ||
        rule.operator === REPORT_CONDITION_OPERATORS["NOT_IN"];

      const values = multipleValuesOperator
        ? rule.value.split(",")
        : rule.value;

      const conditionValues = multipleValuesOperator
        ? values.map((value) => {
            return {
              value,
            };
          })
        : [{ value: rule.value }];

      result.conditions.push({
        field: { id: reportingField?.id, name: rule.field },
        conditionOperator: rule.operator,
        values: conditionValues,
        valueType: "STATIC",
      });
    } else {
      // Nested group
      result.children.push(parseQueryExecute(rule, reportingFields));
    }
  }

  return result;
};

export const checkQueryValidity = (query) => {
  for (const rule of query.rules) {
    if (rule.field) {
      const multipleValuesOperator =
        rule.operator === REPORT_CONDITION_OPERATORS["IN"] ||
        rule.operator === REPORT_CONDITION_OPERATORS["NOT_IN"];

      if (!multipleValuesOperator) {
        // Validations will come here
        if (!Boolean(rule.value)) {
          return false;
        }
      }
    } else {
      // Nested group
      const result = checkQueryValidity(rule);

      if (!result) {
        return false;
      }
    }
  }

  return true;
};

export const parseQuery = (query, reportingFields = []) => {
  const result = {
    logicalOperator: query.combinator.toUpperCase(),
    conditions: [],
    children: [],
  };

  for (const rule of query.rules) {
    if (rule.field) {
      // Simple condition
      const reportingField = reportingFields.find(
        (reportingField) => reportingField.name === rule.field
      );

      const multipleValuesOperator =
        rule.operator === REPORT_CONDITION_OPERATORS["IN"] ||
        rule.operator === REPORT_CONDITION_OPERATORS["NOT_IN"];

      const values = multipleValuesOperator
        ? rule.value.split(",")
        : rule.value;

      const conditionValues = multipleValuesOperator
        ? values.map((value) => {
            return {
              value,
            };
          })
        : [{ value: rule.value }];

      result.conditions.push({
        fieldId: reportingField?.id,
        conditionOperator: rule.operator,
        values: conditionValues,
        valueType: "STATIC",
      });
    } else {
      // Nested group
      result.children.push(parseQuery(rule, reportingFields));
    }
  }

  return result;
};

export const filterEmptyRules = (query) => {
  // Recursive function to filter out empty rules
  const filterRules = (rules) => {
    return rules
      .map((rule) => {
        if (Array.isArray(rule.rules)) {
          // Recursively filter nested rules
          const filteredNestedRules = filterRules(rule.rules);

          // If the nested rules are empty, return null to mark for removal
          if (filteredNestedRules.length === 0) {
            return null;
          }

          // Otherwise, return the rule with the filtered nested rules
          return {
            ...rule,
            rules: filteredNestedRules,
          };
        }
        return rule;
      })
      .filter((rule) => rule !== null); // Remove rules marked for removal
  };

  // Filter the topmost rules, ensuring the top-level rules array is never empty
  const filteredTopRules = filterRules(query.rules);

  return {
    ...query,
    rules: filteredTopRules,
  };
};

export const addDefaultConditions = (query) => {
  // Recursive function to add default conditions to empty rules
  const addDefaultsToRules = (rules) => {
    const defaultCondition = {
      id: uuidv4(),
      field: REPORT_FIELDS["NAME"],
      operator: REPORT_CONDITION_OPERATORS["EQUALS"],
      valueSource: "value",
      value: "",
    };

    return rules.map((rule) => {
      if (Array.isArray(rule.rules)) {
        if (rule.rules.length === 0) {
          // Add default condition if the rules array is empty
          return {
            ...rule,
            rules: [defaultCondition],
          };
        } else {
          // Recursively process nested rules
          return {
            ...rule,
            rules: addDefaultsToRules(rule.rules),
          };
        }
      }
      return rule;
    });
  };

  // Only process the "rules" field of the topmost object
  return {
    ...query,
    rules: addDefaultsToRules(query.rules),
  };
};

export const addRuleById = (query, targetId, newRule) => {
  // Check if the top-level object matches the target ID
  if (query.id === targetId) {
    return {
      ...query,
      rules: query.rules ? [...query.rules, newRule] : [newRule],
    };
  }

  // Recursive function to find the rule by ID and add the new rule
  const addRule = (rules) => {
    return rules.map((rule) => {
      if (rule.id === targetId) {
        // If the rule ID matches the target ID, add the new rule to its rules array
        return {
          ...rule,
          rules: rule.rules ? [...rule.rules, newRule] : [newRule],
        };
      } else if (Array.isArray(rule.rules)) {
        // If the rule has nested rules, recursively process the nested rules
        return {
          ...rule,
          rules: addRule(rule.rules),
        };
      }

      return rule;
    });
  };

  // Start the process with the top-level rules array
  return {
    ...query,
    rules: addRule(query.rules),
  };
};

export const editRuleById = (query, targetId, updatedProperties) => {
  // Recursive function to find the rule by ID and update it
  const editRule = (rules) => {
    return rules.map((rule) => {
      if (rule.id === targetId) {
        // If the rule ID matches the target ID, update it with the new properties
        return {
          ...rule,
          ...updatedProperties,
        };
      } else if (Array.isArray(rule.rules)) {
        // If the rule has nested rules, recursively process the nested rules
        return {
          ...rule,
          rules: editRule(rule.rules),
        };
      }

      return rule;
    });
  };

  // Start the process with the top-level rules array
  return {
    ...query,
    rules: editRule(query.rules),
  };
};

export const constructQuery = (reportDefinitionFilter) => {
  const { id, logicalOperator, conditions, children } = reportDefinitionFilter;

  const query = {
    id,
    combinator: logicalOperator,
    rules: [],
  };

  for (const condition of conditions) {
    const { id, field, conditionOperator, values } = condition;

    const rule = {
      id,
      field: field.name,
      operator: conditionOperator,
      value: values.map((value) => value.value).join(","),
    };

    query.rules.push(rule);
  }

  for (const child of children ?? []) {
    query.rules.push(constructQuery(child));
  }

  return query;
};

export const addPropertyToChildren = (data, propName, propValue) => {
  const newData = Array.isArray(data) ? [] : { ...data }; // Create a copy

  if (Array.isArray(data)) {
    for (const item of data) {
      newData.push(addPropertyToChildren(item, propName, propValue));
    }
  } else if (data.hasOwnProperty("children")) {
    newData.children = addPropertyToChildren(
      data.children,
      propName,
      propValue
    );

    newData[propName] = propValue;
  }

  return newData;
};

export const handleMapDynamicAggregationResult = (
  data,
  excludeFields,
  aggregations
) => {
  if (!data || data.length === 0) return [];

  const firstObject = data[0];
  const dynamicColumns = [];

  Object.keys(firstObject)
    .filter((key) => !excludeFields?.some((field) => field?.field === key))
    .forEach((key) => {
      const dynamicColumn = {
        id: key,
        label: key,
        minWidth: 170,
      };

      dynamicColumns.push(dynamicColumn);
    });

  const aggregationTypes = aggregations?.map((aggregation) =>
    aggregation?.aggregationType?.toLowerCase()
  );

  const result = [];

  for (let i = 0; i < aggregationTypes?.length; i++) {
    const aggregationType = aggregationTypes[i];

    for (let j = 0; j < dynamicColumns?.length; j++) {
      const column = dynamicColumns[j];

      if (column?.id?.includes(aggregationType)) {
        result?.push(column);
        break;
      }
    }
  }

  return result;
};

export const constructMRTAggregatedCellContent = (
  column,
  aggregationFunctions,
  t,
  i18n,
  row
) => {
  if (!aggregationFunctions || aggregationFunctions.length <= 0) {
    return null;
  }

  const aggregationResults = [];
  const originalRow = row?.original;

  const rowObjectKeys = Object.keys(originalRow)?.filter((key) =>
    key.includes(column)
  );

  for (let i = 0; i < aggregationFunctions.length; i++) {
    const aggregationFunction = aggregationFunctions[i];

    const aggregationKeys = rowObjectKeys?.filter((key) =>
      key.includes(aggregationFunction.toLowerCase())
    );

    for (let j = 0; j < aggregationKeys.length; j++) {
      const aggregationKey = aggregationKeys[j];
      const aggregationResult = Number(originalRow[aggregationKey]?.toFixed(2));
      aggregationResults.push(aggregationResult);
    }
  }

  let result = "";

  for (let i = 0; i < aggregationFunctions.length - 1; i++) {
    const aggregationFunction = aggregationFunctions[i];

    result += `${getTranslation(aggregationFunction, t, i18n)}: (${
      isNaN(aggregationResults[i]) ? "N/A" : aggregationResults[i]
    }), `;
  }

  result += `${getTranslation(
    aggregationFunctions[aggregationFunctions.length - 1],
    t,
    i18n
  )}: (${
    isNaN(aggregationResults[aggregationFunctions.length - 1])
      ? "N/A"
      : aggregationResults[aggregationFunctions.length - 1]
  })`;

  return result;
};

export const generateSQLWhereClause = (data, t, i18n, region) => {
  // Further execution proceeds only if there are filters
  if (!data || data.length <= 0) {
    return "";
  }

  let whereClause = ""; // Initialize the WHERE clause string

  // Function to process conditions of a node
  const processConditions = (conditions, logicalOperator, depth, region) => {
    let conditionStr = ""; // Initialize the condition string for this node
    const indentation = "\t".repeat(depth); // Calculate indentation based on depth

    // Iterate through each condition
    conditions.forEach((condition, index) => {
      if (index > 0) {
        conditionStr += ` ${logicalOperator} `; // Add logical operator between conditions
      }

      let field = getTranslation(condition.field.name, t, i18n); // Get the name of the field
      let operator = ""; // Initialize the SQL operator
      let value = ""; // Initialize the value(s)

      // Determine the SQL operator and value(s) based on condition operator
      switch (condition.conditionOperator) {
        case "EQUALS":
        case "EQUALS_IGNORE_CASE":
        case "MATCH":
        case "EXACT_MATCH":
          operator = "="; // SQL operator for equality
          value = `'${condition.values[0].value}'`; // Single value enclosed in quotes
          break;
        case "NOT_EQUALS":
          operator = "!="; // SQL operator for inequality
          value = `'${condition.values[0].value}'`; // Single value enclosed in quotes
          break;
        case "STARTS_WITH":
          operator = "LIKE"; // SQL operator for pattern matching
          value = `'${condition.values[0].value}...'`; // Enclose value in quotes and append %
          break;
        case "ENDS_WITH":
          operator = "LIKE"; // SQL operator for pattern matching
          value = `'...${condition.values[0].value}'`; // Append % and enclose value in quotes
          break;
        case "LIKE":
        case "LIKE_IGNORE_CASE":
          operator = "LIKE"; // SQL operator for pattern matching
          value = `'...${condition.values[0].value}...'`; // Enclose value in quotes and append %
          break;
        case "GREATER_THAN":
          operator = ">"; // SQL operator for greater than
          value = condition.values[0].value; // Single value
          break;
        case "LESS_THAN":
          operator = "<"; // SQL operator for less than
          value = condition.values[0].value; // Single value
          break;
        case "IN":
          operator = "IN"; // SQL operator for set membership
          value = `('${condition.values.map((val) => val.value).join("','")}')`; // List of values enclosed in ()
          break;
        case "NOT_IN":
          operator = "NOT IN"; // SQL operator for set non-membership
          value = `('${condition.values.map((val) => val.value).join("','")}')`; // List of values enclosed in ()
          break;
        default:
          break;
      }

      const isDateField =
        condition.field.name === REPORT_FIELDS["CREATED_AT"] ||
        condition.field.name === REPORT_FIELDS["LIFECYCLE_DATE"] ||
        condition.field.name === REPORT_FIELDS["RESOURCE_TAG_MATCH_DATE"] ||
        condition.field.name ===
          REPORT_FIELDS["RESOURCE_PARENT_LIFECYCLE_DATE"] ||
        condition.field.name === REPORT_FIELDS["RESOURCE_PARENT_CREATED_AT"] ||
        condition.field.name === REPORT_FIELDS["P_RESOURCE_TAG_MATCH_DATE"];

      if (isDateField) {
        value = value.replaceAll("'", "");
      }

      value = isDateField ? localizeDate(value, region) : value;

      conditionStr += `${field} ${operator} ${value}`; // Concatenate field, operator, and value
    });

    return `${indentation}(${conditionStr})`; // Return the processed condition string with indentation
  };

  const traverse = (node, depth = 0) => {
    if (node.conditions.length > 0) {
      whereClause +=
        processConditions(
          node.conditions,
          node.logicalOperator,
          depth,
          region
        ) + "\n";
    }

    if (node.children.length > 0) {
      node.children.forEach((child) => {
        whereClause += `\t${node.logicalOperator}\n`;
        traverse(child, depth + 1);
      });
    }
  };

  traverse(data[0]); // Start traversing from the root node

  return whereClause ? `\n${whereClause}` : ""; // Return the generated WHERE clause, or an empty string if no conditions
};

export const constructAggregations = (executionResultFields) => {
  if (!executionResultFields || executionResultFields.length <= 0) {
    return [];
  }

  const aggregations = [];

  const aggregatedFields = executionResultFields.filter(
    (field) => field.aggregationTypes?.length > 0
  );

  aggregatedFields?.forEach((field, i) => {
    field?.aggregationTypes?.forEach((aggregationType, j) => {
      const id = `${i}_${j}`;

      const aggregation = {
        id,
        field: {
          name: field.field,
        },
        aggregationType,
        filters: [],
      };

      aggregations.push(aggregation);
    });
  });

  return aggregations;
};

export const mapCreatedByAttribute = (object) => {
  const keys = Object.keys(object);

  if (keys.some((key) => key === REPORT_FIELDS.CREATED_BY)) {
    object[REPORT_FIELDS.CREATED_BY] =
      object[REPORT_FIELDS.CREATED_BY] ?? "Unknown";
  }

  if (keys.some((key) => key === REPORT_FIELDS.RESOURCE_PARENT_CREATED_BY)) {
    object[REPORT_FIELDS.RESOURCE_PARENT_CREATED_BY] =
      object[REPORT_FIELDS.RESOURCE_PARENT_CREATED_BY] ?? "Unknown";
  }

  return object;
};

export const getPaletteValues = () => {
  const paletteValues = Object.keys(colorPalettes);
  return paletteValues;
};

export const getPaletteColor = (palette, theme, index) => {
  const selectedPalette = colorPalettes[palette][theme];
  const selectedPaletteKeys = Object.keys(selectedPalette);
  const selectedPaletteColorKey = selectedPaletteKeys[index];
  const selectedPaletteColor = selectedPalette[selectedPaletteColorKey];

  return selectedPaletteColor;
};

export const getDefaultPaletteColor = (palette, theme) => {
  const selectedPalette = colorPalettes[palette][theme];
  const selectedPaletteKeys = Object.keys(selectedPalette);
  const selectedPaletteColorKey = selectedPaletteKeys[0];
  const selectedPaletteColor = selectedPalette[selectedPaletteColorKey];

  return selectedPaletteColor;
};

export const getPaletteColorBySelection = (palette, theme, color) => {
  const selectedPalette = colorPalettes[palette][theme];
  const selectedPaletteColorKey = color;
  const selectedPaletteColor = selectedPalette[selectedPaletteColorKey];

  return selectedPaletteColor;
};

export const getPaletteColorValues = (palette, theme) => {
  const selectedPalette = colorPalettes[palette][theme];
  const selectedPaletteKeys = Object.keys(selectedPalette);

  return selectedPaletteKeys;
};

export const findColorName = (colorCode) => {
  const searchPalette = (palette) => {
    for (const key in palette) {
      const value = palette[key];

      if (typeof value === "object" && value !== null) {
        // Recursively search in nested objects
        const result = searchPalette(value);
        if (result) return result;
      } else if (value === colorCode) {
        return key; // Return the color name
      }
    }

    return null;
  };

  return searchPalette(colorPalettes);
};

export const findColorCode = (colorName) => {
  const searchPalette = (palette) => {
    for (const key in palette) {
      const value = palette[key];

      if (typeof value === "object" && value !== null) {
        // Recursively search in nested objects
        const result = searchPalette(value);
        if (result) return result;
      } else if (key === colorName) {
        return value; // Return the color code
      }
    }

    return null;
  };

  return searchPalette(colorPalettes);
};

export const mapPaletteToId = (palette) => {
  let id = 1;

  if (palette === "gradient") {
    id = 2;
  } else if (palette === "monochromatic") {
    id = 3;
  } else if (palette === "solid fill") {
    id = 4;
  } else if (palette === "bubble") {
    id = 5;
  }

  return id;
};

export const mapIdToPalette = (id) => {
  let palette = "pastel";

  if (id === 2) {
    palette = "gradient";
  } else if (id === 3) {
    palette = "monochromatic";
  } else if (id === 4) {
    palette = "solid fill";
  } else if (id === 5) {
    palette = "bubble";
  }

  return palette;
};

export const getAvailableWidgets = (groupFields, aggregations) => {
  // const nonAggregationsWidgets = [
  //   {
  //     widgetTypeId: WIDGET_TYPE.LINK,
  //     title: "Link",
  //     category: WIDGET_CATEGORY.LINK,
  //     reportTypes: ["SUMMARY", "LIST"],
  //   },
  // ];

  // if (aggregations?.length <= 0) {
  //   return nonAggregationsWidgets;
  // }

  if (groupFields?.length > 0) {
    const summaryWidgets = [
      // ...nonAggregationsWidgets,
      {
        widgetTypeId: WIDGET_TYPE.PIE_CHART,
        title: "Pie chart",
        category: WIDGET_CATEGORY.PIE_CHART,
        reportTypes: ["SUMMARY"],
      },
      {
        widgetTypeId: WIDGET_TYPE.DONUT_CHART,
        title: "Donut chart",
        category: WIDGET_CATEGORY.DONUT_CHART,
        reportTypes: ["SUMMARY"],
      },
      {
        widgetTypeId: WIDGET_TYPE.LINE_CHART,
        title: "Line chart",
        category: WIDGET_CATEGORY.LINE_CHART,
        reportTypes: ["SUMMARY"],
      },
      {
        widgetTypeId: WIDGET_TYPE.BAR_CHART,
        title: "Bar chart",
        category: WIDGET_CATEGORY.BAR_CHART,
        reportTypes: ["SUMMARY"],
      },
      {
        widgetTypeId: WIDGET_TYPE.FUNNEL_CHART,
        title: "Funnel chart",
        category: WIDGET_CATEGORY.FUNNEL_CHART,
        reportTypes: ["SUMMARY"],
      },
      {
        widgetTypeId: WIDGET_TYPE.AREA_CHART,
        title: "Area chart",
        category: WIDGET_CATEGORY.AREA_CHART,
        reportTypes: ["SUMMARY"],
      },
    ];

    return summaryWidgets;
  } else {
    const listWidgets = [
      // ...nonAggregationsWidgets,
      {
        widgetTypeId: WIDGET_TYPE.SINGLE_VALUE,
        title: "Single Value",
        category: WIDGET_CATEGORY.SINGLE_VALUE,
        reportTypes: ["LIST"],
      },
      {
        widgetTypeId: WIDGET_TYPE.DOUBLE_VALUE,
        title: "Double Value",
        category: WIDGET_CATEGORY.DOUBLE_VALUE,
        reportTypes: ["LIST"],
      },
    ];

    if (aggregations?.length >= 3) {
      return [
        ...listWidgets,
        {
          widgetTypeId: WIDGET_TYPE.MIN_MAX_AVERAGE,
          title: "Min Max Average",
          category: WIDGET_CATEGORY.MIN_MAX_AVERAGE,
          reportTypes: ["LIST"],
        },
        {
          widgetTypeId: WIDGET_TYPE.STATUS,
          title: "Status",
          category: WIDGET_CATEGORY.STATUS,
          reportTypes: ["LIST"],
        },
      ];
    }

    return listWidgets;
  }
};

export const compatibilityCheck = (widgetTypeId, hasGroupFields) => {
  let available = [];
  if (hasGroupFields) {
    available = [
      WIDGET_TYPE.PIE_CHART,
      WIDGET_TYPE.DONUT_CHART,
      WIDGET_TYPE.LINE_CHART,
      WIDGET_TYPE.BAR_CHART,
      WIDGET_TYPE.FUNNEL_CHART,
      WIDGET_TYPE.AREA_CHART,
    ];
  } else {
    available = [
      WIDGET_TYPE.MIN_MAX_AVERAGE,
      WIDGET_TYPE.SINGLE_VALUE,
      WIDGET_TYPE.DOUBLE_VALUE,
      WIDGET_TYPE.STATUS,
    ];
  }

  return available.includes(widgetTypeId);
};

export const constructNewWidget = (title, widgetTypeId, theme) => {
  let widget = {
    title,
    widgetTypeId,
    dataSources: [],
  };

  return widget;
};

export const getChartGroups = (
  rows,
  groupFields,
  finalAggregations,
  aggregationIds
) => {
  const result = {};

  aggregationIds?.forEach((aggregationId) => {
    const groups = [];

    rows?.forEach((row) => {
      let joinedGroupValue = "";
      const groupKeys = groupFields?.map((field) => field.field);

      for (let i = 0; i < groupKeys.length; i++) {
        const groupKey = groupKeys[i];
        joinedGroupValue += `${row[groupKey]} `;
      }

      const aggregation = finalAggregations?.find(
        (a) => a.id === aggregationId
      );

      const key =
        aggregation?.field?.name +
        `.aggregation-${aggregation?.aggregationType?.toLowerCase()}`;

      const value = row[key];

      const group = {
        label: joinedGroupValue,
        count: value,
      };

      groups.push(group);
    });

    result[aggregationId] = groups;
  });

  return result;
};

export const getChartPortions = (objOfGroups) => {
  const result = {};
  const keys = Object.keys(objOfGroups);

  keys?.forEach((key) => {
    const groups = objOfGroups[key];
    const portions = getReasonablePercentageDistribution(groups);
    result[key] = portions;
  });

  return result;
};

export const getChartData = (
  objOfPortions,
  finalAggregations,
  theme,
  type,
  t,
  i18n,
  dataSources,
  mode = WIDGET_MODES.PREVIEW
) => {
  const keys = Object.keys(objOfPortions);
  let datasets = [];
  let labels = [];

  keys?.forEach((key, i) => {
    const portions = objOfPortions[key];

    const currentLabels = portions?.map((portion) => portion.label);
    labels.push(...currentLabels);

    const aggregation = finalAggregations?.find((a) => a.id === key);

    const dataSource = dataSources?.find((ds) => {
      return mode === "preview"
        ? ds.generatedAggregationId === key
        : aggregation?.id === ds.reportDefinitionAggregationId;
    });

    const dataSourcePalette = mapIdToPalette(dataSource?.paletteId);
    const dataSourceColor = dataSource?.color;

    let dataset = {
      label: getTranslation(aggregation?.aggregationType, t, i18n),
      data: portions?.map((portion) => portion.count),
      backgroundColor: portions?.map((portion, j) =>
        getPaletteColor(dataSourcePalette, theme, j)
      ),
    };

    if (type === WIDGET_TYPE.LINE_CHART) {
      dataset = {
        label: getTranslation(aggregation?.aggregationType, t, i18n),
        data: portions?.map((portion) => portion.count),
        borderColor: dataSourceColor,
        fill: false,
        tension: 0.1,
      };
    } else if (type === WIDGET_TYPE.BAR_CHART) {
      dataset = {
        label: getTranslation(aggregation?.aggregationType, t, i18n),
        data: portions?.map((portion) => portion.count),
        borderColor: dataSourceColor,
        backgroundColor: dataSourceColor,
        borderWidth: 1,
      };
    } else if (type === WIDGET_TYPE.AREA_CHART) {
      dataset = {
        label: getTranslation(aggregation?.aggregationType, t, i18n),
        data: portions?.map((portion) => portion.count),
        borderColor: dataSourceColor,
        backgroundColor: dataSourceColor,
        fill: true,
      };
    }

    datasets?.push(dataset);
  });

  if (
    type === WIDGET_TYPE.BAR_CHART ||
    type === WIDGET_TYPE.LINE_CHART ||
    type === WIDGET_TYPE.AREA_CHART
  ) {
    const originalLabels = labels;
    const originalDatasets = datasets;

    labels = Array.from(new Set(originalLabels));

    const allData = [];

    datasets?.forEach((dataset) => {
      allData?.push(...dataset?.data);
    });

    const multiplier = allData?.length / datasets?.length;
    let acc = multiplier;
    let indexFrom = 0;

    datasets = originalDatasets?.map((dataset, i) => {
      const alignedData = labels?.map((label) => {
        const index = originalLabels?.indexOf(label, indexFrom);
        return index < acc ? allData[index] : 0;
      });

      acc += multiplier;
      indexFrom += multiplier;

      return {
        ...dataset,
        data: alignedData,
      };
    });
  }

  const data = {
    labels,
    datasets,
  };

  return data;
};

export const getMappedWidgets = (
  reportDefinitionWidgetsData,
  reportDefinitionAggregations,
  constructedAggregations
) => {
  const mappedWidgets = reportDefinitionWidgetsData.map((widget) => {
    // sort the dataSources ascending by index
    const clonedDataSources = widget.dataSources
      ?.slice()
      ?.sort((a, b) => a.index - b.index);

    const palette = clonedDataSources[0].paletteName.toLowerCase();

    // in widgetForm aggregations have generated ids so we need to map loaded data in similar structure
    const mappedDataSources = clonedDataSources.map((ds, index) => {
      const dbAggregation = reportDefinitionAggregations.find(
        (a) => a.id === ds.reportDefinitionAggregationId
      );
      const constructedAggregation = constructedAggregations?.find(
        (localAggregation) =>
          localAggregation?.field?.name === dbAggregation?.field?.name &&
          localAggregation?.aggregationType === dbAggregation?.aggregationType
      );

      return {
        ...ds,
        generatedAggregationId: constructedAggregation?.id,
        uniqueId: uuidv4(),
      };
    });

    let resultWidget = {
      title: widget.title,
      widgetTypeId: widget.widgetTypeId,
      palette,
      aggregationId: "",
      maxAggregationId: "",
      avgAggregationId: "",
      minAggregationId: "",
      aggregationIds: mappedDataSources.map((ds) => ds.generatedAggregationId),
      dataSources: mappedDataSources,
    };

    if (widget.widgetTypeId === WIDGET_TYPE.MIN_MAX_AVERAGE) {
      resultWidget = {
        ...resultWidget,
        maxAggregationId: mappedDataSources[0].generatedAggregationId,
        avgAggregationId: mappedDataSources[1].generatedAggregationId,
        minAggregationId: mappedDataSources[2].generatedAggregationId,
      };
    } else if (
      widget.widgetTypeId === WIDGET_TYPE.SINGLE_COUNT ||
      widget.widgetTypeId === WIDGET_TYPE.SUM
    ) {
      resultWidget = {
        ...resultWidget,
        aggregationId: mappedDataSources[0].generatedAggregationId,
      };
    } else if (widget.widgetTypeId === WIDGET_TYPE.FUNNEL_CHART) {
      resultWidget = {
        ...resultWidget,
        aggregationId: mappedDataSources[0].generatedAggregationId,
      };
    }

    return resultWidget;
  });

  return mappedWidgets;
};
