import { useMediaQuery, useTheme } from "@mui/material";
import { Group, Rect, Text } from "react-konva";
import { useSelector } from "react-redux";
import { selectGraphicalViewFitMode } from "../../../store/slices/appSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";

const RackUnitRectangle = ({
  i,
  usableAreaWidth,
  rectangleHeight,
  fitWidthModeEmptySpaceWidth,
  sideFrameWidth,
  rowNumber,
  rows,
}) => {
  const theme = useTheme();

  // General hooks
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);

  // Selectors
  const graphicalViewFitMode = useSelector(selectGraphicalViewFitMode);

  // Other variables
  const frameY = i * rectangleHeight;
  const textY = rectangleHeight < 15 ? frameY : frameY + rectangleHeight / 4;

  const initialX =
    !mobileMatches && graphicalViewFitMode === "height"
      ? fitWidthModeEmptySpaceWidth
      : 0;

  const isReversed = i >= rows / 2;
  const middleRowIndex = rows / 2;
  const normalizedRowIndex = isReversed ? rows - i : i;
  const normalizedIndex = normalizedRowIndex / middleRowIndex;

  const fillLinearGradientStartPoint = {
    x: 0,
    y: 0,
  };

  const fillLinearGradientEndPoint = {
    x: 0,
    y: rectangleHeight * 1.5,
  };

  const colorStops = [
    0,
    theme.palette.secondary.frame,
    normalizedIndex,
    theme.palette.primary.contrastText,
  ];

  return (
    <Group name="row" key={"rect-" + i}>
      <Rect
        x={initialX}
        y={frameY}
        width={sideFrameWidth}
        height={rectangleHeight}
        fillLinearGradientStartPoint={fillLinearGradientStartPoint}
        fillLinearGradientEndPoint={fillLinearGradientEndPoint}
        fillLinearGradientColorStops={colorStops}
        stroke={theme.palette.secondary.line}
      />
      <Text
        text={rowNumber}
        fontSize={12}
        fill={theme.palette.primary.main}
        x={initialX}
        y={textY}
        width={sideFrameWidth}
        align="center"
      />
      <Rect
        x={initialX + sideFrameWidth}
        y={frameY}
        width={usableAreaWidth}
        height={rectangleHeight}
        fill={theme.palette.primary.contrastText}
        stroke={theme.palette.secondary.line}
      />
      <Rect
        x={usableAreaWidth + initialX + sideFrameWidth}
        y={frameY}
        width={sideFrameWidth}
        height={rectangleHeight}
        fillLinearGradientStartPoint={fillLinearGradientStartPoint}
        fillLinearGradientEndPoint={fillLinearGradientEndPoint}
        fillLinearGradientColorStops={colorStops}
        stroke={theme.palette.secondary.line}
      />
      <Text
        text={rowNumber}
        fontSize={12}
        fill={theme.palette.primary.main}
        x={initialX + sideFrameWidth + usableAreaWidth}
        y={textY}
        width={sideFrameWidth}
        align="center"
      />
    </Group>
  );
};

export default RackUnitRectangle;
