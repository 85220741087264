import { useEffect } from "react";
import { Box, Grid, Paper, Stack, useTheme } from "@mui/material";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import DoubleValueWidgetContent from "./categorized-widgets/DoubleValueWidgetContent.jsx";
import MinMaxAverageWidgetContent from "./categorized-widgets/MinMaxAverageWidgetContent";
import PercentageWidgetContent from "./categorized-widgets/PercentageWidgetContent";
import StatusWidgetContent from "./categorized-widgets/StatusWidgetContent";
import PieChartWidgetContent from "./categorized-widgets/PieChartWidgetContent.jsx";
import DonutChartWidgetContent from "./categorized-widgets/DonutChartWidgetContent.jsx";
import LineChartWidgetContent from "./categorized-widgets/LineChartWidgetContent.jsx";
import BarChartWidgetContent from "./categorized-widgets/BarChartWidgetContent.jsx";
import FunnelChartWidgetContent from "./categorized-widgets/FunnelChartWidgetContent.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addWidget,
  removeWidget,
  selectGroupResultFields,
  selectResultFields,
  selectWidgetCategory,
  selectWidgets,
  setWidgetCategory,
} from "../../../store/slices/reportsSlice.js";
import AreaChartWidgetContent from "./categorized-widgets/AreaChartWidgetContent.jsx";
import WidgetForm from "./WidgetForm.jsx";
import {
  constructAggregations,
  constructNewWidget,
  getAvailableWidgets,
  WIDGET_CATEGORY,
} from "../../../util/reports-utils.js";
import SingleValueWidgetContent from "./categorized-widgets/SingleValueWidgetContent.jsx";
import WidgetSelectionCircle from "./WidgetSelectionCircle.jsx";

const PIE_CHART_DATA = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      label: "Test",
      data: [5, 5, 10],
      backgroundColor: ["#0A5EB4", "1A3C66", "#7598D7"],
    },
  ],
};

const DONUT_CHART = {
  labels: ["Red", "Blue", "Yellow"],
  datasets: [
    {
      fill: true,
      data: [5, 5, 10],
      backgroundColor: ["#0A5EB4", "1A3C66", "#7598D7"],
    },
  ],
  updateMode: "normal",
};

const LINE_CHART = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: "#095CAA",
      tension: 0.1,
    },
  ],
};

const BAR_CHART = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "My First Dataset",
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: ["#095CAA", "#095CAA", "#095CAA", "#095CAA"],
      borderColor: ["#095CAA", "#095CAA)", "#095CAA", "#095CAA"],
      borderWidth: 1,
    },
  ],
};

const FUNNEL_CHART = [
  {
    value: 100,
    name: "February",
    fill: "#095CAA",
  },
  {
    value: 80,
    name: "March",
    fill: "#095CAA",
  },
  {
    value: 70,
    name: "March",
    fill: "#095CAA",
  },
  {
    value: 60,
    name: "January",
    fill: "#095CAA",
  },
  {
    value: 30,
    name: "April",
    fill: "#095CAA",
  },
];

const AREA_CHART = {
  labels: ["January", "February", "March", "April"],
  datasets: [
    {
      label: "My Second dataset",
      data: [100, 20, 120, 70],
      borderColor: "#0076BC",
      backgroundColor: "#0076BC",
      fill: true,
    },
    {
      label: "My First dataset",
      data: [10, 100, 60, 80],
      borderColor: "#89A9D9",
      backgroundColor: "#89A9D9",
      fill: true,
    },
  ],
};

const AddWidgetWizardStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const theme = useTheme();

  // Selectors
  const widgetCategory = useSelector(selectWidgetCategory);
  const resultFields = useSelector(selectResultFields);
  const groupFields = useSelector(selectGroupResultFields);
  const widgets = useSelector(selectWidgets);

  // Other variables
  const finalAggregations = constructAggregations(resultFields);
  const filteredWidgets = getAvailableWidgets(groupFields, finalAggregations);

  // Handlers
  const handleAddWidget = (w) => {
    const { widgetTypeId, title } = w;

    if (!widgets?.some((wi) => wi.widgetTypeId === widgetTypeId)) {
      const newWidget = constructNewWidget(title, widgetTypeId, theme);
      dispatch(addWidget(newWidget));
    }
  };

  const handleRemoveWidget = (w) => {
    const { widgetTypeId } = w;
    dispatch(removeWidget(widgetTypeId));
  };

  const handleSelect = (w) => {
    const { category } = w;
    dispatch(setWidgetCategory(category));
    handleAddWidget(w);
  };

  // Effects
  // useEffect(() => {
  //   return () => {
  //     dispatch(setWidgetCategory(WIDGET_CATEGORY.LINK));
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Box>
      <SecondaryText>
        Do you want to add further widgets to show the report on your
        dashboards?
      </SecondaryText>
      <Grid container columnSpacing={3}>
        <Grid item xs={6}>
          <Grid marginTop="8px" container spacing={3}>
            {filteredWidgets?.map((w) => {
              const { widgetTypeId, title, category } = w;

              const isSelected = widgetCategory === category;
              const isAdded = widgets?.some(
                (wi) => wi.widgetTypeId === widgetTypeId
              );

              return (
                <Grid key={widgetTypeId} item xs={6}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: "180px",
                      backgroundColor: "#F1F1F1",
                      position: "relative",
                      cursor: "pointer",
                      border: `1px solid ${
                        isSelected
                          ? theme.palette.secondary.contrastText
                          : theme
                      }`,
                    }}
                    onClick={() => {
                      handleSelect(w);
                    }}
                  >
                    <Stack sx={{ height: "100%" }}>
                      <Grid container padding="8px" alignItems="center">
                        <Grid item xs>
                          {isAdded ? (
                            <WidgetSelectionCircle
                              backgroundColor={
                                theme.palette.secondary.contrastText
                              }
                              borderColor={theme.palette.secondary.contrastText}
                              showCheck={true}
                              widget={w}
                              onClick={handleRemoveWidget}
                            />
                          ) : (
                            <WidgetSelectionCircle
                              backgroundColor={
                                theme.palette.primary.contrastText
                              }
                              borderColor="#F1F1F1"
                              showCheck={false}
                              widget={w}
                              onClick={handleAddWidget}
                            />
                          )}
                        </Grid>
                        <Grid item xs>
                          <SecondaryText variant="body2" textAlign="center">
                            {title}
                          </SecondaryText>
                        </Grid>
                        <Grid item xs>
                          <Box sx={{ visibility: "hidden" }}></Box>
                        </Grid>
                      </Grid>

                      {category === WIDGET_CATEGORY.SINGLE_VALUE && (
                        <SingleValueWidgetContent value={144} />
                      )}

                      {category === WIDGET_CATEGORY.DOUBLE_VALUE && (
                        <DoubleValueWidgetContent value1={144} value2={12} />
                      )}

                      {category === WIDGET_CATEGORY.MIN_MAX_AVERAGE && (
                        <MinMaxAverageWidgetContent
                          min={12}
                          max={12}
                          avg={144}
                        />
                      )}

                      {/* {category === WIDGET_CATEGORY.PERCENTAGE && (
                        <PercentageWidgetContent percentage={75} />
                      )} */}

                      {category === WIDGET_CATEGORY.STATUS && (
                        <StatusWidgetContent
                          dangerCount={1}
                          warningCount={3}
                          successCount={144}
                        />
                      )}

                      {category === WIDGET_CATEGORY.PIE_CHART && (
                        <PieChartWidgetContent data={PIE_CHART_DATA} />
                      )}

                      {category === WIDGET_CATEGORY.DONUT_CHART && (
                        <DonutChartWidgetContent data={DONUT_CHART} />
                      )}

                      {category === WIDGET_CATEGORY.LINE_CHART && (
                        <LineChartWidgetContent data={LINE_CHART} />
                      )}

                      {category === WIDGET_CATEGORY.BAR_CHART && (
                        <BarChartWidgetContent data={BAR_CHART} />
                      )}

                      {category === WIDGET_CATEGORY.FUNNEL_CHART && (
                        <FunnelChartWidgetContent data={FUNNEL_CHART} />
                      )}

                      {category === WIDGET_CATEGORY.AREA_CHART && (
                        <AreaChartWidgetContent data={AREA_CHART} />
                      )}
                    </Stack>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <WidgetForm category={widgetCategory} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddWidgetWizardStep;
