import { styled } from "@mui/system";
import { alpha } from "@mui/material";
import { Item } from "../../assets/ListInlineView.styles";
import { Grid } from "@mui/material";
import { THEME } from "../../../../util/utils";

const getTypeItemBackgroundColor = ({ longPressed, isSelected, theme }) => {
  let backgroundColor = "transparent";
  if (longPressed) {
    backgroundColor = theme.palette.secondary.active;
  }

  if (isSelected) {
    backgroundColor = alpha(theme.palette.secondary.contrastText, 0.1);
  }

  return backgroundColor;
};

const getHoveredTableRowBackgroundColor = ({ isSelected, theme }) => {
  let backgroundColor = theme.palette.secondary.active;
  if (isSelected) {
    backgroundColor = alpha(theme.palette.secondary.contrastText, 0.2);
  }

  return backgroundColor;
};

export const TypeItemContainer = styled(Item)(
  ({ theme, selected, lastindex, longPressed }) => ({
    position: longPressed ? "relative" : "static",
    zIndex: longPressed ? 1200 : "auto",
    backgroundColor: getTypeItemBackgroundColor({
      theme,
      isSelected: selected,
      longPressed,
    }),
    ":hover": {
      backgroundColor: getHoveredTableRowBackgroundColor({
        isSelected: selected,
        theme,
      }),
    },
    borderBottom: !lastindex ? `1px solid ${theme.palette.divider}` : "",
  })
);

export const TypeNameGridContainer = styled(Grid)({
  overflow: "hidden",
  flexShrink: 1,
});
