import { Stack, alpha } from "@mui/material";
import { styled } from "@mui/system";
import { ListGridViewCard, PrimaryText } from "../ListGridView.styles";
import { THEME } from "../../../../util/utils";

const getAssetGridViewCardBackgroundColor = ({
  longPressed,
  isMobile,
  theme,
}) => {
  let backgroundColor = "transparent";

  if (longPressed || isMobile) {
    backgroundColor = theme.palette.secondary.active;
  }

  return backgroundColor;
};

export const AssetGridViewCard = styled(ListGridViewCard)(
  ({ theme, longPressed, ismobile }) => ({
    minHeight: "25px",
    display: "flex",
    boxSizing: "border-box",
    padding: "8px",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: getAssetGridViewCardBackgroundColor({
      longPressed,
      isMobile: ismobile,
      theme,
    }),
    height: "100%",
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
    "&:hover": {
      backgroundColor: theme.palette.secondary.active,
    },
  })
);

export const CardMediaContainer = styled(Stack)(({ theme }) => ({
  minHeight: "48px",
  minWidth: "64px",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

export const AssetText = styled(PrimaryText)(({ theme }) => ({
  textAlign: "center",
  width: "100%",
  maxWidth: theme.breakpoints.down("sm") ? "100px" : "",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
  whiteSpace: "break-spaces",
  wordBreak: "break-all",
  marginTop: "5px",
}));

export const ItemsNumberText = styled(PrimaryText)(({ theme }) => ({
  textAlign: "center",
  color: theme.palette.secondary.main,
  marginTop: "5px",
}));
