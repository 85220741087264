import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../util/utils";
import { useSelector } from "react-redux";
import {
  IMPORT_EXPORT_FLOWS,
  selectFlow,
  setFlow,
} from "../../../../store/slices/importExportSlice";
import { useDispatch } from "react-redux";
import { getSvgIcon } from "../../../../util/icons";
import { selectGlobalFontSize } from "../../../../store/slices/appSlice";

const AssetImportExportWizardChooseOptionStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const flow = useSelector(selectFlow);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const handleChange = (e) => {
    dispatch(setFlow(e.target.value));
  };

  const handleDownloadXLSX = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/Twinalize-XLS-Template.xlsx`;
    link.download = "Data Import Template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      <Typography sx={{ color: theme.palette.text.secondary }}>
        {getTranslation("IMPORT_EXPORT_FIRST_STEP_CAPTION", t, i18n)}
      </Typography>

      <FormControl sx={{ marginTop: "40px" }}>
        <FormLabel
          sx={{ color: theme.palette.primary.main }}
          id="demo-controlled-radio-buttons-group"
        >
          {getTranslation("CHOOSE_DESIRED_ACTION", t, i18n)}
        </FormLabel>

        <RadioGroup
          sx={{ alignItems: "start", marginTop: "15px" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={flow}
          onChange={handleChange}
        >
          <FormControlLabel
            value={IMPORT_EXPORT_FLOWS.EXPORT}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("EXPORT_OPTION", t, i18n)}
          />
          <FormControlLabel
            value={IMPORT_EXPORT_FLOWS.IMPORT}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("IMPORT_OPTION", t, i18n)}
          />
        </RadioGroup>
      </FormControl>

      <Typography
        sx={{ color: theme.palette.text.secondary, marginTop: "15px" }}
      >
        {getTranslation("DOWNLOAD_IMPORT_TEMPLATE", t, i18n)}
      </Typography>

      <Box
        onClick={handleDownloadXLSX}
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "15px",
          margin: "10px 0",
          width: "100%",
          cursor: "pointer",
          border: `1px solid ${theme.palette.primary.border}`,
          padding: "20px 15px",
        }}
      >
        <Box>
          {getSvgIcon(
            "IMPORT",
            iconSize,
            iconSize,
            theme.palette.secondary.contrastText
          )}
        </Box>

        <Box>
          <Typography sx={{ color: theme.palette.primary.main }}>
            Data Import Template.xlsx
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetImportExportWizardChooseOptionStep;
