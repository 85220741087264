import { styled } from "@mui/material";
import { Item, ItemContainer, ItemName } from "../ListInlineView.styles";
import { Typography, alpha } from "@mui/material";
import { THEME } from "../../../../util/utils";

const getBackgroundColor = ({ isActive, isSelected, longPressed, theme }) => {
  let backgroundColor = "transparent";
  if (isSelected || longPressed) {
    backgroundColor = theme.palette.secondary.active;
  }

  if (isActive) {
    backgroundColor = theme.palette.secondary.activeContrast;
  }

  return backgroundColor;
};

export const SelectedResourceItem = styled(ItemContainer)(
  ({ theme, isselected, isactive, longPressed }) => ({
    backgroundColor: getBackgroundColor({
      isActive: isactive,
      isSelected: isselected,
      longPressed,
      theme,
    }),
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
  })
);

export const ItemWrapper = styled(Item)({
  paddingInline: "16px",
});

export const ChildrenCountText = styled(Typography)(({ theme, isactive }) => ({
  color: isactive ? theme.palette.primary.main : theme.palette.secondary.main,
}));

export const ResourceItemName = styled(ItemName)(({ isactive, theme }) => ({
  marginLeft: 0,
  fontWeight: isactive ? "bold" : "normal",
}));
