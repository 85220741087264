import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CarouselImage from "./CarouselImage";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import AddImageButton from "./AddImageButton";
import { useSwipeable } from "react-swipeable";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { getSvgIcon } from "../../../util/icons";

const CarouselSlider = ({ resourceImages }) => {
  // General hooks
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);
  const containerRef = useRef(null);

  // State
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Handlers for swipe actions
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const current = containerRef.current;

      if (desktopMatches && current) {
        current.scrollBy({ left: 200, behavior: "smooth" });
      }
    },
    onSwipedRight: () => {
      const current = containerRef.current;

      if (desktopMatches && current) {
        current.scrollBy({ left: -200, behavior: "smooth" });
      }
    },
    delta: 10,
    trackMouse: true,
  });

  // Handlers
  const handleRefPassThrough = (current) => {
    if (current) {
      swipeHandlers.ref(current);
      containerRef.current = current;
    }
  };

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary
        value={borderWidth}
        expandIcon={
          <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SectionTitle variant="body2" istablet={1} ref={textRef}>
          {getTranslation("IMAGES", t, i18n)}
        </SectionTitle>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Box
          {...swipeHandlers}
          ref={handleRefPassThrough}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            columnGap: "20px",
            alignItems: "center",
            overflowX: "auto",
            scrollbarWidth: "none", // Firefox
            "-ms-overflow-style": "none", // IE and Edge
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for Chrome, Safari, and Opera
            },
            cursor: "grab",
          }}
        >
          {resourceImages?.map((resourceImage, index) => (
            <CarouselImage
              id="carousel-image"
              key={resourceImage.id}
              resourceImage={resourceImage}
              index={index}
            />
          ))}

          <AddImageButton
            resourceImages={resourceImages}
            imageCategory="FRONT"
          />

          <AddImageButton
            resourceImages={resourceImages}
            imageCategory="LEFT"
          />

          <AddImageButton
            resourceImages={resourceImages}
            imageCategory="RIGHT"
          />

          <AddImageButton
            resourceImages={resourceImages}
            imageCategory="REAR"
          />

          <AddImageButton
            resourceImages={resourceImages}
            imageCategory="OTHER"
          />
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CarouselSlider;
