import { List, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { ResourceAttachmentsListContainer } from "../../styles/assets/asset-attachments/ResourceAttachmentsList.styles";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { useEffect, useRef, useState } from "react";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { SecondaryContrastButton } from "../../styles/general/General.styles";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { selectResourceAttachments } from "../../../store/slices/resourceAttachmentSlice";
import LocalResourceAttachment from "./LocalResourceAttachment";
import AddLocalAttachmentDialog from "../asset-form/AddLocalAttachmentDialog";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { getSvgIcon } from "../../../util/icons";
import { FORM_MODE } from "../../../util/asset-utils";

const LocalResourceAttachmentList = ({ mode }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();
  const navigate = useNavigate();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const parentId = useSelector(selectParentId);
  const currentTheme = useSelector(selectTheme);
  const localResourceAttachments = useSelector(selectResourceAttachments);
  const currentResourceId = useSelector(selectCurrentResourceId);

  // Other variables
  const calculatedResourceId = currentResourceId || resourceid || parentId;

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // State
  const [openAttachmentDialog, setOpenAttachmentDialog] = useState(false);
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Handlers
  const handleAddResourceAttachmentClick = () => {
    if (mobileMatches) {
      const resourceId = calculatedResourceId;

      if (mode === FORM_MODE.DUPLICATE) {
        navigate(
          `/resources/${resourceId}/duplicate/add-local-attachment?direction=${transitionDirections.RIGHT_TO_LEFT}`
        );
      } else {
        navigate(
          `/resources${
            resourceId ? `/${resourceId}` : ""
          }/add-local-attachment?direction=${
            transitionDirections.RIGHT_TO_LEFT
          }`
        );
      }
    } else {
      setOpenAttachmentDialog(true);
    }
  };

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, []);

  return (
    <StyledAccordion defaultExpanded={localResourceAttachments?.length > 0}>
      <StyledAccordionSummary
        value={borderWidth}
        expandIcon={
          <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SectionTitle variant="body2" istablet={1} ref={textRef}>
          {getTranslation("ATTACHMENTS", t, i18n)}
        </SectionTitle>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <ResourceAttachmentsListContainer id="attachments">
          <List id="resource-attachment-list">
            {localResourceAttachments
              ?.slice()
              ?.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )
              ?.map((resourceAttachment, index) => {
                return (
                  <LocalResourceAttachment
                    id={resourceAttachment?.id}
                    key={index}
                    attachment={resourceAttachment}
                    resourceid={calculatedResourceId}
                    index={index}
                    mode={mode}
                  />
                );
              })}

            <SecondaryContrastButton
              sx={{ marginTop: "10px" }}
              onClick={handleAddResourceAttachmentClick}
            >
              <div style={{ marginRight: "5px", marginTop: "5px" }}>
                {getSvgIcon(
                  "CREATE_NEW",
                  iconSize,
                  iconSize,
                  theme.palette.secondary.contrastText
                )}
              </div>

              {getTranslation("ADD_ATTACHMENT", t, i18n)}
            </SecondaryContrastButton>
          </List>

          {openAttachmentDialog && (
            <AddLocalAttachmentDialog
              open={openAttachmentDialog}
              setOpen={setOpenAttachmentDialog}
              resourceId={mode === "edit-asset" ? calculatedResourceId : null}
            />
          )}
        </ResourceAttachmentsListContainer>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default LocalResourceAttachmentList;
