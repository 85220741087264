import { Box, useTheme } from "@mui/material";
import { StyledAvatar } from "../../components/styles/header/Header.styles";
import { useGetOrganizationLogoQuery } from "../../store/slices/api/organizationsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { useUpdateUserOrganizationMutation } from "../../store/slices/api/userManagementSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { getTranslation } from "../../util/utils";
import { useTranslation } from "react-i18next";
import {
  ManageOrganizationsMenuItem,
  OrganizationsMenuItemAvatarContainer,
  OrganizationsMenuItemTypographyBoxContainer,
} from "../../components/styles/profile/ProfileDesktop.styles";
import { PrimaryText } from "../../components/styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { useNavigate } from "react-router-dom";
import ErrorHandling from "../../components/common/ErrorHandling";
import useCheckOrganizationRestricted from "../../hooks/useCheckOrganizationRestricted";
import { getSvgIcon } from "../../util/icons";
import { selectGlobalFontSize } from "../../store/slices/appSlice";

const OrganizationMenuItem = ({ organization }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const activeOrganization = user?.organizations?.find((o) => o.default);
  const iconSize = globalFontSize * 1.2;

  // Mutations
  const [
    updateUserOrganization,
    { isLoading: isLoadingUpdateUserOrganization },
  ] = useUpdateUserOrganizationMutation();

  // Custom hooks
  const { isLoading, isRestricted } = useCheckOrganizationRestricted(
    organization,
    isLoadingUpdateUserOrganization
  );

  // Queries
  const { data: organizationLogo } = useGetOrganizationLogoQuery(
    {
      logoUri: organization.logoUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(organization.logoUri) || isRestricted,
    }
  );

  // Handlers
  const handleChangeOrganization = async (organizationId) => {
    navigate("/");

    const newOrganizationId = organizationId;

    const newOrganizations = user?.organizations?.map((org) => {
      return org.id === newOrganizationId
        ? { ...org, default: true }
        : { ...org, default: false };
    });

    try {
      await updateUserOrganization({
        userId: user.id,
        newOrganizationId: newOrganizationId,
      }).unwrap();

      dispatch(
        setUser({
          ...user,
          defaultOrganizationId: newOrganizationId,
          organizations: newOrganizations,
        })
      );

      messageSuccess(
        getTranslation("successfulUpdateUserOrganization", t, i18n)
      );
    } catch (error) {
      messageError(getTranslation("failedUpdateUserOrganization", t, i18n));
    }
  };

  return (
    <ErrorHandling
      isLoading={isLoadingUpdateUserOrganization || isLoading}
      isError={false}
    >
      <ManageOrganizationsMenuItem
        id={`organization-${organization.id}`}
        onClick={() => {
          if (!isRestricted) {
            handleChangeOrganization(organization.id);
          }
        }}
      >
        <OrganizationsMenuItemAvatarContainer>
          <StyledAvatar id="organization-avatar" src={organizationLogo}>
            {organization.nickname
              ? organization.nickname.substring(0, 2).toUpperCase()
              : organization.name.substring(0, 2).toUpperCase()}
          </StyledAvatar>

          <OrganizationsMenuItemTypographyBoxContainer>
            <PrimaryText>
              {organization.nickname || organization.name}
            </PrimaryText>

            {isRestricted && (
              <PrimaryText fontWeight="bold">(Restricted)</PrimaryText>
            )}
          </OrganizationsMenuItemTypographyBoxContainer>

          {organization.id === activeOrganization?.id && (
            <Box>
              {getSvgIcon(
                "CHECK",
                iconSize,
                iconSize,
                theme.palette.primary.main
              )}
            </Box>
          )}
        </OrganizationsMenuItemAvatarContainer>
      </ManageOrganizationsMenuItem>
    </ErrorHandling>
  );
};

export default OrganizationMenuItem;
