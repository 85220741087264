import { useSelector } from "react-redux";
import { Typography, useTheme } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  StyledReportsWizardTimeline,
  StyledTimelineConnector,
  StyledTimelineDot,
} from "../../styles/reports/Reports.styles";
import {
  IMPORT_EXPORT_FLOWS,
  selectFlow,
  selectWizardStep,
} from "../../../store/slices/importExportSlice";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const AssetsImportExportWizardTimeline = () => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const wizardStep = useSelector(selectWizardStep);
  const globalFontSize = useSelector(selectGlobalFontSize);
  const flow = useSelector(selectFlow);

  // Other variables
  const iconSize = globalFontSize * 1.2;
  const height = flow === IMPORT_EXPORT_FLOWS.EXPORT ? "100px" : "50px";

  return (
    <StyledReportsWizardTimeline sx={{ paddingInline: "5px" }}>
      <TimelineItem sx={{ justifyContent: "end" }}>
        <TimelineSeparator>
          <StyledTimelineDot
            sx={{
              "&.MuiTimelineDot-root": {
                backgroundColor: wizardStep >= 1 ? "#1976D2" : "#7D8182",
              },
            }}
            wizardStep={wizardStep}
            targetWizardStep={1}
            id="first-step"
            role="button"
          >
            {wizardStep > 1 ? (
              getSvgIcon(
                "CHECK",
                iconSize,
                iconSize,
                theme.palette.primary.contrastText
              )
            ) : (
              <Typography align="center">1</Typography>
            )}
          </StyledTimelineDot>

          <StyledTimelineConnector
            sx={{ "&.MuiTimelineConnector-root": { height } }}
          />
        </TimelineSeparator>
      </TimelineItem>

      <TimelineItem sx={{ justifyContent: "end" }}>
        <TimelineSeparator>
          <StyledTimelineDot
            sx={{
              "&.MuiTimelineDot-root": {
                backgroundColor: wizardStep >= 2 ? "#1976D2" : "#7D8182",
              },
            }}
            wizardStep={wizardStep}
            targetWizardStep={2}
            id="second-step"
            role="button"
          >
            {wizardStep > 2 ? (
              getSvgIcon(
                "CHECK",
                iconSize,
                iconSize,
                theme.palette.primary.contrastText
              )
            ) : (
              <Typography align="center">2</Typography>
            )}
          </StyledTimelineDot>

          <StyledTimelineConnector
            sx={{ "&.MuiTimelineConnector-root": { height } }}
          />
        </TimelineSeparator>
      </TimelineItem>

      <TimelineItem sx={{ justifyContent: "end" }}>
        <TimelineSeparator>
          <StyledTimelineDot
            sx={{
              "&.MuiTimelineDot-root": {
                backgroundColor: wizardStep >= 3 ? "#1976D2" : "#7D8182",
              },
            }}
            wizardStep={wizardStep}
            targetWizardStep={3}
            id="third-step"
            role="button"
          >
            {wizardStep > 3 ? (
              getSvgIcon(
                "CHECK",
                iconSize,
                iconSize,
                theme.palette.primary.contrastText
              )
            ) : (
              <Typography align="center">3</Typography>
            )}
          </StyledTimelineDot>

          {flow === IMPORT_EXPORT_FLOWS.IMPORT && (
            <StyledTimelineConnector
              sx={{ "&.MuiTimelineConnector-root": { height } }}
            />
          )}
        </TimelineSeparator>
      </TimelineItem>

      {flow === IMPORT_EXPORT_FLOWS.IMPORT && (
        <TimelineItem sx={{ justifyContent: "end" }}>
          <TimelineSeparator>
            <StyledTimelineDot
              sx={{
                "&.MuiTimelineDot-root": {
                  backgroundColor: wizardStep >= 4 ? "#1976D2" : "#7D8182",
                },
              }}
              wizardStep={wizardStep}
              targetWizardStep={4}
              id="fourth-step"
              role="button"
            >
              {wizardStep > 4 ? (
                getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.primary.contrastText
                )
              ) : (
                <Typography align="center">4</Typography>
              )}
            </StyledTimelineDot>

            <StyledTimelineConnector
              sx={{ "&.MuiTimelineConnector-root": { height } }}
            />
          </TimelineSeparator>
        </TimelineItem>
      )}

      {flow === IMPORT_EXPORT_FLOWS.IMPORT && (
        <TimelineItem sx={{ justifyContent: "end" }}>
          <TimelineSeparator>
            <StyledTimelineDot
              sx={{
                "&.MuiTimelineDot-root": {
                  backgroundColor: wizardStep >= 5 ? "#1976D2" : "#7D8182",
                },
              }}
              wizardStep={wizardStep}
              targetWizardStep={5}
              id="fifth-step"
              role="button"
            >
              {wizardStep > 5 ? (
                getSvgIcon(
                  "CHECK",
                  iconSize,
                  iconSize,
                  theme.palette.primary.contrastText
                )
              ) : (
                <Typography align="center">5</Typography>
              )}
            </StyledTimelineDot>
          </TimelineSeparator>
        </TimelineItem>
      )}
    </StyledReportsWizardTimeline>
  );
};

export default AssetsImportExportWizardTimeline;
