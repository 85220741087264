import { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  DEFAULT_COLOR,
  removeTags,
  resetTags,
  selectAllTags,
  selectHaveChanges,
  selectInitialTags,
  selectInputValue,
  setAutocompleteOpen,
  setCurrentColor,
  setError,
  setGlobalTags,
  setInitialTags,
  setInputValue,
  setIsInDialog,
  setMarkedTag,
  setTags,
} from "../../../store/slices/tagsSlice";
import {
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
} from "../../../store/slices/api/assetManagementSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
  usePatchResourceTagsMutation,
} from "../../../store/slices/api/tagsSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { messageSuccess } from "../../../util/notification";
import {
  getTranslation,
  RESOURCE_CATEGORIES,
  showValidationError,
} from "../../../util/utils";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import TagsInput from "../tags/TagsInput";
import { StaticHeightDialogContent } from "../../styles/assets/ChooseAssetImagePage.styles";
import ErrorHandling from "../../common/ErrorHandling";
import { selectParentId } from "../../../store/slices/columnViewSlice";
import { useParams } from "react-router-dom";
import { selectCurrentResourceId } from "../../../store/slices/longPressSlice";
import { selectIsInDuplicate } from "../../../store/slices/assetListSlice";
import { selectPageView } from "../../../store/slices/appSlice";

const DuplicateTagsDialog = ({ open, setOpen, id }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { resourceid } = useParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const initialTags = useSelector(selectInitialTags);
  const tags = useSelector(selectAllTags);
  const inputValue = useSelector(selectInputValue);
  const parentId = useSelector(selectParentId);
  const currentResourceId = useSelector(selectCurrentResourceId);
  const currentPageView = useSelector(selectPageView);

  // Other variables
  const calculatedResourceId =
    currentResourceId || resourceid || parentId || id;

  const resourceId = calculatedResourceId;
  const haveChanges = !shallowEqual(initialTags, tags);

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResource,
    isError: isErrorResource,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId) || resourceId === "undefined",
    }
  );

  const {
    data: tagsData,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid: resourceId,
    organizationId,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  // Mutations
  const [patchResourceTags] = usePatchResourceTagsMutation();

  // Other variables
  const resourceFunction = allFunctionsData?.find(
    (f) => f.id === resourceData?.functionId
  );

  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const isLoading =
    currentPageView !== "column"
      ? isLoadingFunctions ||
        isLoadingResource ||
        isLoadingResourceTags ||
        isLoadingTags
      : false;

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleReset = () => {
    dispatch(setInputValue(""));
    dispatch(setError(""));
    dispatch(setAutocompleteOpen(false));
    dispatch(setCurrentColor(DEFAULT_COLOR));
    dispatch(setMarkedTag(null));
  };

  const handleCancel = () => {
    if (haveChanges) {
      setOpenConfirm(true);
      dispatch(setAutocompleteOpen(false));
    } else {
      handleReset();
      handleClose();
    }
  };

  const handleConfirmAlert = () => {
    handleReset();
    dispatch(resetTags());
    handleClose();
  };

  const handleDone = () => {
    dispatch(setInitialTags(tags));
    handleReset();
    handleClose();
  };

  // Effects
  useEffect(() => {
    dispatch(setInitialTags(tags));
    dispatch(setIsInDialog(true));

    return () => dispatch(setIsInDialog(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorHandling
      isLoading={isLoading}
      isError={
        isErrorFunctions ||
        isErrorResource ||
        isErrorResourceTags ||
        isErrorTags
      }
    >
      <>
        <Dialog fullWidth maxWidth="xs" onClose={handleClose} open={open}>
          <DialogTitle>{getTranslation("EDIT_TAGS", t, i18n)}</DialogTitle>
          <StaticHeightDialogContent>
            <ConfirmAlert
              isOpen={openConfirm}
              setIsOpen={setOpenConfirm}
              alert={alert}
              handleConfirm={handleConfirmAlert}
            />
            <TagsInput id={resourceId} shouldFocus={true} />
          </StaticHeightDialogContent>
          <DialogActions>
            <CancelButton variant="outlined" onClick={handleCancel}>
              {getTranslation("CANCEL", t, i18n)}
            </CancelButton>
            <CreateButton
              disabled={Boolean(inputValue) || !haveChanges}
              onClick={handleDone}
            >
              {getTranslation("DONE", t, i18n)}
            </CreateButton>
          </DialogActions>
        </Dialog>
      </>
    </ErrorHandling>
  );
};

export default DuplicateTagsDialog;
