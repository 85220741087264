import InvitationForm from "../components/InvitationForm";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/authSlice";

const CreateReferralPage = () => {
  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  return <InvitationForm organizationId={organizationId} />;
};

export default CreateReferralPage;
