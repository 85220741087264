import { Box, Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { alpha, styled } from "@mui/system";
import {
  ReportsWizardGridContainer,
  ReportWizardTimeLineContainer,
} from "../../reports/Reports.styles";

export const StackContainer = styled(Stack)({
  paddingInline: "16px",
});

export const SwitchControlLabel = styled(FormControlLabel)({
  maxWidth: "fit-content",
  marginRight: 0,
});

export const AssetsImportExportWizardTimeLineContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const AssetsImportExportWizardGridContainer = styled(
  ReportsWizardGridContainer
)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const AssetsImportExportWizardBackButton = styled(Button)({
  borderColor: "#1976D2",
  color: "#1976D2",
  boxShadow: "none",
  "&:hover": {
    borderColor: "#1976D2",
  },
  borderRadius: "5px",
  marginRight: "10px",
});

export const AssetsImportExportWizardContinueButton = styled(Button)(
  ({ theme }) => ({
    backgroundColor: "#1976D2",
    color: theme.palette.primary.contrastText,
    marginRight: "10px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#1976D2",
    },
    borderRadius: "5px",
  })
);

export const ExportIncludesSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.secondary.contrastText,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));
