import { MenuList, useMediaQuery, useTheme } from "@mui/material";
import ProfilePreferenceItem from "../../components/ProfilePreferenceItem";
import { useNavigate, useSearchParams } from "react-router-dom";
import { StyledDivider } from "../../components/styles/profile/Profile.styles";
import PageTransition from "../../components/PageTransition";
import {
  getTranslation,
  transitionDirections,
  urlBase64ToUint8Array,
} from "../../util/utils";
import PreferencesHeader from "../../navigation/header/profile/PreferencesHeader";
import { VIEWPORT_MEDIA_QUERIES } from "../../util/viewport-utils";
import DesktopSettings from "../../components/profile/DesktopSettings";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShowNotifications,
  selectIsSubscribed,
  setIsSubscribed,
  selectGlobalFontSize,
} from "../../store/slices/appSlice";
import { useTranslation } from "react-i18next";
import { messageSuccess } from "../../util/notification";
import { useSubscribe } from "react-pwa-push-notifications";
import { useSubscribeMutation } from "../../store/slices/api/notificationsApiSlice";
import { PUBLIC_PUSH_KEY } from "../../App";
import {
  HomePagePadding,
  StyledListItemIcon,
  StyledSwitch,
} from "../../components/styles/general/General.styles";
import { PreferenceItemName } from "../../components/styles/profile/PreferenceItem.styles";
import {
  PreferenceItemNameWrapper,
  PreferenceMenuItem,
} from "../../components/styles/profile/ProfilePreferenceItem.styles";
import ProfileDesktopHeader from "../../navigation/header/profile/desktop/ProfileDesktopHeader";
import AppAccess from "../../components/common/AppAccess";
import { getSvgIcon } from "../../util/icons";

const Settings = () => {
  // Global hooks
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const { getSubscription } = useSubscribe({ publicKey: PUBLIC_PUSH_KEY });
  const theme = useTheme();

  // Selectors
  const showNotifications = useSelector(selectShowNotifications);
  const isSubscribed = useSelector(selectIsSubscribed);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Mutations
  const [subscribe] = useSubscribeMutation();

  // Handlers
  const onLanguageClickHandler = () => {
    navigate(
      `/profile/language?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const onThemeClickHandler = () => {
    navigate(`/profile/theme?direction=${transitionDirections.RIGHT_TO_LEFT}`);
  };

  const onTimeZoneClickHandler = () => {
    navigate(
      `/profile/preferences/time-zone?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const onRegionalPreferenceClickHandler = () => {
    navigate(
      `/profile/preferences/regional-preference?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  const onSubscribe = async () => {
    try {
      const subscription = await getSubscription();

      const notificationsSubscribeDto = {
        endpoint: subscription.endpoint,
        p256dh: subscription.toJSON().keys.p256dh,
        auth: subscription.toJSON().keys.auth,
      };

      await subscribe({
        notificationsSubscribeDto,
      }).unwrap();

      dispatch(setIsSubscribed(true));

      messageSuccess(getTranslation("SUBSCRIBED_FOR_NOTIFICATIONS", t, i18n));
    } catch (e) {
      if (e.errorCode === "ExistingSubscription") {
        const registration = await navigator.serviceWorker.ready;
        const convertedVapidKey = urlBase64ToUint8Array(PUBLIC_PUSH_KEY);

        const existingSubscription = await registration.pushManager.subscribe({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true,
        });

        const notificationsSubscribeDto = {
          endpoint: existingSubscription.endpoint,
          p256dh: existingSubscription.toJSON().keys.p256dh,
          auth: existingSubscription.toJSON().keys.auth,
        };

        await subscribe({
          notificationsSubscribeDto,
        }).unwrap();

        dispatch(setIsSubscribed(true));

        messageSuccess(getTranslation("SUBSCRIBED_FOR_NOTIFICATIONS", t, i18n));
      } else {
        console.error("Subscribe to push failed", e);
      }
    }
  };

  const onUnsubscribe = async () => {
    const registration = await navigator.serviceWorker.ready;

    const existingSubscription =
      await registration.pushManager.getSubscription();

    const result = await existingSubscription.unsubscribe();

    if (result) {
      dispatch(setIsSubscribed(false));

      messageSuccess(
        getTranslation("UNSUBSCRIBED_FROM_NOTIFICATIONS", t, i18n)
      );
    }
  };

  const handleChange = async () => {
    if (isSubscribed) {
      await onUnsubscribe();
    } else {
      await onSubscribe();
    }
  };

  // Other variables
  const transitionDirection = searchParams.get("direction");
  const iconSize = globalFontSize * 1.5;

  // Handlers
  const goBackHandler = () =>
    navigate(`/profile?direction=${transitionDirections.LEFT_TO_RIGHT}`);

  return (
    <AppAccess>
      {!mobileMatches ? (
        <>
          <ProfileDesktopHeader />
          <DesktopSettings />
        </>
      ) : (
        <PageTransition direction={transitionDirection}>
          <PreferencesHeader goBackHandler={goBackHandler} />
          <HomePagePadding>
            <MenuList>
              <StyledDivider />
              {/* Profile preference menu item about theme */}
              <ProfilePreferenceItem
                handleClick={onThemeClickHandler}
                icon={getSvgIcon(
                  "THEME",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="theme"
                labelId="btn-profile-theme"
                menuId="btn-menu-item-profile-theme"
              />
              <StyledDivider />
              {/* Profile preference menu item about time zone */}
              <ProfilePreferenceItem
                handleClick={onTimeZoneClickHandler}
                icon={getSvgIcon(
                  "TIME_ZONE",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="timeZone"
                labelId="btn-profile-time-zone"
                menuId="btn-menu-item-profile-time-zone"
              />
              <StyledDivider />

              {/* Profile preference menu item about Region */}
              <ProfilePreferenceItem
                handleClick={onRegionalPreferenceClickHandler}
                icon={getSvgIcon(
                  "REGIONAL_PREFERENCES",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="regionalPreference"
                labelId="btn-profile-regional-preference"
                menuId="btn-menu-item-profile-regional-preference"
              />
              <StyledDivider />
              {/* Profile preference menu item about language */}
              <ProfilePreferenceItem
                handleClick={onLanguageClickHandler}
                icon={getSvgIcon(
                  "GLOBE",
                  iconSize,
                  iconSize,
                  theme.palette.primary.main
                )}
                label="LANGUAGE"
                labelId="btn-profile-language"
                menuId="btn-menu-item-profile-language"
              />
              <StyledDivider />
              {showNotifications && (
                <PreferenceMenuItem>
                  <StyledListItemIcon globalFontSize={globalFontSize}>
                    {getSvgIcon(
                      "NOTIFICATION",
                      iconSize,
                      iconSize,
                      theme.palette.primary.main
                    )}
                  </StyledListItemIcon>

                  <PreferenceItemNameWrapper>
                    <PreferenceItemName id={"notifications"}>
                      {getTranslation("NOTIFICATIONS", t, i18n)}
                    </PreferenceItemName>
                  </PreferenceItemNameWrapper>

                  <StyledSwitch checked={isSubscribed} onClick={handleChange} />
                </PreferenceMenuItem>
              )}

              {showNotifications && <StyledDivider />}
            </MenuList>
          </HomePagePadding>
        </PageTransition>
      )}
    </AppAccess>
  );
};

export default Settings;
