import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import { styled } from "@mui/system";
import { ItemName } from "../ListInlineView.styles";
import { THEME } from "../../../../util/utils";

export const ListTable = styled(Table)({
  width: "100%",
  maxWidth: "650px",
});

export const EmptyCell = styled(TableCell)({
  width: "48px",
});

export const SecondaryContrastColorCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  paddingLeft: "10px",
}));

export const Row = styled(TableRow)({
  cursor: "pointer",
});

export const CategoryText = styled(ItemName)({
  marginLeft: 0,
});

export const FlexContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
});

export const AssetListTableFlexContainer = styled(FlexContainer)({
  alignItems: "center",
  columnGap: "5px",
});

export const FlexBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  position: "relative",
});

export const NameTableCell = styled(TableCell)({
  width: "150px",
});

export const CountTableCell = styled(TableCell)({
  width: "90px",
});

export const CategoryTableCell = styled(TableCell)({
  width: "150px",
});

export const TableCellText = styled(Typography)(({ theme, isSelected }) => ({
  color: theme.palette.primary.main,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  width: "inherit",
  fontWeight: isSelected ? "bold" : "normal",
}));

export const ChildrenCountCellText = styled(TableCellText)({
  marginBottom: "2px",
});

const getTableRowBackgroundColor = ({ longPressed, isSelected, theme }) => {
  let backgroundColor = "transparent";
  if (longPressed) {
    backgroundColor = theme.palette.secondary.active;
  }

  if (isSelected) {
    backgroundColor = alpha(theme.palette.secondary.contrastText, 0.1);
  }

  return backgroundColor;
};

const getHoveredTableRowBackgroundColor = ({ isSelected, theme }) => {
  let backgroundColor = theme.palette.secondary.active;
  if (isSelected) {
    backgroundColor = alpha(theme.palette.secondary.contrastText, 0.2);
  }

  return backgroundColor;
};

export const SelectedTableRow = styled(Row)(
  ({ isselected, theme, longPressed }) => ({
    backgroundColor: getTableRowBackgroundColor({
      longPressed,
      isSelected: isselected,
      theme,
    }),
    "&:hover": {
      backgroundColor: getHoveredTableRowBackgroundColor({
        isSelected: isselected,
        theme,
      }),
    },
    zIndex: longPressed ? 1200 : "auto",
    position: longPressed ? "relative" : "static",
  })
);

export const TableIconImg = styled("img")({
  objectFit: "contain",
});
