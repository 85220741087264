import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  useTheme,
  Typography,
  FormGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../../../util/utils";
import { useSelector } from "react-redux";
import {
  IMPORT_OPTIONS,
  selectImportIncludes,
  selectImportOption,
  setImportIncludes,
  setImportOption,
} from "../../../../../store/slices/importExportSlice";
import { ExportIncludesSwitch } from "../../../../styles/assets/asset-list/AssetImportExport.styles";

const AssetImportWizardSecondStep = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const importOption = useSelector(selectImportOption);
  const importIncludes = useSelector(selectImportIncludes);

  // Handlers
  const handleChangeImportOption = (e) => {
    dispatch(setImportOption(e.target.value));
  };

  const handleChangeImportIncludesImages = (event) => {
    dispatch(
      setImportIncludes({ ...importIncludes, images: event.target.checked })
    );
  };

  const handleChangeImportIncludesAttachments = (event) => {
    dispatch(
      setImportIncludes({
        ...importIncludes,
        attachments: event.target.checked,
      })
    );
  };

  const handleChangeImportIncludesTags = (event) => {
    dispatch(
      setImportIncludes({ ...importIncludes, tags: event.target.checked })
    );
  };

  const handleChangeImportIncludesGraphicalObjects = (event) => {
    dispatch(
      setImportIncludes({
        ...importIncludes,
        graphicalObjects: event.target.checked,
      })
    );
  };

  return (
    <Box sx={{ marginTop: "40px" }}>
      <FormLabel
        sx={{ color: theme.palette.text.secondary }}
        id="demo-controlled-radio-buttons-group"
      >
        {getTranslation("IMPORT_SELECT_FILE_TYPE", t, i18n)}
      </FormLabel>

      <FormControl>
        <RadioGroup
          sx={{ alignItems: "start", marginTop: "20px" }}
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={importOption}
          onChange={handleChangeImportOption}
        >
          <FormControlLabel
            value={IMPORT_OPTIONS.XLSX}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("XLSX_EXPORT_OPTION", t, i18n)}
          />

          <Typography sx={{ color: theme.palette.text.secondary }}>
            {getTranslation("XLSX_EXPORT_OPTION_CAPTION", t, i18n)}
          </Typography>

          <FormControlLabel
            value={IMPORT_OPTIONS.ZIP}
            control={
              <Radio
                sx={{
                  color: theme.palette.secondary.contrastText,
                  "&.Mui-checked": {
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              />
            }
            label={getTranslation("ZIP_EXPORT_OPTION", t, i18n)}
          />

          <Typography sx={{ color: theme.palette.text.secondary }}>
            {getTranslation("ZIP_EXPORT_OPTION_CAPTION", t, i18n)}
          </Typography>
        </RadioGroup>

        {importOption === IMPORT_OPTIONS.ZIP && (
          <>
            <Typography
              sx={{ marginTop: "20px", color: theme.palette.text.secondary }}
            >
              {getTranslation("ZIP_EXPORT_ADDITIONAL_CONFIG", t, i18n)}
            </Typography>

            <FormGroup sx={{ marginTop: "20px", alignItems: "start" }}>
              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={importIncludes["images"]}
                    onChange={handleChangeImportIncludesImages}
                  />
                }
                label={getTranslation("IMAGES", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={importIncludes["attachments"]}
                    onChange={handleChangeImportIncludesAttachments}
                  />
                }
                label={getTranslation("ATTACHMENTS", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={importIncludes["tags"]}
                    onChange={handleChangeImportIncludesTags}
                  />
                }
                label={getTranslation("TAGS", t, i18n)}
              />

              <FormControlLabel
                control={
                  <ExportIncludesSwitch
                    checked={importIncludes["graphicalObjects"]}
                    onChange={handleChangeImportIncludesGraphicalObjects}
                  />
                }
                label={getTranslation("GRAPHICAL_OBJECTS", t, i18n)}
              />
            </FormGroup>
          </>
        )}
      </FormControl>
    </Box>
  );
};

export default AssetImportWizardSecondStep;
