import React, { useEffect, useMemo } from "react";
import { Group, Rect, Text } from "react-konva";
import {
  useGetAllCharacteristicsQuery,
  useGetCharacteristicSetTemplatesQuery,
} from "../../../store/slices/api/assetManagementSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import {
  delimiterLocalize,
  getTranslation,
  measurementDisplay,
} from "../../../util/utils";
import {
  groupCharacteristics,
  mergeCharacteristics,
} from "../../../util/asset-utils";
import { useTranslation } from "react-i18next";
import {
  selectCoordinates,
  selectResource,
  selectVisible,
} from "../../../store/slices/tooltipSlice";
import { useTheme } from "@mui/material";

export const TOOLTIP_WIDTH = 150;
const NAMEPLATE_CHARACTERISTICS = {
  WIDTH_ID: 10,
  HEIGHT_ID: 20,
  WEIGHT_ID: 40,
  SERIAL_NUMBER_ID: 50,
  POWER_CONSUMPTION_ID: 70,
};

const Tooltip = ({ rackHeight }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const region = user?.region;
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const coordinates = useSelector(selectCoordinates);
  const visible = useSelector(selectVisible);
  const resource = useSelector(selectResource);
  const { x, y } = coordinates;
  const {
    name,
    functionId,
    type,
    characteristics: resourceCharacteristics,
  } = resource;

  // Queries
  const { data: characteristicDefinitionsData } = useGetAllCharacteristicsQuery(
    { organizationId }
  );

  const { data: characteristicSetTemplatesData } =
    useGetCharacteristicSetTemplatesQuery(
      {
        resourceFunctionId: functionId,
        organizationId,
      },
      { skip: !Boolean(functionId) }
    );

  // Other variables
  const characteristics = useMemo(
    () =>
      mergeCharacteristics(
        resourceCharacteristics,
        type.characteristics,
        characteristicDefinitionsData
      ).filter((c) =>
        Object.values(NAMEPLATE_CHARACTERISTICS).some((id) => id === c.id)
      ),
    [
      resourceCharacteristics,
      type.characteristics,
      characteristicDefinitionsData,
    ]
  );

  const { setTemplates, other } = useMemo(
    () => groupCharacteristics(characteristics, characteristicSetTemplatesData),
    [characteristics, characteristicSetTemplatesData]
  );

  const orderedCharacteristics = useMemo(() => {
    const setTemplateCharacteristics = setTemplates
      .sort((a, b) => {
        if (a.orderPriority > b.orderPriority) {
          return 1;
        } else {
          return -1;
        }
      })
      .reduce((acc, setTemplate) => {
        return [...acc, ...setTemplate.characteristics];
      }, []);
    return [...setTemplateCharacteristics, ...other];
  }, [setTemplates, other]);

  const mappedCharacteristics = useMemo(
    () =>
      orderedCharacteristics.map((characteristic, index) => {
        const { value, measurementUnit, name, dataType } = characteristic;
        const { value: characteristicValue, unit } = measurementDisplay({
          value,
          unit: measurementUnit,
          region: user?.region,
          reverse: false,
        });
        const y = (index + 2) * 20;

        return {
          text: `${getTranslation(name, t, i18n)}: ${
            !isNaN(characteristicValue) && dataType !== "STRING"
              ? Number(characteristicValue).toLocaleString(
                  delimiterLocalize(region)
                )
              : value
          } ${unit}`,
          y,
        };
      }),
    [orderedCharacteristics]
  );

  const textProps = [
    {
      text: `${getTranslation("Name", t, i18n)} : ${name}`,
      y: 0,
    },
    { text: `Type: ${type.name}`, y: 20 },
    ...mappedCharacteristics,
  ];

  const TOOLTIP_HEIGHT = 20 + textProps[textProps.length - 1].y;

  return (
    <Group
      x={x}
      y={Math.min(y, rackHeight - TOOLTIP_HEIGHT - 50)}
      visible={visible}
    >
      <Rect
        width={TOOLTIP_WIDTH}
        height={TOOLTIP_HEIGHT}
        fill={theme.palette.primary.dark}
        cornerRadius={5}
      />
      {textProps.map((props, index) => (
        <Text
          fontSize={10}
          width={TOOLTIP_WIDTH}
          key={index}
          {...props}
          padding={5}
          fill={theme.palette.primary.main}
          wrap="none"
          ellipsis={true}
        />
      ))}
    </Group>
  );
};

export default Tooltip;
