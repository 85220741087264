import { Box, DialogContent, Grid, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { ProfileAvatar } from "../profile/ProfileCardItm.styles";
import { SecondaryText } from "./asset-detail/AssetDetailBasicInfo.styles";

export const StorageIconAvatarContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: "30px",
});

export const StorageIconAvatar = styled(ProfileAvatar)(({ theme }) => ({
  minWidth: "150px",
  height: "75px",
  backgroundColor: theme.palette.secondary.active,
}));

export const ImageActionsContainer = styled(Grid)({
  marginBottom: "30px",
});

export const ImageActionsItem = styled(Box)({
  display: "flex",
  justifyContent: "center",
});

export const UploadButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "15px",
  fontWeight: "bold",
}));

export const SecondaryTextSpace = styled(SecondaryText)({
  marginBottom: "5px",
});

export const IconsGridItem = styled(Grid)({
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
});

export const StaticHeightDialogContent = styled(DialogContent)({
  height: "350px",
});

export const PreviewImage = styled(`img`)({
  height: "100px",
  width: "100%",
  objectFit: "contain",
});
