import { Box, Menu, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { SecondaryText } from "../../../assets/ListInlineView.styles";
import { PrimaryText } from "../../../assets/asset-detail/AssetDetailBasicInfo.styles";

export const HeaderContainer = styled(Box)(({ fitscreen }) => ({
  display: "flex",
  borderBottom: "2px solid #e0e0e0",
  position: fitscreen ? "relative" : "static",
  zIndex: fitscreen ? 100 : "",
}));

export const NavigationOptionsContainer = styled(Box)({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
});

export const SortingMenuOptions = styled(Menu)({
  marginTop: "24px",
  ".MuiMenu-paper": { borderRadius: "15px", outline: "none" },
  ".MuiMenu-list": {
    width: "240px",
    borderRadius: "15px",
  },
});
export const SearchTextField = styled(TextField)({
  marginBlock: "auto",
  " .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
});

export const PageTitle = styled(PrimaryText)({
  fontWeight: "bold",
  marginLeft: "8px",
});
