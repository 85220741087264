import { Grid, IconButton, MenuItem, Select, useTheme } from "@mui/material";
import { getTranslation } from "../../../util/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  editWidgetAggregation,
  removeWidgetAggregation,
  selectReportDefinitionAggregations,
  selectResultFields,
  selectWidgetById,
} from "../../../store/slices/reportsSlice";
import { Draggable } from "react-beautiful-dnd";
import {
  constructAggregations,
  findColorCode,
  findColorName,
  getDefaultPaletteColor,
  getPaletteColorValues,
  getPaletteValues,
  mapIdToPalette,
  mapPaletteToId,
  WIDGET_TYPE,
} from "../../../util/reports-utils";
import {
  DeleteFieldButtonWrapper,
  DragIndicatorIconWrapper,
  FieldsStack,
  ReportsSecondaryText,
  ResultFieldsListItemGridContainer,
} from "../../styles/reports/Reports.styles";
import { useState } from "react";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";

const WidgetAggregationsListItem = ({ aggregation, index, widgetTypeId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const resultFields = useSelector(selectResultFields);
  const widget = useSelector((state) => selectWidgetById(state, widgetTypeId));
  const reportDefinitionAggregations = useSelector(
    selectReportDefinitionAggregations
  );

  // Other variables
  const finalAggregations = constructAggregations(resultFields);

  const shouldShowPaletteSelect =
    widgetTypeId !== WIDGET_TYPE.SINGLE_VALUE &&
    widgetTypeId !== WIDGET_TYPE.DOUBLE_VALUE &&
    widgetTypeId !== WIDGET_TYPE.MIN_MAX_AVERAGE &&
    widgetTypeId !== WIDGET_TYPE.STATUS;

  const shouldShowColorSelect =
    widgetTypeId === WIDGET_TYPE.BAR_CHART ||
    widgetTypeId === WIDGET_TYPE.AREA_CHART ||
    widgetTypeId === WIDGET_TYPE.LINE_CHART;

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const handleEditWidgetAggregation = (e) => {
    const generatedAggregationId = e.target.value;

    const newAggregation = finalAggregations?.find(
      (a) => a.id === generatedAggregationId
    );

    const reportDefinitionAggregation = reportDefinitionAggregations?.find(
      (a) =>
        a?.field?.name === newAggregation?.field?.name &&
        a?.aggregationType === newAggregation?.aggregationType
    );

    dispatch(
      editWidgetAggregation({
        aggregation: {
          ...aggregation,
          generatedAggregationId,
          reportDefinitionAggregationId: reportDefinitionAggregation?.id,
        },
        uniqueId: aggregation?.uniqueId,
        widgetTypeId,
      })
    );
  };

  const handleEditWidgetAggregationPalette = (e) => {
    const paletteId = e.target.value;

    const color = getDefaultPaletteColor(
      mapIdToPalette(paletteId),
      theme.palette.mode
    );

    dispatch(
      editWidgetAggregation({
        aggregation: { ...aggregation, paletteId, color },
        uniqueId: aggregation?.uniqueId,
        widgetTypeId,
      })
    );
  };

  const handleEditWidgetAggregationColor = (e) => {
    const color = e.target.value;

    dispatch(
      editWidgetAggregation({
        aggregation: { ...aggregation, color: findColorCode(color) },
        uniqueId: aggregation?.uniqueId,
        widgetTypeId,
      })
    );
  };

  const handleRemoveWidgetAggregation = () => {
    dispatch(
      removeWidgetAggregation({ uniqueId: aggregation.uniqueId, widgetTypeId })
    );
  };

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleConfirm = () => {
    handleRemoveWidgetAggregation();
    setOpenConfirm(false);
  };

  const handleGetGridColumns = () => {
    if (shouldShowPaletteSelect) {
      if (shouldShowColorSelect) {
        return 6;
      } else {
        return 9;
      }
    } else if (shouldShowColorSelect) {
      if (shouldShowPaletteSelect) {
        return 6;
      } else {
        return 9;
      }
    } else {
      return 12;
    }
  };

  // Other variables
  const alert = {
    content: getTranslation("REMOVE_WIDGET_AGGREGATION_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation(
      "REMOVE_WIDGET_AGGREGATION_ALERT_TITLE",
      t,
      i18n
    ),
    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const iconSize = globalFontSize * 1.2;
  const gridColumns = handleGetGridColumns();

  return (
    <>
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={alert}
        label="delete-widget-aggregation"
        handleConfirm={handleConfirm}
      />

      <Draggable
        key={aggregation.uniqueId}
        draggableId={aggregation.uniqueId}
        index={index}
      >
        {(provided) => (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <ResultFieldsListItemGridContainer
              alignItems="center"
              container
              columns={22}
              spacing={2}
            >
              <Grid item xs={1}>
                <DragIndicatorIconWrapper>
                  {getSvgIcon(
                    "DRAG_POINT",
                    iconSize,
                    iconSize,
                    theme.palette.primary.main
                  )}
                </DragIndicatorIconWrapper>
              </Grid>

              <Grid item xs={gridColumns}>
                <FieldsStack rowGap={2}>
                  <ReportsSecondaryText variant="caption">
                    {getTranslation("AGGREGATION", t, i18n)}
                  </ReportsSecondaryText>

                  <Select
                    fullWidth
                    labelId="aggregation-select-label"
                    id="aggregation-select"
                    label={getTranslation("AGGREGATION_SELECT_LABEL", t, i18n)}
                    variant="standard"
                    value={aggregation?.generatedAggregationId}
                    onChange={handleEditWidgetAggregation}
                  >
                    {finalAggregations?.map((a) => (
                      <MenuItem
                        key={a?.id}
                        value={a?.id}
                        disabled={widget?.dataSources?.some(
                          (ds) => ds.generatedAggregationId === a?.id
                        )}
                      >
                        {getTranslation(a?.field?.name, t, i18n)} -{" "}
                        {getTranslation(a?.aggregationType, t, i18n)}
                      </MenuItem>
                    ))}
                  </Select>
                </FieldsStack>
              </Grid>

              {shouldShowPaletteSelect && (
                <Grid item xs={gridColumns}>
                  <FieldsStack rowGap={2}>
                    <ReportsSecondaryText variant="caption">
                      {getTranslation("PALETTE", t, i18n)}
                    </ReportsSecondaryText>

                    <Select
                      fullWidth
                      labelId="palette-select-label"
                      id="palette-select"
                      label={getTranslation("PALETTE_SELECT_LABEL", t, i18n)}
                      variant="standard"
                      value={aggregation?.paletteId}
                      onChange={handleEditWidgetAggregationPalette}
                    >
                      {getPaletteValues()?.map((palette) => (
                        <MenuItem key={palette} value={mapPaletteToId(palette)}>
                          {palette[0]?.toUpperCase() + palette?.substring(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FieldsStack>
                </Grid>
              )}

              {shouldShowColorSelect && (
                <Grid item xs={gridColumns}>
                  <FieldsStack rowGap={2}>
                    <ReportsSecondaryText variant="caption">
                      {getTranslation("COLOR", t, i18n)}
                    </ReportsSecondaryText>

                    <Select
                      fullWidth
                      labelId="color-select-label"
                      id="color-select"
                      label={getTranslation("COLOR_SELECT_LABEL", t, i18n)}
                      variant="standard"
                      value={findColorName(aggregation?.color)}
                      onChange={handleEditWidgetAggregationColor}
                    >
                      {getPaletteColorValues(
                        mapIdToPalette(aggregation?.paletteId),
                        theme.palette.mode
                      )?.map((color) => (
                        <MenuItem key={color} value={color}>
                          {getTranslation(
                            `colors.${mapIdToPalette(
                              aggregation?.paletteId
                            )}.${color}`,
                            t,
                            i18n
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </FieldsStack>
                </Grid>
              )}

              <Grid item>
                <DeleteFieldButtonWrapper>
                  <IconButton
                    id="delete-result-field"
                    onClick={handleOpenConfirm}
                  >
                    {getSvgIcon(
                      "DELETE",
                      iconSize,
                      iconSize,
                      theme.palette.secondary.contrastText
                    )}
                  </IconButton>
                </DeleteFieldButtonWrapper>
              </Grid>
            </ResultFieldsListItemGridContainer>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default WidgetAggregationsListItem;
