import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { DialogPaperProps } from "../../styles/general/General.styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ConfirmAlert from "../../../store/confirm/ConfirmAlert";
import {
  CancelButton,
  CreateButton,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { getTranslation } from "../../../util/utils";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import {
  WIDGET_CATEGORY,
  WIDGET_TYPE,
  constructAggregations,
  constructQuery,
  getMappedWidgets,
} from "../../../util/reports-utils";
import {
  useGetReportDefinitionAggregationsQuery,
  useGetReportDefinitionFieldsQuery,
  useGetReportDefinitionFiltersQuery,
  useReportingFieldsByTypeQuery,
  useUpdateReportDefinitionWidgetsWithIdsMutation,
} from "../../../store/slices/api/reportsApiSlice";
import { messageError, messageSuccess } from "../../../util/notification";
import { selectUser } from "../../../store/slices/authSlice";
import WidgetForm from "../create-report/WidgetForm";
import {
  resetWizard,
  selectResultFields,
  selectWidgets,
  setQuery,
  setReportDefinitionAggregations,
  setResultFields,
  setWidgets,
} from "../../../store/slices/reportsSlice";
import ErrorHandling from "../../common/ErrorHandling";

const UpdateWidgetDialog = ({
  open,
  handleClose,
  widget,
  reportId,
  reportTypeId,
}) => {
  const { id: widgetId, widgetTypeId } = widget;

  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resultFields = useSelector(selectResultFields);
  const widgets = useSelector(selectWidgets);

  // States
  const [openDiscard, setOpenDiscard] = useState(false);

  // Mutations
  const [updateReportDefinitionWidgets] =
    useUpdateReportDefinitionWidgetsWithIdsMutation();

  // Queries
  const { data: reportingFieldsData, isLoading: isLoadingReportingFields } =
    useReportingFieldsByTypeQuery(
      {
        organizationId,
        typeId: reportTypeId,
      },
      {
        skip: !reportTypeId,
      }
    );

  const {
    data: reportDefinitionAggregationsData,
    isLoading: isLoadingReportDefinitionAggregations,
  } = useGetReportDefinitionAggregationsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    {
      skip: !reportId,
    }
  );

  const {
    data: reportDefinitionFieldsData,
    isLoading: isLoadingReportDefinitionFields,
  } = useGetReportDefinitionFieldsQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    {
      skip: !reportId,
    }
  );

  const {
    data: reportDefinitionFilterData,
    isLoading: isLoadingReportDefinitionFilter,
  } = useGetReportDefinitionFiltersQuery(
    {
      organizationId,
      reportDefinitionId: reportId,
    },
    {
      skip: !reportId,
    }
  );

  // Handlers
  const checkHasChanges = () => {
    if (widgets.length === 0) return false;
    const titleChanged = widgets[0].title !== widget.title;

    const localDataSources = widgets[0].dataSources;
    const originalDataSources = widget.dataSources;

    const dataSourcesChanged = localDataSources.some((localDs, index) => {
      const originalDataSource = originalDataSources.find(
        (originalDs) => index === originalDs.index
      );

      const reportDefinitionChanged =
        localDs.reportDefinitionAggregationId !==
        originalDataSource?.reportDefinitionAggregationId;
      const paletteChange = localDs.paletteId !== originalDataSource?.paletteId;
      let colorChange = false;
      if (
        widgetTypeId !== WIDGET_TYPE.PIE_CHART &&
        widgetTypeId !== WIDGET_TYPE.DONUT_CHART
      ) {
        colorChange = localDs.color !== originalDataSource?.color;
      }

      return reportDefinitionChanged || paletteChange || colorChange;
    });

    const aggregationsChanged =
      widgets[0].dataSources.length !== widget.dataSources.length ||
      dataSourcesChanged;

    return titleChanged || aggregationsChanged;
  };

  const handleCancelUpdate = () => {
    if (checkHasChanges()) {
      setOpenDiscard(true);
    } else {
      handleClose();
    }
  };

  const handleSubmit = async () => {
    try {
      handleClose();
      const widgetsPayload = widgets?.map((widget) => {
        const { title, widgetTypeId, dataSources } = widget;

        return {
          id: widgetId,
          title,
          widgetTypeId,
          dataSources: dataSources?.map((ds, index) => {
            const { reportDefinitionAggregationId, color, paletteId } = ds;

            return {
              reportDefinitionAggregationId,
              index,
              color,
              paletteId,
            };
          }),
        };
      });

      await updateReportDefinitionWidgets({
        organizationId,
        widgetPayload: widgetsPayload,
      }).unwrap();

      dispatch(resetWizard());
      messageSuccess("Widget updated");
    } catch (error) {
      console.log(error);
      messageError("Failed to update widget");
    }
  };

  // Other variables
  const alert = {
    content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
    confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),

    closeTitle: getTranslation("CANCEL", t, i18n),
    showConfirm: true,
  };

  const isValid = widgets.length > 0 ? widgets[0].title : false;
  const hasChanges = checkHasChanges();

  const isSubmitDisabled = !isValid || !hasChanges;

  useEffect(() => {
    if (reportDefinitionFilterData) {
      const constructedQuery = constructQuery(reportDefinitionFilterData[0]);
      dispatch(setQuery(constructedQuery));
    }
  }, [reportDefinitionFilterData]);

  useEffect(() => {
    if (
      reportDefinitionFieldsData &&
      reportDefinitionAggregationsData &&
      reportingFieldsData
    ) {
      const formattedResultFields = reportDefinitionFieldsData
        ?.slice()
        ?.sort((a, b) => a.index - b.index)
        .map((reportDefinitionField) => {
          const {
            field: { id: reportDefinitionFieldId },
            displayName,
            usedForGrouping,
            orderIndex,
            sortMethod,
          } = reportDefinitionField;
          const reportingField = reportingFieldsData.find(
            (f) => f.id === reportDefinitionFieldId
          );

          const aggregationTypes = reportDefinitionAggregationsData
            .filter((aggregation) => aggregation.field.id === reportingField.id)
            .map((a) => a.aggregationType);

          return {
            uniqueId: uuidv4(),
            displayName: getTranslation(
              displayName ?? reportingField.name,
              t,
              i18n
            ),
            field: reportingField.name,
            orderIndex,
            sortMethod,
            usedForGrouping: usedForGrouping,
            aggregationTypes,
          };
        });

      dispatch(setResultFields(formattedResultFields));
    }
  }, [reportDefinitionFieldsData, reportDefinitionAggregationsData]);

  useEffect(() => {
    let constructedAggregations = [];
    if (resultFields) {
      constructedAggregations = constructAggregations(resultFields);
    }

    if (
      reportDefinitionAggregationsData &&
      constructedAggregations.length > 0
    ) {
      const mappedWidgets = getMappedWidgets(
        [widget],
        reportDefinitionAggregationsData,
        constructedAggregations
      );
      dispatch(setWidgets(mappedWidgets));
    }
  }, [reportDefinitionAggregationsData, resultFields]);

  useEffect(() => {
    if (reportDefinitionAggregationsData) {
      dispatch(
        setReportDefinitionAggregations(reportDefinitionAggregationsData)
      );
    }
  }, [reportDefinitionAggregationsData]);

  useEffect(() => {
    return () => {
      dispatch(resetWizard());
    };
  }, []);

  if (widgets.length === 0) {
    return <></>;
  }

  return (
    <ErrorHandling
      isLoading={
        isLoadingReportDefinitionAggregations ||
        isLoadingReportDefinitionFields ||
        isLoadingReportingFields ||
        isLoadingReportDefinitionFilter
      }
    >
      <Dialog
        fullWidth={true}
        maxWidth="md"
        PaperProps={DialogPaperProps}
        open={open}
        onClose={handleCancelUpdate}
      >
        {tabletMatches && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Button
                sx={{ color: theme.palette.secondary.contrastText }}
                variant="text"
                onClick={handleCancelUpdate}
              >
                {getTranslation("CANCEL", t, i18n)}
              </Button>
            </Box>

            <Box>
              <DialogTitle>
                {getTranslation("Update Widget", t, i18n)}
              </DialogTitle>
            </Box>

            <Box>
              <Button
                sx={{ color: theme.palette.secondary.contrastText }}
                variant="text"
                disabled={isSubmitDisabled}
                onClick={handleSubmit}
              >
                {getTranslation("DONE", t, i18n)}
              </Button>
            </Box>
          </Box>
        )}

        {desktopMatches && (
          <DialogTitle>{getTranslation("Update Widget", t, i18n)}</DialogTitle>
        )}

        <DialogContent>
          <ConfirmAlert
            isOpen={openDiscard}
            setIsOpen={setOpenDiscard}
            alert={alert}
            label="discard-changes"
            handleConfirm={handleClose}
          />

          {WIDGET_TYPE.AREA_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.AREA_CHART} />
          )}
          {WIDGET_TYPE.BAR_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.BAR_CHART} />
          )}
          {WIDGET_TYPE.DONUT_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.DONUT_CHART} />
          )}
          {WIDGET_TYPE.FUNNEL_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.FUNNEL_CHART} />
          )}
          {WIDGET_TYPE.LINE_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.LINE_CHART} />
          )}
          {WIDGET_TYPE.PIE_CHART === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.PIE_CHART} />
          )}
          {WIDGET_TYPE.MIN_MAX_AVERAGE === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.MIN_MAX_AVERAGE} />
          )}
          {WIDGET_TYPE.STATUS === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.STATUS} />
          )}
          {/* {WIDGET_TYPE.PERCENTAGE === widgetTypeId && (
          <UpdatePercentageWidgetForm />
        )} */}
          {WIDGET_TYPE.SINGLE_VALUE === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.SINGLE_VALUE} />
          )}
          {WIDGET_TYPE.DOUBLE_VALUE === widgetTypeId && (
            <WidgetForm category={WIDGET_CATEGORY.DOUBLE_VALUE} />
          )}
        </DialogContent>

        {desktopMatches && (
          <DialogActions>
            <CancelButton variant="outlined" onClick={handleCancelUpdate}>
              {getTranslation("CANCEL", t, i18n)}
            </CancelButton>

            <CreateButton disabled={isSubmitDisabled} onClick={handleSubmit}>
              {getTranslation("DONE", t, i18n)}
            </CreateButton>
          </DialogActions>
        )}
      </Dialog>
    </ErrorHandling>
  );
};

export default UpdateWidgetDialog;
