import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBox = styled(Box)({
  position: "sticky !important",
  zIndex: "100 !important",
  padding: "8px",
  bottom: 0,
  left: 0,
  right: 0,
});

export const ButtonContainer = styled(Box)(({ view }) => ({
  display: "flex",
  justifyContent: view === "column" ? "flex-start" : "center",
}));
