import { Box, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAggregationToWidget,
  selectWidgetById,
} from "../../../store/slices/reportsSlice";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../util/utils";
import {
  AddFieldButton,
  FieldsHeaderTypography,
} from "../../styles/reports/Reports.styles";
import { getSvgIcon } from "../../../util/icons";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import WidgetAggregationsListItem from "./WidgetAggregationsListItem";
import { WIDGET_TYPE } from "../../../util/reports-utils";

const WidgetAggregationsList = ({ widgetTypeId }) => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const widget = useSelector((state) => selectWidgetById(state, widgetTypeId));

  // Other variables
  const iconSize = globalFontSize * 1.2;
  const aggregations = widget?.dataSources;

  // Handlers
  const handleAddAggregationToWidget = () => {
    dispatch(addAggregationToWidget(widgetTypeId));
  };

  const handleCreateAggregationWidgetButtonActive = () => {
    if (
      widgetTypeId === WIDGET_TYPE.SINGLE_VALUE ||
      widgetTypeId === WIDGET_TYPE.FUNNEL_CHART
    ) {
      return aggregations?.length < 1;
    } else if (widgetTypeId === WIDGET_TYPE.DOUBLE_VALUE) {
      return aggregations?.length < 2;
    } else if (
      widgetTypeId === WIDGET_TYPE.MIN_MAX_AVERAGE ||
      widgetTypeId === WIDGET_TYPE.STATUS
    ) {
      return aggregations?.length < 3;
    }

    return true;
  };

  // Other variables
  const isDisabled = !handleCreateAggregationWidgetButtonActive();

  return (
    <>
      <Droppable droppableId="widget-aggregations">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Box>
              <FieldsHeaderTypography>
                {getTranslation("AGGREGATIONS", t, i18n)}
              </FieldsHeaderTypography>

              {aggregations?.map((aggregation, index) => (
                <WidgetAggregationsListItem
                  key={aggregation?.uniqueId}
                  aggregation={aggregation}
                  index={index}
                  widgetTypeId={widgetTypeId}
                />
              ))}
            </Box>

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AddFieldButton
        id="add-widget-aggregation"
        disabled={isDisabled}
        onClick={handleAddAggregationToWidget}
        variant="text"
      >
        <div style={{ marginTop: "5px", marginRight: "5px" }}>
          {getSvgIcon(
            "CREATE_NEW",
            iconSize,
            iconSize,
            isDisabled
              ? theme.palette.text.disabled
              : theme.palette.secondary.contrastText
          )}
        </div>

        {getTranslation("ADD_AGGREGATION", t, i18n)}
      </AddFieldButton>
    </>
  );
};

export default WidgetAggregationsList;
