import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { SecondaryText } from "../../assets/ListInlineView.styles";

export const DarkGreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
}));

export const FlexSpacedContainer = styled(Box)({
  display: "flex",
  gap: 1,
});

export const TopFlexSpacedContainer = styled(FlexSpacedContainer)({
  // marginTop: "16px",
});

export const FlexUnShrinkableText = styled(DarkGreyText)({
  // flexShrink: 0,
  // flexGrow: 1,
  flex: "0 0 auto",
});

export const QuarterWidthNotesText = styled(FlexUnShrinkableText)({
  minWidth: "25%",
});

export const NotesText = styled(SecondaryText)({
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  lineClamp: 3,
  WebkitBoxOrient: "vertical",
});

export const HeaderContainer = styled(Grid)(({ theme }) =>
  theme.unstable_sx({
    borderBottom: 1,
    borderColor: "divider",
    paddingInline: 2,
    paddingBlock: 1,
    alignItems: "center",
  })
);

export const CreationTypeGridContainer = styled(Grid)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

export const FirstBox = styled(Box)({
  flex: "0 0 auto",
});

export const SecondBox = styled(Box)({
  flex: "1 1 auto",
  overflow: "auto",
  display: "flex",
  justifyContent: "end",
});
