import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import CanvasImage from "../../common/CanvasImage";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";
import { selectPageView } from "../../../store/slices/appSlice";

const GridViewCardMedia = ({
  functionName,
  size,
  thumbnailUri,
  showSkeletonIfLoading,
}) => {
  // General hooks
  const mobileMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.MOBILE);
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const currentPageView = useSelector(selectPageView);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default).id;
  const mobileSize = 100;
  const iconSize = size || 60;

  // Queries
  const {
    data: resourceImageUri,
    isLoading: isLoadingImage,
    isFetching: isFetchingImage,
    isError,
  } = useGetResourceImageQuery(
    {
      imageUri: thumbnailUri,
      organizationId,
      thumbnail: true,
    },
    {
      skip: !thumbnailUri,
    }
  );

  // Other variables
  const isLoading = currentPageView !== "column" ? isLoadingImage : false;

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {showSkeletonIfLoading && (isLoadingImage || isFetchingImage) ? (
        <Skeleton variant="rectangular" width={iconSize} height={iconSize} />
      ) : (
        <>
          {" "}
          {resourceImageUri && thumbnailUri ? (
            <CanvasImage
              id={thumbnailUri}
              width={mobileMatches ? mobileSize : iconSize}
              height={mobileMatches ? mobileSize : iconSize}
              image={resourceImageUri}
            />
          ) : (
            getSvgIcon(
              functionName?.toUpperCase(),
              iconSize,
              iconSize,
              theme.palette.secondary.dark
            )
          )}
        </>
      )}
    </ErrorHandling>
  );
};

export default GridViewCardMedia;
