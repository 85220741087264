import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectExecutionGroupResultFields } from "../../../store/slices/reportsSlice";
import { useMemo } from "react";
import {
  constructMRTAggregatedCellContent,
  DEFAULT_REPORT_RESULTS_PAGE_SIZE,
  mapCreatedByAttribute,
  REPORT_RESULTS_PAGE_OPTIONS,
} from "../../../util/reports-utils";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { FormatResultPreviewTableWrapper } from "../../styles/reports/Reports.styles";
import { THEME } from "../../../util/utils";
import { selectTheme } from "../../../store/slices/appSlice";
import { useTheme } from "@mui/material";

const ResultFieldDialogPreviewTable = ({ rows, shouldExecute, field }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  // Selectors
  const executionGroupFields = useSelector(selectExecutionGroupResultFields);
  const currentTheme = useSelector(selectTheme);

  // Other variables
  const {
    field: accessorKey,
    displayName: header,
    aggregationTypes,
    usedForGrouping,
  } = field;

  const finalExecutionGroupFields = useMemo(
    () =>
      usedForGrouping
        ? [
            ...executionGroupFields.filter(
              (field) => field.field !== accessorKey
            ),
            field,
          ]
        : [...executionGroupFields],
    [executionGroupFields, field, usedForGrouping, accessorKey]
  );

  const tableColumn =
    aggregationTypes.length > 0
      ? {
          accessorFn: (row) => row[accessorKey],
          id: accessorKey,
          header,
          AggregatedCell: ({ row }) => (
            <>
              {constructMRTAggregatedCellContent(
                accessorKey,
                aggregationTypes,
                t,
                i18n,
                row
              )}
            </>
          ),
        }
      : {
          accessorFn: (row) => row[accessorKey],
          id: accessorKey,
          header,
        };

  const columns = useMemo(
    () =>
      finalExecutionGroupFields.length > 0
        ? usedForGrouping
          ? [
              {
                accessorFn: (row) => {
                  const groupKey = finalExecutionGroupFields
                    .map((field) => field.field)
                    .join(":");

                  return row[groupKey];
                },
                id: finalExecutionGroupFields
                  .map((field) => field.field)
                  .join(":"),
                header: finalExecutionGroupFields
                  .map((field) => field.displayName)
                  .join(", "),
                usedForGrouping: true,
              },
            ]
          : [
              {
                accessorFn: (row) => {
                  const groupKey = finalExecutionGroupFields
                    .map((field) => field.field)
                    .join(":");

                  return row[groupKey];
                },
                id: finalExecutionGroupFields
                  .map((field) => field.field)
                  .join(":"),
                header: finalExecutionGroupFields
                  .map((field) => field.displayName)
                  .join(", "),
                usedForGrouping: true,
              },
              tableColumn,
            ]
        : [tableColumn],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [finalExecutionGroupFields]
  );

  const rowsData = useMemo(() => rows, [rows]);

  const data = useMemo(
    () => [
      ...rowsData.map((row) => {
        const keys = Object.keys(row);

        const groupKeys = finalExecutionGroupFields.map((field) => field.field);

        const joinedGroupKeys = groupKeys.join(":");
        let joinedGroupValue = "";

        for (let i = 0; i < groupKeys.length; i++) {
          const groupKey = groupKeys[i];

          joinedGroupValue += `${row[groupKey]} `;
        }

        let resultObject = { ...row };

        if (keys.some((key) => key === "subRows")) {
          const mappedSubRows = resultObject.subRows.map((subRow) => {
            const newSubRow = { ...subRow };
            const mappedSuRow = mapCreatedByAttribute(newSubRow);

            return mappedSuRow;
          });

          resultObject.subRows = mappedSubRows;
        } else {
          resultObject = mapCreatedByAttribute(resultObject);
        }

        if (Boolean(joinedGroupKeys)) {
          resultObject[joinedGroupKeys] = `${joinedGroupValue} ${
            keys?.some((key) => key === "subRows")
              ? `(${row.subRows?.length})`
              : ""
          }`;
        }

        return resultObject;
      }),
    ],
    [finalExecutionGroupFields, rowsData]
  );

  const disabledColor = theme.palette.action.disabled;

  const activeColor = theme.palette.primary.main;

  const previewTableColor = theme.palette.secondary.active;

  const color = !shouldExecute ? disabledColor : activeColor;

  // MRT initialization
  const table = useMaterialReactTable({
    columns,
    data,
    enableTopToolbar: false,
    enableExpandAll: false,
    enableExpanding: finalExecutionGroupFields?.length > 0,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableSorting: false,
    groupedColumnMode: false,
    initialState: {
      density: "compact",
      expanded: false,
      pagination: {
        pageSize: DEFAULT_REPORT_RESULTS_PAGE_SIZE,
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: "none",
        backgroundColor: previewTableColor,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color,
        backgroundColor: previewTableColor,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        color,
        backgroundColor: previewTableColor,
      },
    },
    muiBottomToolbarProps: {
      sx: {
        color,
        backgroundColor: previewTableColor,
      },
    },
    muiExpandButtonProps: {
      sx: {
        color,
      },
    },
    muiPaginationProps: {
      sx: {
        color,
      },
      rowsPerPageOptions: REPORT_RESULTS_PAGE_OPTIONS,
    },
    state: {
      columnOrder: columns.map((column) => column.accessorKey),
    },
  });

  return (
    <FormatResultPreviewTableWrapper>
      <MaterialReactTable table={table} />
    </FormatResultPreviewTableWrapper>
  );
};

export default ResultFieldDialogPreviewTable;
