import React from "react";
import { useGetUserDataStorageLocationQuery } from "../../store/slices/api/userManagementSlice";
import ErrorHandling from "../common/ErrorHandling";
import { Box } from "@mui/material";
import { SecondaryDarkText } from "../styles/general/General.styles";
import { SecondaryText } from "../styles/assets/ListInlineView.styles";
import { useTranslation } from "react-i18next";
import { getTranslation } from "../../util/utils";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";

const ProfileDataStorageLocation = () => {
  // General hooks
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const {
    data: orgDataStorageLocation,
    isLoading: isLoadingOrgDataStorageLocation,
    isError: isErrorSuccessDataStorageLocation,
  } = useGetUserDataStorageLocationQuery({ userId: user.id });

  return (
    <ErrorHandling
      isLoading={isLoadingOrgDataStorageLocation}
      isError={isErrorSuccessDataStorageLocation}
    >
      <Box>
        <SecondaryDarkText>
          {getTranslation("GEO_LOCATION_STORING", t, i18n)}
        </SecondaryDarkText>
        <SecondaryText>
          {orgDataStorageLocation
            ? i18n.exists(orgDataStorageLocation.displayId)
              ? t(orgDataStorageLocation.displayId)
              : orgDataStorageLocation.name
            : ""}
        </SecondaryText>
      </Box>
    </ErrorHandling>
  );
};

export default ProfileDataStorageLocation;
