import { useTheme, Grid } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../styles/assets/asset-detail/AssetDetailBasicInfo.styles";
import { getTranslation } from "../../../util/utils";
import CharacteristicInput from "./CharacteristicInput";
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/assets/asset-form/CreateAsset.styles";
import { useSelector } from "react-redux";
import {
  selectGlobalFontSize,
  selectTheme,
} from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const CharacteristicSetTemplateAccordion = ({ name, characteristics }) => {
  // General hooks
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const currentTheme = useSelector(selectTheme);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Refs
  const textRef = useRef(null);
  const iconRef = useRef(null);

  // State
  const [borderWidth, setBorderWidth] = useState("25%");

  // Other variables
  const leftPadding = 16;
  const iconSize = globalFontSize * 1.2;

  // Effects
  useEffect(() => {
    if (textRef.current && iconRef.current) {
      const textWidth = textRef.current.offsetWidth;
      const totalWidth = textRef.current.parentElement.offsetWidth;

      const percentage =
        ((textWidth + leftPadding + iconRef.current.offsetWidth) / totalWidth) *
        100;

      setBorderWidth(`${percentage}%`);
    }
  }, [name]);

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary
        value={borderWidth}
        expandIcon={
          <div style={{ marginTop: "5px", marginRight: "5px" }} ref={iconRef}>
            {getSvgIcon(
              "ARROW_RIGHT",
              iconSize,
              iconSize,
              theme.palette.secondary.contrastText
            )}
          </div>
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <SectionTitle variant="body2" istablet={1} ref={textRef}>
          {getTranslation(name, t, i18n)}
        </SectionTitle>
      </StyledAccordionSummary>

      <StyledAccordionDetails>
        <Grid
          container
          columnSpacing={2}
          id={`characteristic-input-group-${name
            ?.toLowerCase()
            .replaceAll(" ", "_")}`}
        >
          {characteristics
            ?.slice()
            ?.sort((a, b) => {
              if (a.id > b.id) {
                return 1;
              } else {
                return -1;
              }
            })
            ?.map((row) => (
              <Grid item xs={12} sm={6} key={row.id}>
                <CharacteristicInput characteristic={row} />
              </Grid>
            ))}
        </Grid>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
};

export default CharacteristicSetTemplateAccordion;
