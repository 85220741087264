import dayjs from "dayjs";
import convert from "convert-units";
import KeycloakService from "../services/KeycloakService";
import { DEFAULT_LANGUAGE } from "../store/slices/appSlice";
import Compressor from "compressorjs";
import { Line } from "react-konva";
import { fetchCurrentSubscription } from "../services/SubscriptionService";
import { messageError } from "./notification";
import { getResources } from "../services/ResourceService";

export const MAXIMUM_ROOT_SIZES = {
  MOBILE: 24,
  TABLET: 28,
  DESKTOP: 32,
};

export const BREAKPOINTS = {
  TABLET: 600,
  DESKTOP: 1024,
};

export const EXPANDED_DRAWER_WIDTH = 240;
export const COLLAPSED_DRAWER_WIDTH = 50;

export const DEFAULT_IMAGE_SIZE = 10 * 1024 * 1024;
export const DEFAULT_ATTACHMENT_SIZE = 25 * 1024 * 1024;

export const ALLOWED_EXTENSIONS = ["jpeg", "png", "bmp"];

export const ALLOWED_ATTACHMENTS_EXTENSIONS = [
  "7z",
  "aac",
  "amr",
  "avi",
  "bmp",
  "bpg",
  "blend",
  "cr2",
  "elf",
  "exr",
  "flac",
  "flv",
  "gif",
  "heic",
  "ico",
  "indd",
  "jpeg",
  "lzh",
  "m4a",
  "m4v",
  "mkv",
  "mov",
  "mp3",
  "mp4",
  "ogg",
  "pdf",
  "pbm",
  "pgm",
  "png",
  "ppm",
  "psd",
  "ps",
  "rar",
  "rtf",
  "sqlite",
  "stl",
  "swf",
  "ttf",
  "wav",
  "webm",
  "webp",
  "zip",
];

export const RESOURCE_ITEM_HEIGHT = 48; // Helper constant to determine PAGE SIZE
export const TABLET_LANDSCAPE_PAGE_SIZE = 40;
export const TABLET_PORTRAIT_PAGE_SIZE = 60;

export const TYPE_ITEM_HEIGHT = 48; // Helper constant to determine PAGE SIZE
export const TYPE_LANDSCAPE_PAGE_SIZE = 40;
export const TYPE_PORTRAIT_PAGE_SIZE = 60;

export const TABLET_LANDSCAPE_COLUMNS = 4;
export const TABLET_PORTRAIT_COLUMNS = 3;
export const DESKTOP_COLUMNS = 2;

export const TABLET_LANDSCAPE_DETAILS_SECTION_WIDTH = ["25%", "50%", "75%"];
export const TABLET_PORTRAIT_DETAILS_SECTION_WIDTH = ["33%", "66%"];

export const DATE_FORMATS = {
  DE: "DD.MM.YYYY",
  UK: "DD/MM/YYYY",
  US: "MM/DD/YYYY",
};

export const ICON_NAMES = {
  "ICN-EXPERT-ASSET": "icn-expert-asset.svg",
  "ICN-EXPERT-ATTACHMENTS": "icn-expert-attachments.svg",
  "ICN-EXPERT-BASKET": "icn-expert-basket.svg",
  "ICN-EXPERT-BLANK_PANEL": "icn-expert-blank_panel.svg",
  "ICN-EXPERT-BOOKMARK": "icn-expert-bookmark.svg",
  "ICN-EXPERT-CABINET-ACCESSORY": "icn-expert-cabinet-accessory.svg",
  "ICN-EXPERT-CABINET-ACCESSORY_DECOMISSION":
    "icn-expert-cabinet-accessory_decomission.svg",
  "ICN-EXPERT-CABINET-ACCESSORY_PROVISIONING":
    "icn-expert-cabinet-accessory_provisioning.svg",
  "ICN-EXPERT-CABINETS-ALL": "icn-expert-cabinets_all.svg",
  "ICN-EXPERT-CARD": "icn-expert-card.svg",
  "ICN-EXPERT-CHASSIS_PROVISIONING": "icn-expert-chassis_provisioning.svg",
  "ICN-EXPERT-COG": "icn-expert-cog.svg",
  "ICN-EXPERT-LOCATION": "icn-expert-location.svg",
  "ICN-EXPERT-RESOURCES": "icn-expert-resources.svg",
  "ICN-EXPERT-SERVER": "icn-expert-server.svg",
};

export const THEME = {
  AUTOMATIC: "AUTOMATIC",
  DARK: "DARK",
  LIGHT: "LIGHT",
};

export const THEME_EXPIRATION_DAYS = 400;

export const ROUTES = {
  PROFILE: "profile",
  ORGANIZATION: "organization",
  RESOURCES: "resources",
  LIBRARY: "library",
  FAVORITES: "favorites",
  RECENTS: "recents",
  MORE: "more",
  REPORTS: "reports",
  SEARCH: "search",
  ABOUT: "about",
  PREFERENCES: "preferences",
};

export const EVENT_NAMES = {
  FAV_ICON_CLICKED: "Favorite icon clicked",
};

export const permissions = {
  USER_MANAGEMENT_ADD: "userManagement_add",
  USER_MANAGEMENT_EDIT: "userManagement_edit",
  USER_MANAGEMENT_VIEW: "userManagement_view",
  USER_MANAGEMENT_DELETE: "userManagement_delete",

  ORG_MANAGEMENT_EDIT: "orgManagement_edit",
  ORG_MANAGEMENT_SUBSCRIPTION_EDIT: "orgManagement_subscription_edit",

  ASSET_MANAGEMENT_RESOURCE_VIEW: "assetManagement_view",

  ASSET_MANAGEMENT_HARDWARE_ADD: "assetManagement_hardware_add",
  ASSET_MANAGEMENT_HARDWARE_EDIT: "assetManagement_hardware_edit",
  ASSET_MANAGEMENT_HARDWARE_DELETE: "assetManagement_hardware_delete",

  ASSET_MANAGEMENT_LOCATION_ADD: "assetManagement_location_add",
  ASSET_MANAGEMENT_LOCATION_EDIT: "assetManagement_location_edit",
  ASSET_MANAGEMENT_LOCATION_DELETE: "assetManagement_location_delete",

  ASSET_MANAGEMENT_RACK_ADD: "assetManagement_rack_add",
  ASSET_MANAGEMENT_RACK_EDIT: "assetManagement_rack_edit",
  ASSET_MANAGEMENT_RACK_DELETE: "assetManagement_rack_delete",

  ASSET_MANAGEMENT_TYPE_ADD: "assetManagement_type_add",
  ASSET_MANAGEMENT_TYPE_EDIT: "assetManagement_type_edit",
  ASSET_MANAGEMENT_TYPE_DELETE: "assetManagement_type_delete",

  GRAPHICAL_OBJECT_ADD: "graphical_object_add",
  GRAPHICAL_OBJECT_EDIT: "graphical_object_edit",
  GRAPHICAL_OBJECT_DELETE: "graphical_object_delete",

  REPORTING_USER_DEFINED_CREATE: "reporting_user_defined_create",
  REPORTING_USER_DEFINED_EDIT: "reporting_user_defined_edit",
  REPORTING_USER_DEFINED_DELETE: "reporting_user_defined_delete",
  REPORTING_USER_DEFINED_EXECUTE: "reporting_user_defined_execute",
  REPORTING_SYSTEM_REPORT_EXECUTE: "reporting_system_report_execute",
};

export const RESOURCE_CATEGORIES = {
  LOCATION: "LOCATION",
  HARDWARE_ASSET: "HARDWARE_ASSET",
  RACK: "RACK",
};

const LANGUAGES_DATA = [
  { code: "en", label: "en", isSupported: true },
  { code: "de", label: "de", isSupported: false },
];

export const supportedLanguages = LANGUAGES_DATA.filter((l) => l.isSupported);

export const SORT_ORDER_NAME = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
};

export const CATEGORY_SELECT_LIST = [
  {
    id: 1,
    label: "Location",
    value: "LOCATION",
  },
  {
    id: 3,
    label: "Hardware Asset",
    value: "HARDWARE_ASSET",
  },
  {
    id: 2,
    label: "Rack",
    value: "RACK",
  },
];

export const reportTypes = {
  ZONE_LIST: "ZONE_LIST",
  ASSET_LIST_PER_LOCATION: "ASSET_LIST_PER_LOCATION",
  ASSET_LIST_PER_RACK: "ASSET_LIST_PER_RACK",
};

export const transitionDirections = {
  RIGHT_TO_LEFT: "rtl",
  LEFT_TO_RIGHT: "ltr",
  TOP_TO_BOTTOM: "ttb",
  BOTTOM_TO_TOP: "btt",
};

export const PIXELS_PER_RACK_UNIT = 4.5;
export const SIDE_FRAMES_PERCENTS = 10;
export const USABLE_AREA_PERCENTS = 80;
export const FIT_WIDTH_MODE_EMPTY_SPACE_PERCENTS = 20;

export const TAGS_MIN_CHARACTERS = 3;
export const TAGS_MAX_CHARACTERS = 36;
export const TAGS_LABEL_AUTOCOMPLETE_REGEX =
  /[%<>@=^*\\$'"[\]{}()&`+?!/#|;,.:]/;
export const TAGS_LABEL_TEXT_FIELD_REGEX =
  /[%<>@=^*\s\\$'"[\]{}()&`+?!/#|;,.:]/;

export const FLOORPLAN_UNIT = 100;
export const ROTATION_STEP_DEGREES = 15;
export const SCALE_BY = 1.02;
export const CM_FACTOR = 10;
export const CANVAS_WIDTH = 3000;
export const CANVAS_HEIGHT = 3000;
export const CANVAS_OFFSET = 150;

export const SUBSCRIPTION_ERRORS = {
  SUBSCRIPTION_NOT_FOUND: "SUBSCRIPTION_NOT_FOUND",
  PAYMENT_REQUIRED: "PAYMENT_REQUIRED",
  RULE_CREATION_ERROR: "RULE_CREATION_ERROR",
  ADDON_NOT_FOUND: "ADDON_NOT_FOUND",
  SUBSCRIPTION_ADDON_NOT_FOUND: "SUBSCRIPTION_ADDON_NOT_FOUND",
  CURRENT_SUBSCRIPTION_NOT_FOUND: "CURRENT_SUBSCRIPTION_NOT_FOUND",
  SUBSCRIPTION_STATUS_NOT_VALID: "SUBSCRIPTION_STATUS_NOT_VALID",
  ACTIVE_PAID_SUBSCRIPTION_ALREADY_EXISTS:
    "ACTIVE_PAID_SUBSCRIPTION_ALREADY_EXISTS",
  SUBSCRIPTION_TIER_NOT_FOUND: "SUBSCRIPTION_TIER_NOT_FOUND",
  TIER_ADDON_LIMIT_EXCEEDED: "TIER_ADDON_LIMIT_EXCEEDED",
  BILLING_PERIOD_REQUIRED: "BILLING_PERIOD_REQUIRED",
  ADDON_NOT_ALLOWED_FOR_TIER: "ADDON_NOT_ALLOWED_FOR_TIER",
  RESTRICTED_ACCESS: "RESTRICTED_ACCESS",
  LIMIT_EXCEEDED_USER: "LIMIT_EXCEEDED_USER",
  RESTRICTED_DUE_TO_OVERLIMIT_USER: "RESTRICTED_DUE_TO_OVERLIMIT_USER",
  LIMIT_EXCEEDED_LOCATION: "LIMIT_EXCEEDED_LOCATION",
  RESTRICTED_DUE_TO_OVERLIMIT_LOCATION: "RESTRICTED_DUE_TO_OVERLIMIT_LOCATION",
  LIMIT_EXCEEDED_RACK: "LIMIT_EXCEEDED_RACK",
  RESTRICTED_DUE_TO_OVERLIMIT_RACK: "RESTRICTED_DUE_TO_OVERLIMIT_RACK",
  LIMIT_EXCEEDED_HARDWARE_ASSET: "LIMIT_EXCEEDED_HARDWARE_ASSET",
  RESTRICTED_DUE_TO_OVERLIMIT_HARDWARE_ASSET:
    "RESTRICTED_DUE_TO_OVERLIMIT_HARDWARE_ASSET",
  LIMIT_EXCEEDED_ASSET: "LIMIT_EXCEEDED_ASSET",
  RESTRICTED_DUE_TO_OVERLIMIT_ASSET: "RESTRICTED_DUE_TO_OVERLIMIT_ASSET",
};

export const countries = [
  { code: "AD", label: "Andorra", phone: "376" },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
  },
  { code: "AF", label: "Afghanistan", phone: "93" },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
  },
  { code: "AI", label: "Anguilla", phone: "1-264" },
  { code: "AL", label: "Albania", phone: "355" },
  { code: "AM", label: "Armenia", phone: "374" },
  { code: "AO", label: "Angola", phone: "244" },
  { code: "AQ", label: "Antarctica", phone: "672" },
  { code: "AR", label: "Argentina", phone: "54" },
  { code: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AT", label: "Austria", phone: "43" },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
  },
  { code: "AW", label: "Aruba", phone: "297" },
  { code: "AX", label: "Alland Islands", phone: "358" },
  { code: "AZ", label: "Azerbaijan", phone: "994" },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
  },
  { code: "BB", label: "Barbados", phone: "1-246" },
  { code: "BD", label: "Bangladesh", phone: "880" },
  { code: "BE", label: "Belgium", phone: "32" },
  { code: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BG", label: "Bulgaria", phone: "359" },
  { code: "BH", label: "Bahrain", phone: "973" },
  { code: "BI", label: "Burundi", phone: "257" },
  { code: "BJ", label: "Benin", phone: "229" },
  { code: "BL", label: "Saint Barthelemy", phone: "590" },
  { code: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BO", label: "Bolivia", phone: "591" },
  { code: "BR", label: "Brazil", phone: "55" },
  { code: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BT", label: "Bhutan", phone: "975" },
  { code: "BV", label: "Bouvet Island", phone: "47" },
  { code: "BW", label: "Botswana", phone: "267" },
  { code: "BY", label: "Belarus", phone: "375" },
  { code: "BZ", label: "Belize", phone: "501" },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
  },
  { code: "CH", label: "Switzerland", phone: "41" },
  { code: "CI", label: "Cote d'Ivoire", phone: "225" },
  { code: "CK", label: "Cook Islands", phone: "682" },
  { code: "CL", label: "Chile", phone: "56" },
  { code: "CM", label: "Cameroon", phone: "237" },
  { code: "CN", label: "China", phone: "86" },
  { code: "CO", label: "Colombia", phone: "57" },
  { code: "CR", label: "Costa Rica", phone: "506" },
  { code: "CU", label: "Cuba", phone: "53" },
  { code: "CV", label: "Cape Verde", phone: "238" },
  { code: "CW", label: "Curacao", phone: "599" },
  { code: "CX", label: "Christmas Island", phone: "61" },
  { code: "CY", label: "Cyprus", phone: "357" },
  { code: "CZ", label: "Czech Republic", phone: "420" },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
  },
  { code: "DJ", label: "Djibouti", phone: "253" },
  { code: "DK", label: "Denmark", phone: "45" },
  { code: "DM", label: "Dominica", phone: "1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
  },
  { code: "DZ", label: "Algeria", phone: "213" },
  { code: "EC", label: "Ecuador", phone: "593" },
  { code: "EE", label: "Estonia", phone: "372" },
  { code: "EG", label: "Egypt", phone: "20" },
  { code: "EH", label: "Western Sahara", phone: "212" },
  { code: "ER", label: "Eritrea", phone: "291" },
  { code: "ES", label: "Spain", phone: "34" },
  { code: "ET", label: "Ethiopia", phone: "251" },
  { code: "FI", label: "Finland", phone: "358" },
  { code: "FJ", label: "Fiji", phone: "679" },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "FO", label: "Faroe Islands", phone: "298" },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
  },
  { code: "GA", label: "Gabon", phone: "241" },
  { code: "GB", label: "United Kingdom", phone: "44" },
  { code: "GD", label: "Grenada", phone: "1-473" },
  { code: "GE", label: "Georgia", phone: "995" },
  { code: "GF", label: "French Guiana", phone: "594" },
  { code: "GG", label: "Guernsey", phone: "44" },
  { code: "GH", label: "Ghana", phone: "233" },
  { code: "GI", label: "Gibraltar", phone: "350" },
  { code: "GL", label: "Greenland", phone: "299" },
  { code: "GM", label: "Gambia", phone: "220" },
  { code: "GN", label: "Guinea", phone: "224" },
  { code: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "GR", label: "Greece", phone: "30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "GT", label: "Guatemala", phone: "502" },
  { code: "GU", label: "Guam", phone: "1-671" },
  { code: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GY", label: "Guyana", phone: "592" },
  { code: "HK", label: "Hong Kong", phone: "852" },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  { code: "HN", label: "Honduras", phone: "504" },
  { code: "HR", label: "Croatia", phone: "385" },
  { code: "HT", label: "Haiti", phone: "509" },
  { code: "HU", label: "Hungary", phone: "36" },
  { code: "ID", label: "Indonesia", phone: "62" },
  { code: "IE", label: "Ireland", phone: "353" },
  { code: "IL", label: "Israel", phone: "972" },
  { code: "IM", label: "Isle of Man", phone: "44" },
  { code: "IN", label: "India", phone: "91" },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "IQ", label: "Iraq", phone: "964" },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IS", label: "Iceland", phone: "354" },
  { code: "IT", label: "Italy", phone: "39" },
  { code: "JE", label: "Jersey", phone: "44" },
  { code: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JO", label: "Jordan", phone: "962" },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
  },
  { code: "KE", label: "Kenya", phone: "254" },
  { code: "KG", label: "Kyrgyzstan", phone: "996" },
  { code: "KH", label: "Cambodia", phone: "855" },
  { code: "KI", label: "Kiribati", phone: "686" },
  { code: "KM", label: "Comoros", phone: "269" },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KW", label: "Kuwait", phone: "965" },
  { code: "KY", label: "Cayman Islands", phone: "1-345" },
  { code: "KZ", label: "Kazakhstan", phone: "7" },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LB", label: "Lebanon", phone: "961" },
  { code: "LC", label: "Saint Lucia", phone: "1-758" },
  { code: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LK", label: "Sri Lanka", phone: "94" },
  { code: "LR", label: "Liberia", phone: "231" },
  { code: "LS", label: "Lesotho", phone: "266" },
  { code: "LT", label: "Lithuania", phone: "370" },
  { code: "LU", label: "Luxembourg", phone: "352" },
  { code: "LV", label: "Latvia", phone: "371" },
  { code: "LY", label: "Libya", phone: "218" },
  { code: "MA", label: "Morocco", phone: "212" },
  { code: "MC", label: "Monaco", phone: "377" },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
  },
  { code: "ME", label: "Montenegro", phone: "382" },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
  },
  { code: "MG", label: "Madagascar", phone: "261" },
  { code: "MH", label: "Marshall Islands", phone: "692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ML", label: "Mali", phone: "223" },
  { code: "MM", label: "Myanmar", phone: "95" },
  { code: "MN", label: "Mongolia", phone: "976" },
  { code: "MO", label: "Macao", phone: "853" },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "MQ", label: "Martinique", phone: "596" },
  { code: "MR", label: "Mauritania", phone: "222" },
  { code: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MT", label: "Malta", phone: "356" },
  { code: "MU", label: "Mauritius", phone: "230" },
  { code: "MV", label: "Maldives", phone: "960" },
  { code: "MW", label: "Malawi", phone: "265" },
  { code: "MX", label: "Mexico", phone: "52" },
  { code: "MY", label: "Malaysia", phone: "60" },
  { code: "MZ", label: "Mozambique", phone: "258" },
  { code: "NA", label: "Namibia", phone: "264" },
  { code: "NC", label: "New Caledonia", phone: "687" },
  { code: "NE", label: "Niger", phone: "227" },
  { code: "NF", label: "Norfolk Island", phone: "672" },
  { code: "NG", label: "Nigeria", phone: "234" },
  { code: "NI", label: "Nicaragua", phone: "505" },
  { code: "NL", label: "Netherlands", phone: "31" },
  { code: "NO", label: "Norway", phone: "47" },
  { code: "NP", label: "Nepal", phone: "977" },
  { code: "NR", label: "Nauru", phone: "674" },
  { code: "NU", label: "Niue", phone: "683" },
  { code: "NZ", label: "New Zealand", phone: "64" },
  { code: "OM", label: "Oman", phone: "968" },
  { code: "PA", label: "Panama", phone: "507" },
  { code: "PE", label: "Peru", phone: "51" },
  { code: "PF", label: "French Polynesia", phone: "689" },
  { code: "PG", label: "Papua New Guinea", phone: "675" },
  { code: "PH", label: "Philippines", phone: "63" },
  { code: "PK", label: "Pakistan", phone: "92" },
  { code: "PL", label: "Poland", phone: "48" },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  { code: "PN", label: "Pitcairn", phone: "870" },
  { code: "PR", label: "Puerto Rico", phone: "1" },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
  },
  { code: "PT", label: "Portugal", phone: "351" },
  { code: "PW", label: "Palau", phone: "680" },
  { code: "PY", label: "Paraguay", phone: "595" },
  { code: "QA", label: "Qatar", phone: "974" },
  { code: "RE", label: "Reunion", phone: "262" },
  { code: "RO", label: "Romania", phone: "40" },
  { code: "RS", label: "Serbia", phone: "381" },
  { code: "RU", label: "Russian Federation", phone: "7" },
  { code: "RW", label: "Rwanda", phone: "250" },
  { code: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SC", label: "Seychelles", phone: "248" },
  { code: "SD", label: "Sudan", phone: "249" },
  { code: "SE", label: "Sweden", phone: "46" },
  { code: "SG", label: "Singapore", phone: "65" },
  { code: "SH", label: "Saint Helena", phone: "290" },
  { code: "SI", label: "Slovenia", phone: "386" },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
  },
  { code: "SK", label: "Slovakia", phone: "421" },
  { code: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SM", label: "San Marino", phone: "378" },
  { code: "SN", label: "Senegal", phone: "221" },
  { code: "SO", label: "Somalia", phone: "252" },
  { code: "SR", label: "Suriname", phone: "597" },
  { code: "SS", label: "South Sudan", phone: "211" },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
  },
  { code: "SV", label: "El Salvador", phone: "503" },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
  },
  { code: "SZ", label: "Swaziland", phone: "268" },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TD", label: "Chad", phone: "235" },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "TG", label: "Togo", phone: "228" },
  { code: "TH", label: "Thailand", phone: "66" },
  { code: "TJ", label: "Tajikistan", phone: "992" },
  { code: "TK", label: "Tokelau", phone: "690" },
  { code: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TM", label: "Turkmenistan", phone: "993" },
  { code: "TN", label: "Tunisia", phone: "216" },
  { code: "TO", label: "Tonga", phone: "676" },
  { code: "TR", label: "Turkey", phone: "90" },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
  },
  { code: "TV", label: "Tuvalu", phone: "688" },
  {
    code: "TW",
    label: "Taiwan",
    phone: "886",
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
  },
  { code: "UA", label: "Ukraine", phone: "380" },
  { code: "UG", label: "Uganda", phone: "256" },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
  },
  { code: "UY", label: "Uruguay", phone: "598" },
  { code: "UZ", label: "Uzbekistan", phone: "998" },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "VE", label: "Venezuela", phone: "58" },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
  },
  { code: "VN", label: "Vietnam", phone: "84" },
  { code: "VU", label: "Vanuatu", phone: "678" },
  { code: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "WS", label: "Samoa", phone: "685" },
  { code: "XK", label: "Kosovo", phone: "383" },
  { code: "YE", label: "Yemen", phone: "967" },
  { code: "YT", label: "Mayotte", phone: "262" },
  { code: "ZA", label: "South Africa", phone: "27" },
  { code: "ZM", label: "Zambia", phone: "260" },
  { code: "ZW", label: "Zimbabwe", phone: "263" },
];

export const getParentIds = (resource) => {
  const ids = [];

  while (resource && resource?.id !== undefined) {
    resource = resource?.parent;
    ids.push(resource?.id);
  }

  return ids.reverse().slice(1);
};

export const constructColumns = async (resource, organizationId) => {
  const columns = [];
  const parentIds = getParentIds(resource);

  const parentsResponse = await getResources(organizationId);
  const parentsData = parentsResponse?.data;
  const parents = parentsData?.data;

  const parentColumn = {
    parentId: null,
    items: parents,
    level: 1,
    columnType: "list",
  };

  columns.push(parentColumn);

  for (let i = 0; i < parentIds.length; i++) {
    const parentId = parentIds[i];

    const childrenResponse = await getResources(organizationId, parentId);
    const childrenData = childrenResponse?.data;
    const children = childrenData?.data;

    const column = {
      parentId,
      items: children,
      level: i + 2,
      columnType: "list",
    };

    columns.push(column);
  }

  return columns;
};

export const checkOrganizationRestricted = (
  organization,
  currentSubscriptionError
) => {
  if (organization?.restricted) {
    return true;
  }

  if (organization?.owner) {
    if (
      currentSubscriptionError?.data?.error ===
      SUBSCRIPTION_ERRORS["SUBSCRIPTION_NOT_FOUND"]
    ) {
      return true;
    }
  }

  if (!organization?.subscription) {
    return true;
  }

  return false;
};

export const showValidationError = (error, t, i18n) => {
  if (
    Object.keys(SUBSCRIPTION_ERRORS).some((key) => key === error?.data?.error)
  ) {
    messageError(getTranslation(error?.data?.error, t, i18n));
  } else {
    if (error?.data?.message) {
      messageError(error?.data?.message);
    } else {
      messageError("Operation failed");
    }
  }
};

export const closestInteger = (a, b) => {
  const c1 = a - (a % b);
  const c2 = a + b - (a % b);
  if (a - c1 > c2 - a) {
    return c2;
  } else {
    return c1;
  }
};

export const getFallBackLanguage = (selectedLang) => {
  try {
    let lang = selectedLang;
    if (lang.indexOf("_") !== -1) {
      lang = lang.substring(0, lang.indexOf("_"));
    }

    return lang;
  } catch {
    return "en";
  }
};

export const checkIsAuthenticated = () => {
  return KeycloakService.isLoggedIn();
};

export const userLocalStorageKey = (keyName) => {
  return keyName + "_" + KeycloakService.getUsername();
};

export const hasAccess = (type, accessUnits, userAccessUnits) => {
  return type === "all"
    ? !!accessUnits?.every((item) => userAccessUnits?.includes(item))
    : !!accessUnits?.some((item) => userAccessUnits?.includes(item));
};

export const getPermissionsFromUserRoles = (userRoles) => {
  const resultPermissions = [];

  userRoles?.forEach((role) => {
    const { permissions } = role;

    permissions.forEach((permission) => {
      const { name } = permission;

      if (!resultPermissions.includes(name)) {
        resultPermissions.push(name);
      }
    });
  });

  return resultPermissions;
};

export const truncateValue = (value, maxChar, indexEllipse) => {
  return value.length > maxChar
    ? value.substring(0, indexEllipse) + "..."
    : value;
};

export const localizeDate = (date, region) => {
  let format = DATE_FORMATS.DE;

  if (region === "us") {
    format = DATE_FORMATS.US;
  } else if (region === "uk") {
    format = DATE_FORMATS.UK;
  }

  const formattedDate = dayjs(date).format(format);
  return formattedDate;
};

export const datePickerFormat = (region) => {
  let format = "de";

  if (region === "us") {
    format = "us";
  } else if (region === "uk") {
    format = "en-gb";
  }

  return format;
};

export const delimiterLocalize = (region) => {
  let format = "de";

  if (region === "us") {
    format = "en-us";
  } else if (region === "uk") {
    format = "en-gb";
  }

  return format;
};

export const measurementDisplay = ({ value, unit, region, reverse }) => {
  if (!unit) {
    return {
      unit: "",
      value,
    };
  }

  let system = "imperial";

  if (region === "de") {
    system = "metric";
  }

  let from = "";
  let to = "";

  if (unit === "MILLIMETER") {
    from = "mm";

    if (system === "metric") {
      to = "m";
    } else if (system === "imperial") {
      to = "ft";
    }
  } else if (unit === "GRAM") {
    from = "g";

    if (system === "metric") {
      to = "kg";
    } else if (system === "imperial") {
      to = "lb";
    }
  } else if (unit === "WATT") {
    from = "W";
    to = "kW";
  }

  return {
    unit: to,
    value: reverse
      ? Number(convert(Number(value)).from(to).to(from).toFixed(2))
      : Number(convert(Number(value)).from(from).to(to).toFixed(2)),
  };
};

export const getDatesFromRecentsData = (recentsData) => {
  let dates = [];

  recentsData?.forEach((recent) => {
    const { createdAt } = recent;
    const date = createdAt?.split("T")[0];

    if (!dates.includes(date)) {
      dates.push(date);
    }
  });

  return dates;
};

export const getFallbackIcon = (category) => {
  let iconName = "icn-expert-location.svg";

  if (category === "RACK") {
    iconName = "icn-expert-resources.svg";
  } else if (category === "HARDWARE_ASSET") {
    iconName = "icn-expert-server.svg";
  }

  return iconName;
};

export const getTranslation = (placeHolder, t, i18n) => {
  return i18n?.exists(placeHolder)
    ? t(placeHolder)
    : i18n?.exists(placeHolder, { lng: DEFAULT_LANGUAGE })
    ? t(placeHolder, { lng: DEFAULT_LANGUAGE })
    : placeHolder;
};

export const calculateActiveDuration = (activeDuration) => {
  const timeValue = parseInt(activeDuration.slice(0, -1));
  const unit = activeDuration.slice(-1);

  let seconds;
  if (unit === "M") {
    seconds = timeValue * 60; // Convert minutes to seconds
  } else if (unit === "H") {
    seconds = timeValue * 60 * 60; // Convert hours to seconds
  } else if (unit === "D") {
    seconds = timeValue * 24 * 60 * 60; // Convert days to seconds
  }

  return seconds;
};

export const testInput = {
  CONTACT_PERSON: (value) => {
    const regex = /^[A-Za-zÀàÂâæÇçÉéÈèÊêËëĞğÎîÏïİıÔôÖöŞşßÙùÛûÜü.-\s]*$/;
    return regex.test(value) && value?.length >= 2;
  },
  CONTACT_EMAIL: (value) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    return regex.test(value) && value?.length >= 2;
  },
  PHONE_NUMBER: (value) => {
    return value?.length >= 10;
  },
  ORGANIZATION_NAME: (value) => {
    return value?.length >= 2 && value?.length <= 100;
  },
  USER_NAME: (value) => {
    const regex = /^[A-Za-zÀàÂâæÇçÉéÈèÊêËëĞğÎîÏïİıÔôÖöŞşßÙùÛûÜü.-\s]*$/;
    return value?.length >= 2 && regex.test(value);
  },
  USER_EMAIL: (value) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/;
    return regex.test(value);
  },
  BILLING_ADDRESS: (value) => {
    return value?.length >= 2;
  },
  VAT_NUMBER: (value) => {
    const regex = /^[A-Za-z]{2}\d+$/;

    return regex.test(value) && value?.length >= 2;
  },
  NICKNAME: (value) => {
    const regex = /^[0-9a-zàâæçéèêëğîïıôöşùûü_]*$/;
    return value?.length >= 3 && value?.length <= 35 && regex.test(value);
  },
};

export const getNormalizedFile = (file) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1000,
      maxHeight: 1000,
      success(normalizedFile) {
        resolve(normalizedFile);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

// Crop functionality
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) {
    return null;
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(file);
    }, "image/jpeg");
  });
}

// Dynamic font size
export const mapBrowserFontSizeToMuiTypography = (browserFontSize) => {
  // The implementation to map browser font size to MUI typography options here
  // We can use the Apple typography specifications for different variants
  // e.g., adjust font size for h1, h2, h3, etc.
  let adjustedFontSize = browserFontSize;

  if (
    window.innerWidth <= BREAKPOINTS.TABLET &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.MOBILE
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.MOBILE;
  } else if (
    window.innerWidth > BREAKPOINTS.TABLET &&
    window.innerWidth <= BREAKPOINTS.DESKTOP &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.TABLET
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.TABLET;
  } else if (
    window.innerWidth > BREAKPOINTS.DESKTOP &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.DESKTOP
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.DESKTOP;
  }

  const muiTypographyOptions = {
    h1: {
      fontSize: adjustedFontSize * 2.5,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: adjustedFontSize * 2,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: adjustedFontSize * 1.75,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: adjustedFontSize * 1.5,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: adjustedFontSize * 1.25,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: adjustedFontSize * 1.1,
      fontWeight: 500,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: adjustedFontSize * 1,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: adjustedFontSize * 0.875,
      lineHeight: 1.2,
    },
    body1: {
      fontSize: adjustedFontSize * 1,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: adjustedFontSize * 0.875,
      lineHeight: 1.5,
    },
    button: {
      fontSize: adjustedFontSize * 0.875,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: adjustedFontSize * 0.75,
      lineHeight: 1.66,
    },
    overline: {
      fontSize: adjustedFontSize * 0.75,
      fontWeight: 500,
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  };

  return muiTypographyOptions;
};

export const getMappedFontSize = (browserFontSize) => {
  const base = 16;
  let adjustedFontSize = browserFontSize;
  const root = document.querySelector(":root");

  if (
    window.innerWidth <= BREAKPOINTS.TABLET &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.MOBILE
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.MOBILE;
  } else if (
    window.innerWidth > BREAKPOINTS.TABLET &&
    window.innerWidth <= BREAKPOINTS.DESKTOP &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.TABLET
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.TABLET;
  } else if (
    window.innerWidth > BREAKPOINTS.DESKTOP &&
    adjustedFontSize > MAXIMUM_ROOT_SIZES.DESKTOP
  ) {
    adjustedFontSize = MAXIMUM_ROOT_SIZES.DESKTOP;
  }

  const pairs = Math.floor(Math.abs(adjustedFontSize - base) / 2);
  let translation = 150;

  if (adjustedFontSize < base) {
    translation += pairs * 35;
  } else {
    translation -= pairs * 10;
  }

  root.style.setProperty("--translate-parent-label", `-${translation}%`);

  return adjustedFontSize;
};

export function extractUtcOffset(utc) {
  const matches = utc.match(/([-+]?\d+)(?::(\d+))?/);

  if (matches) {
    const hours = parseInt(matches[1]);
    const minutes = matches[2] ? parseInt(matches[2]) : 0;
    return hours * 60 + minutes;
  }

  return null;
}

export const extractTextColor = (hex) => {
  const rrggbb = hex.substring(1); // strip #
  const rgb = parseInt(rrggbb, 16); // convert "rrggbb" to decimal

  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000
  );

  const textColor = brightness > 125 ? "black" : "white";

  return textColor;
};

export const convertDegreesToRadians = (degrees) => {
  const pi = Math.PI;
  return degrees * (pi / 180);
};

export const calculateAngleBetweenLines = (line1, line2) => {
  // Calculate the dot product of the two lines
  const dotProduct =
    (line1.x1 - line1.x0) * (line2.x1 - line2.x0) +
    (line1.y1 - line1.y0) * (line2.y1 - line2.y0);

  // Calculate the magnitudes of the two lines
  const magnitude1 = Math.sqrt(
    Math.pow(line1.x1 - line1.x0, 2) + Math.pow(line1.y1 - line1.y0, 2)
  );

  const magnitude2 = Math.sqrt(
    Math.pow(line2.x1 - line2.x0, 2) + Math.pow(line2.y1 - line2.y0, 2)
  );

  // Ensure non-zero magnitudes to avoid division by zero
  if (magnitude1 === 0 || magnitude2 === 0) {
    return NaN; // Undefined angle for lines with zero length
  }

  // Calculate the cosine of the angle between the lines
  const cosTheta = dotProduct / (magnitude1 * magnitude2);

  // Calculate the angle in radians
  const angleInRadians = Math.acos(cosTheta);

  // Convert the angle to degrees
  const angleInDegrees = (angleInRadians * 180) / Math.PI;

  return angleInDegrees;
};

export const calculateLineLength = (line) => {
  const { x0, y0, x1, y1 } = line;

  const deltaX = x1 - x0;
  const deltaY = y1 - y0;

  // Use the Pythagorean theorem to calculate the distance (length) between two points
  const length = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

  return length;
};

export const solveSystemOfEquations = (ac, oa, xo, yo, angleInDegrees) => {
  // Calculate solutions
  let xa = oa + xo;
  let ya = yo;
  let xc = xa;
  let yc = ac + ya;

  if (angleInDegrees > 90 && angleInDegrees <= 180) {
    yc = ya - ac;
  } else if (angleInDegrees > 180 && angleInDegrees <= 270) {
    xc = xo - oa;
    yc = ya - ac;
  } else if (angleInDegrees > 270) {
    xc = xo - oa;
  }

  // Check for edge cases
  if (angleInDegrees === 0 || angleInDegrees === 180) {
    xc = xo;
  }

  if (angleInDegrees === 90 || angleInDegrees === 270) {
    yc = yo;
  }

  // Return the solutions as an object
  return {
    xa,
    ya,
    xc,
    yc,
  };
};

export const transformAngle = (angleInDegrees) => {
  let resultAngle = angleInDegrees;

  if (angleInDegrees > 90 && angleInDegrees <= 180) {
    const alpha = -(angleInDegrees - 180);
    resultAngle = alpha;
  } else if (angleInDegrees > 180 && angleInDegrees <= 270) {
    const alpha = angleInDegrees - 180;
    resultAngle = alpha;
  } else if (angleInDegrees > 270) {
    const alpha = 360 - angleInDegrees;
    resultAngle = alpha;
  }

  return resultAngle;
};

export const calculateCenterPointWithRotation = (angleInDegrees, xo, yo, r) => {
  // 1. Convert degrees to radians
  const angleInRadians = convertDegreesToRadians(
    90 - transformAngle(angleInDegrees)
  );

  // 2. Calculate ac-side of the imaginary 90-degrees triangle
  const ac = Math.abs(r * Math.sin(angleInRadians));

  // 3. Calculate oa-side of the imaginary 90-degrees triangle
  const oa = Math.abs(r * Math.cos(angleInRadians));

  // 4. Find the coordinates of points A and C
  const result = solveSystemOfEquations(ac, oa, xo, yo, angleInDegrees);

  // 5. Find the coordinates of point M
  if (result) {
    const { xc, yc } = result;

    let xm = (xo + xc) / 2;
    let ym = (yo + yc) / 2;

    return { xc, yc, xm, ym };
  }

  return null;
};

export const roundToNearestMultiple = (number, factor) => {
  // Calculate the remainder when divided by the factor
  const remainder = number % factor;

  // Determine whether to round up or down based on the remainder
  const roundDown = remainder < factor / 2;

  // Calculate the closest multiple of the factor
  const closestMultiple = roundDown
    ? number - remainder
    : number + (factor - remainder);

  return closestMultiple;
};

export const calculateArcPoints = (x, y, radius, startAngle, endAngle) => {
  const points = [];
  const angleStep = 1; // Adjust this for smoother or coarser arcs

  startAngle %= 360;
  endAngle %= 360;

  if (startAngle < 0) {
    startAngle += 360;
  }

  if (endAngle < 0) {
    endAngle += 360;
  }

  const direction = startAngle < endAngle ? 1 : -1;

  for (
    let angle = startAngle;
    direction * angle <= direction * endAngle;
    angle += direction * angleStep
  ) {
    const radians = (angle * Math.PI) / 180;
    const pointX = x + radius * Math.cos(radians);
    const pointY = y + radius * Math.sin(radians);
    points.push(pointX, pointY);
  }

  return points;
};

export const makeGrid = (roomWidth, roomDepth) => {
  const lines = [];

  // Draw vertical lines
  for (let i = 0; i < roomWidth + 1; i++) {
    lines.push(
      <Line
        key={`v${i}`}
        points={[
          Math.round(i * FLOORPLAN_UNIT),
          0,
          Math.round(i * FLOORPLAN_UNIT),
          roomDepth * FLOORPLAN_UNIT,
        ]}
        stroke="#DCDCDC"
        strokeWidth={1}
      />
    );
  }

  // Draw horizontal lines
  for (let j = 0; j < roomDepth + 1; j++) {
    lines.push(
      <Line
        key={`h${j}`}
        points={[
          0,
          Math.round(j * FLOORPLAN_UNIT),
          roomWidth * FLOORPLAN_UNIT,
          Math.round(j * FLOORPLAN_UNIT),
        ]}
        stroke="#DCDCDC"
        strokeWidth={2}
      />
    );
  }

  return lines;
};

export const makeWalls = (wallsList) => {
  // Walls of the room
  const walls = [];

  for (let i = 0; i < wallsList.length; i++) {
    walls.push(wallsList[i]);
  }

  return walls;
};

export const makeObjects = (objectsList) => {
  // Objects of the room
  const objects = [];

  for (let i = 0; i < objectsList.length; i++) {
    objects.push(objectsList[i]);
  }

  return objects;
};

export const calculateRegularTriangleCircumradius = (a) => {
  const h = (Math.sqrt(3) / 2) * a;
  const r = h / 3;
  const R = 2 * r;

  return { h, r, R };
};

export const calculateLineEquation = (line) => {
  // Extract line coordinates
  const { x1, y1, x2, y2 } = line;

  // Check if the line is vertical
  if (x1 === x2) {
    return { slope: NaN, intercept: NaN };
  }

  // Calculate the slope
  const slope = (y2 - y1) / (x2 - x1);

  // Calculate the y-intercept using one of the points and the slope
  const intercept = y1 - slope * x1;

  return { slope, intercept };
};

export const pointOnLine = (x, y, m, b, line) => {
  // Extract line coordinates
  const { x1 } = line;

  // For a vertical line, check if x is equal to x1 (or x2)
  if (isNaN(m) || isNaN(b)) {
    return Math.round(x) === Math.round(x1);
  }

  // Check if the point (x, y) lies on the line y = mx + b
  return Math.abs(y - (m * x + b)) <= 1;
};

export const areLinesParallel = (slope1, slope2) => {
  // Check if the slopes are equal (considering floating-point precision)
  return (isNaN(slope1) && isNaN(slope2)) || slope1 === slope2;
};

export const getOppositeAction = (action) =>
  action === "CREATE" ? "DELETE" : action === "DELETE" ? "CREATE" : "UPDATE";

export const getPageSize = (itemHeight) => {
  return Math.floor(window.outerHeight / itemHeight);
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};
