import ResourceImageForm from "../../components/assets/asset-form/ResourceImageForm";
import { useGetAllFunctionsQuery } from "../../store/slices/api/assetManagementSlice";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { useCallback } from "react";
import { selectUser } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  transitionDirections,
  permissions,
  getTranslation,
  showValidationError,
} from "../../util/utils";
import ChooseAssetImageHeader from "../../navigation/header/assets/ChooseAssetImageHeader";
import {
  useGetResourceImageQuery,
  useGetResourceImagesQuery,
  useUpdateResourceImageMutation,
} from "../../store/slices/api/resourceImagesApiSlice";
import { useGetResourceDetailsQuery } from "../../store/slices/api/assetManagementSlice";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import { setMobileTabValue } from "../../store/slices/assetListSlice";
import { useDispatch } from "react-redux";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";

const EditResourceImagePage = () => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const { resourceId, resourceImageId } = useParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default).id;

  // Queries
  const {
    data: currentResourceData,
    isLoading: isLoadingCurrent,
    isError: isErrorCurrent,
  } = useGetResourceDetailsQuery(
    {
      resourceid: resourceId,
      organizationId,
    },
    {
      skip: !Boolean(resourceId),
    }
  );

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId,
  });

  const { data: resourceImagesData } = useGetResourceImagesQuery(
    { resourceid: resourceId, organizationId },
    { skip: !resourceId }
  );

  // Other variables
  const singleResourceImage = resourceImagesData?.find(
    (image) => image.id === resourceImageId
  );

  const { data: resourceImageUri, isLoading: isLoadingImage } =
    useGetResourceImageQuery(
      {
        imageUri: singleResourceImage ? singleResourceImage?.uri : "",
        organizationId,
        thumbnail: true,
      },
      {
        skip: !singleResourceImage,
      }
    );

  // Other variables
  const direction = searchParams.get("direction");

  const transformedImage = {
    imageCategory: singleResourceImage.imageCategory,
    selectedFile: resourceImageUri,
    resourceid: resourceId,
    id: singleResourceImage.id,
    name: singleResourceImage.name,
    mimeType: singleResourceImage.name?.substring(
      singleResourceImage.name?.lastIndexOf(".")
    ),
    description: singleResourceImage.description,
    file: null,
  };

  // State
  const [resourceImageInput, setResourceImageInput] =
    useState(transformedImage);

  const [name, setName] = useState(
    resourceImageInput?.name?.substring(
      0,
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  const [extension, setExtension] = useState(
    resourceImageInput?.name?.substring(
      resourceImageInput?.name?.lastIndexOf(".")
    )
  );

  // Handlers
  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const cancelHandler = () => {
    dispatch(setMobileTabValue(1));

    navigate(
      `/resources/${resourceId}?direction=${transitionDirections.TOP_TO_BOTTOM}&comingFrom=edit-image`
    );
  };

  // Other variables
  const isValid =
    (resourceImageInput.file || resourceImageInput.selectedFile) && name;

  const isDisabled = !isValid;
  const resourceFunction = getFunction(currentResourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_EDIT`;

  // Mutations
  const [updateResourceImage, { isLoading: isLoadingUpdate }] =
    useUpdateResourceImageMutation();

  const handleSubmit = useCallback(async () => {
    if (!resourceImageInput.selectedFile) {
      messageError(getTranslation("UPLOAD_IMAGE", t, i18n));
      return;
    }

    try {
      const formData = new FormData();

      const { file, imageCategory, description } = resourceImageInput;

      formData.append("file", file);
      formData.append("fileName", name + extension);
      formData.append("imageCategory", imageCategory);
      formData.append("description", description);

      await updateResourceImage({
        formData,
        resourceid: resourceId,
        organizationId,
        updateImage: Boolean(file),
        resourceImageId: resourceImageInput.id,
      }).unwrap();

      cancelHandler();

      messageSuccess(
        getTranslation("RESOURCE_IMAGE_UPDATED_SUCCESSFULLY", t, i18n)
      );
    } catch (error) {
      showValidationError(error, t, i18n);
      console.error("Failed to update resource image", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceImageInput, name]);

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={isLoadingCurrent || isLoadingUpdate || isLoadingImage}
        isError={isErrorCurrent}
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <ChooseAssetImageHeader
                handleAction={handleSubmit}
                handleCancelAction={cancelHandler}
                isDisabled={isDisabled}
              />

              <HomePagePadding>
                <ResourceImageForm
                  resourceImageInput={resourceImageInput}
                  setResourceImageInput={setResourceImageInput}
                  name={name}
                  setName={setName}
                  setExtension={setExtension}
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default EditResourceImagePage;
