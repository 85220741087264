import React from "react";
import { useSelector } from "react-redux";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import ErrorHandling from "../../common/ErrorHandling";
import CanvasImage from "../../common/CanvasImage";
import { selectUser } from "../../../store/slices/authSlice";
import { getSvgIcon } from "../../../util/icons";
import { useTheme } from "@mui/material";

const TableMedia = ({ thumbnailUri, functionName }) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const iconSize = 50;

  // Queries
  const {
    data: resourceImageUri,
    isLoading,
    isError,
  } = useGetResourceImageQuery(
    {
      imageUri: thumbnailUri,
      organizationId,
      thumbnail: true,
    },
    {
      skip: !thumbnailUri,
    }
  );

  return (
    <ErrorHandling isLoading={isLoading} isError={isError}>
      {resourceImageUri && thumbnailUri ? (
        <CanvasImage
          id={thumbnailUri}
          width={50}
          height={50}
          image={resourceImageUri}
        />
      ) : (
        getSvgIcon(
          functionName?.toUpperCase(),
          iconSize,
          iconSize,
          theme.palette.secondary.light
        )
      )}
    </ErrorHandling>
  );
};

export default TableMedia;
