import {
  selectQuery,
  selectResultFields,
  selectTypeId,
} from "../../../store/slices/reportsSlice";
import LinkWidgetForm from "./categorized-widget-forms/LinkWidgetForm";
import DoubleValueWidgetForm from "./categorized-widget-forms/DoubleValueWidgetForm";
import MinMaxAverageWidgetForm from "./categorized-widget-forms/MinMaxAverageWidgetForm";
import PercentageWidgetForm from "./categorized-widget-forms/PercentageWidgetForm";
import StatusWidgetForm from "./categorized-widget-forms/StatusWidgetForm";
import PieChartWidgetForm from "./categorized-widget-forms/PieChartWidgetForm";
import DonutChartWidgetForm from "./categorized-widget-forms/DonutChartWidgetForm";
import LineChartWidgetForm from "./categorized-widget-forms/LineChartWidgetForm";
import BarChartWidgetForm from "./categorized-widget-forms/BarChartWidgetForm";
import FunnelChartWidgetForm from "./categorized-widget-forms/FunnelChartWidgetForm";
import AreaChartWidgetForm from "./categorized-widget-forms/AreaChartWidgetForm";
import { useReportExecutionBaseQuery } from "../../../store/slices/api/reportsApiSlice";
import {
  constructAggregations,
  parseQueryExecute,
  WIDGET_CATEGORY,
} from "../../../util/reports-utils";
import { selectUser } from "../../../store/slices/authSlice";
import { useSelector } from "react-redux";
import SingleValueWidgetForm from "./categorized-widget-forms/SingleValueWidgetForm";

const WidgetForm = ({ category }) => {
  // Selectors
  const user = useSelector(selectUser);
  const query = useSelector(selectQuery);
  const resultFields = useSelector(selectResultFields);
  const typeId = useSelector(selectTypeId);

  // Other variables
  const organizationId = user?.organizations?.find((o) => o.default)?.id;

  const fields = resultFields.map((field, i) => {
    return {
      field: {
        name: field.field,
      },
      displayName: field.displayName,
      index: i,
      orderIndex: field.orderIndex,
      sortMethod: field.sortMethod,
      usedForGrouping: field.usedForGrouping,
    };
  });

  const finalAggregations = constructAggregations(resultFields);
  const filters = [parseQueryExecute(query)];

  // Queries
  const { data: reportExecutionData } = useReportExecutionBaseQuery({
    organizationId,
    typeId,
    fields,
    filters,
    aggregations: finalAggregations,
  });

  return (
    <>
      {/* Line report (without grouping)*/}
      {/* {category === WIDGET_CATEGORY.LINK && <LinkWidgetForm />} */}

      {category === WIDGET_CATEGORY.SINGLE_VALUE && (
        <SingleValueWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.MIN_MAX_AVERAGE && (
        <MinMaxAverageWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.DOUBLE_VALUE && (
        <DoubleValueWidgetForm rows={reportExecutionData?.data} />
      )}

      {/* {category === WIDGET_CATEGORY.PERCENTAGE && <PercentageWidgetForm />} */}

      {category === WIDGET_CATEGORY.STATUS && (
        <StatusWidgetForm rows={reportExecutionData?.data} />
      )}

      {/* Summary report (with grouping)*/}
      {category === WIDGET_CATEGORY.PIE_CHART && (
        <PieChartWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.DONUT_CHART && (
        <DonutChartWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.LINE_CHART && (
        <LineChartWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.BAR_CHART && (
        <BarChartWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.FUNNEL_CHART && (
        <FunnelChartWidgetForm rows={reportExecutionData?.data} />
      )}

      {category === WIDGET_CATEGORY.AREA_CHART && (
        <AreaChartWidgetForm rows={reportExecutionData?.data} />
      )}
    </>
  );
};

export default WidgetForm;
