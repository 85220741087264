import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, useTheme } from "@mui/material";
import {
  AssetCategory,
  AssetInfoDetailsContainer,
  AssetName,
} from "../../../components/styles/assets/Heading.styles";
import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
} from "../../../store/slices/api/assetManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import {
  CATEGORY_SELECT_LIST,
  getPermissionsFromUserRoles,
  getTranslation,
  hasAccess,
  permissions,
  RESOURCE_CATEGORIES,
  transitionDirections,
} from "../../../util/utils";
import {
  DEFAULT_INDEX,
  selectGlobalFontSize,
  setIndex,
} from "../../../store/slices/appSlice";
import { useGetResourceImageQuery } from "../../../store/slices/api/resourceImagesApiSlice";
import CanvasImage from "../../common/CanvasImage";
import ErrorHandling from "../../common/ErrorHandling";
import { getSvgIcon } from "../../../util/icons";

const Heading = ({
  resourceid,
  resourceName,
  functionName,
  thumbnailUri,
  currentResourceFunction,
  userRoles,
  category,
  isFavorite,
}) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // Selectors
  const user = useSelector(selectUser);
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Queries
  const {
    data: resourceImageUri,
    isLoading: isLoadingImage,
    isError: isErrorImage,
  } = useGetResourceImageQuery(
    {
      imageUri: thumbnailUri,
      organizationId: user?.organizations?.find((o) => o.default).id,
      thumbnail: true,
    },
    {
      skip: !thumbnailUri,
    }
  );

  // Mutations
  const [addFavorite] = useAddFavoriteMutation();
  const [deleteFavorite] = useDeleteFavoriteMutation();

  // State
  const [image, setImage] = useState(null);

  // Other variables
  const categoryLabel = CATEGORY_SELECT_LIST.find(
    (rt) => rt.value === category
  )?.label;

  const iconSize = globalFontSize * 1.5;
  const imageWidth = 120;
  const imageHeight = 70;

  // Handlers
  const handleAddFavorite = async (e) => {
    e.stopPropagation();

    try {
      await addFavorite({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to add favorite", error);
    }
  };

  const handleDeleteFavorite = async (e) => {
    e.stopPropagation();

    try {
      await deleteFavorite({
        resourceid,
        organizationId: user?.organizations?.find((o) => o.default)?.id,
      }).unwrap();

      dispatch(setIndex(DEFAULT_INDEX));
    } catch (error) {
      console.error("Failed to delete favorite", error);
    }
  };

  const handleOpenEdit = () => {
    navigate(
      `/resources/${resourceid}/edit?direction=${transitionDirections.RIGHT_TO_LEFT}`
    );
  };

  // Effects
  useEffect(() => {
    if (resourceImageUri) {
      setImage(resourceImageUri);
    }
  }, [resourceImageUri]);

  return (
    <ErrorHandling isLoading={isLoadingImage} isError={isErrorImage}>
      <Grid sx={{ maxHeight: "74px" }} container alignItems="center">
        <Grid item xs={4}>
          {image && thumbnailUri ? (
            <CanvasImage
              id={thumbnailUri}
              width={imageWidth}
              height={imageHeight}
              image={image}
            />
          ) : (
            getSvgIcon(
              functionName?.toUpperCase(),
              imageWidth,
              imageHeight,
              theme.palette.secondary.light
            )
          )}
        </Grid>

        <Grid item xs={8}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <AssetInfoDetailsContainer>
                {/* Resource Name */}
                <AssetName variant="h6">{resourceName}</AssetName>
                {/* Resource category */}
                <AssetCategory variant="subtitle1">
                  {getTranslation("CATEGORY", t, i18n)}:{" "}
                  {getTranslation(categoryLabel, t, i18n)}
                </AssetCategory>
              </AssetInfoDetailsContainer>
            </Box>

            <Box sx={{ display: "flex" }}>
              {isFavorite ? (
                <IconButton onClick={(e) => handleDeleteFavorite(e)}>
                  {getSvgIcon(
                    "STAR_FILLED",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>
              ) : (
                <IconButton onClick={(e) => handleAddFavorite(e)}>
                  {getSvgIcon(
                    "STAR",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>
              )}

              {hasAccess(
                "all",
                [
                  permissions[
                    `ASSET_MANAGEMENT_${
                      currentResourceFunction?.category ===
                      RESOURCE_CATEGORIES.HARDWARE_ASSET
                        ? "HARDWARE"
                        : currentResourceFunction?.category
                    }_EDIT`
                  ],
                ],
                getPermissionsFromUserRoles(userRoles)
              ) && (
                <IconButton onClick={handleOpenEdit}>
                  {getSvgIcon(
                    "EDIT",
                    iconSize,
                    iconSize,
                    theme.palette.secondary.contrastText
                  )}
                </IconButton>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ErrorHandling>
  );
};

export default Heading;
