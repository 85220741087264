import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import { getSvgIcon } from "../../../util/icons";

const WidgetSelectionCircle = ({
  backgroundColor,
  borderColor,
  showCheck,
  widget,
  onClick = () => {},
}) => {
  // General hooks
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);

  // Other variables
  const iconSize = globalFontSize;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClick(widget);
      }}
      style={{
        width: `${iconSize + 10}px`,
        height: `${iconSize + 10}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        backgroundColor,
        borderColor,
      }}
    >
      {showCheck &&
        getSvgIcon(
          "CHECK",
          iconSize,
          iconSize,
          theme.palette.primary.contrastText
        )}
    </div>
  );
};

export default WidgetSelectionCircle;
