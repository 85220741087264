import { alpha, createTheme } from "@mui/material";

const mainTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000000",
      contrastText: "#FFFFFF",
      dark: "#FFFFFF",
      light: "#FFFFFF",
      border: "#D9D9D9",
      upload: "#f4fbff",
    },
    secondary: {
      main: "#000000",
      contrastText: "#0076BC",
      dark: "#808080",
      light: "#F5F5F5",
      active: "#EEEEEE",
      line: "#000000",
      frame: "#D3d3d3",
      activeContrast: alpha("#0076BC", 0.1),
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    // Name of the component
    MuiFormGroup: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: "15px",
          width: "100%",
          opacity: "1",
          /* fontFamily: "var(--font-family-roboto)", */
          fontWeight: "400",
          fontStyle: "normal",
          letterSpacing: "0, 15px",
          textAlign: "left",
          lineHeight: "24px",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: "5px !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "initial",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        asterisk: {
          color: "#00b5e2",
          marginLeft: "7px",
          "&$error": {
            color: "red",
          },
        },
      },
    },
  },
});

export default mainTheme;
