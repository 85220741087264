import { createSlice } from "@reduxjs/toolkit";

// columns' will be an array of objects with the following structure:
// { parentId: string | null, level: number, items: Resource[], columnType: "search" | "list" }

// Initial state
const initialState = {
  columns: [],
  trail: [],
  history: [],
  showDetails: false,
  parentId: null,
  fullWidthColumnId: "",
  overflowX: "auto",
  deletedResourceId: null,
  optimisticItem: null,
};

// Slice
const columnViewSlice = createSlice({
  name: "columnView",
  initialState,
  reducers: {
    addColumn: (state, action) => {
      const { column, sortBy, order, favorites } = action.payload;

      if (!state.columns.find((c) => c.parentId === column.parentId)) {
        if (sortBy === "A-Z" || sortBy === "CATEGORY") {
          const { parentId, items, columnType } = column;

          const sortedColumn = {
            parentId,
            columnType,
            items: items
              .slice()
              .sort((a, b) => {
                const combinationForA = a.displayId || a.name;
                const combinationForB = b.displayId || b.name;

                if (
                  combinationForA.toLowerCase() > combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? 1 : -1;
                } else if (
                  combinationForA.toLowerCase() < combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? -1 : 1;
                } else {
                  return 0;
                }
              })
              .map((item) => {
                const favorite = favorites?.find((f) => f.id === item.id);

                return {
                  ...item,
                  favouredAt: favorite?.createdAt,
                };
              }),
          };

          state.columns.push({
            ...sortedColumn,
            level: state.columns.length + 1,
          });
        } else if (sortBy === "FAVOURED") {
          const { parentId, items, columnType } = column;

          const transformedItems = items.map((item) => {
            const favorite = favorites?.find((f) => f.id === item.id);

            return {
              ...item,
              favouredAt: favorite?.createdAt,
            };
          });

          const favoriteItems = transformedItems
            .filter((item) => Boolean(item.favouredAt))
            .sort((a, b) => {
              const favouredAtA = a.favouredAt;
              const favouredAtB = b.favouredAt;

              const dateA = new Date(favouredAtA);
              const dateB = new Date(favouredAtB);

              if (dateA > dateB) {
                return order === "asc" ? 1 : -1;
              } else {
                return order === "asc" ? -1 : 1;
              }
            });

          const nonFavoriteItems = transformedItems
            .filter((item) => !Boolean(item.favouredAt))
            .sort((a, b) => {
              const combinationForA = a.displayId || a.name;
              const combinationForB = b.displayId || b.name;

              if (
                combinationForA.toLowerCase() > combinationForB.toLowerCase()
              ) {
                return order === "asc" ? 1 : -1;
              } else if (
                combinationForA.toLowerCase() < combinationForB.toLowerCase()
              ) {
                return order === "asc" ? -1 : 1;
              } else {
                return 0;
              }
            });

          const sortedColumn = {
            parentId,
            columnType,
            items:
              order === "asc"
                ? [...nonFavoriteItems, ...favoriteItems]
                : [...favoriteItems, ...nonFavoriteItems],
          };

          state.columns.push({
            ...sortedColumn,
            level: state.columns.length + 1,
          });
        }
      }
    },
    setColumns: (state, action) => {
      const { columns, sortBy, order, favorites } = action.payload;
      let sortedColumns = [];

      if (sortBy === "A-Z" || sortBy === "CATEGORY") {
        columns.forEach((column) => {
          const { parentId, items, level, columnType } = column;

          const sortedColumn = {
            parentId,
            columnType,
            level,
            items: items
              .slice()
              .sort((a, b) => {
                const combinationForA = a.displayId || a.name;
                const combinationForB = b.displayId || b.name;

                if (
                  combinationForA.toLowerCase() > combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? 1 : -1;
                } else if (
                  combinationForA.toLowerCase() < combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? -1 : 1;
                } else {
                  return 0;
                }
              })
              .map((item) => {
                const favorite = favorites?.find((f) => f.id === item.id);

                return {
                  ...item,
                  favouredAt: favorite?.createdAt,
                };
              }),
          };

          sortedColumns.push(sortedColumn);
        });
      } else if (sortBy === "FAVOURED") {
        columns.forEach((column) => {
          const { parentId, items, level, columnType } = column;

          const transformedItems = items.map((item) => {
            const favorite = favorites?.find((f) => f.id === item.id);

            return {
              ...item,
              favouredAt: favorite?.createdAt,
            };
          });

          const favoriteItems = transformedItems
            .filter((item) => Boolean(item.favouredAt))
            .sort((a, b) => {
              const favouredAtA = a.favouredAt;
              const favouredAtB = b.favouredAt;

              const dateA = new Date(favouredAtA);
              const dateB = new Date(favouredAtB);

              if (dateA > dateB) {
                return order === "asc" ? 1 : -1;
              } else {
                return order === "asc" ? -1 : 1;
              }
            });

          const nonFavoriteItems = transformedItems
            .filter((item) => !Boolean(item.favouredAt))
            .sort((a, b) => {
              const combinationForA = a.displayId || a.name;
              const combinationForB = b.displayId || b.name;

              if (
                combinationForA.toLowerCase() > combinationForB.toLowerCase()
              ) {
                return order === "asc" ? 1 : -1;
              } else if (
                combinationForA.toLowerCase() < combinationForB.toLowerCase()
              ) {
                return order === "asc" ? -1 : 1;
              } else {
                return 0;
              }
            });

          const sortedColumn = {
            parentId,
            columnType,
            level,
            items:
              order === "asc"
                ? [...nonFavoriteItems, ...favoriteItems]
                : [...favoriteItems, ...nonFavoriteItems],
          };

          sortedColumns.push(sortedColumn);
        });
      }

      state.columns = sortedColumns;
    },
    sortColumns: (state, action) => {
      let sortedColumns = [];
      const { favorites, sortBy, order } = action.payload;

      if (sortBy === "A-Z" || sortBy === "CATEGORY") {
        state.columns.forEach((column) => {
          const { parentId, items, level, columnType } = column;

          const sortedColumn = {
            parentId,
            columnType,
            level,
            items: items
              .slice()
              .sort((a, b) => {
                const combinationForA = a.displayId || a.name;
                const combinationForB = b.displayId || b.name;

                if (
                  combinationForA.toLowerCase() > combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? 1 : -1;
                } else if (
                  combinationForA.toLowerCase() < combinationForB.toLowerCase()
                ) {
                  return order === "asc" ? -1 : 1;
                } else {
                  return 0;
                }
              })
              .map((item) => {
                const favorite = favorites?.find((f) => f.id === item.id);

                return {
                  ...item,
                  favouredAt: favorite?.createdAt,
                };
              }),
          };

          sortedColumns.push(sortedColumn);
        });
      } else if (sortBy === "FAVOURED") {
        state.columns.forEach((column) => {
          const { parentId, items, level, columnType } = column;

          const transformedItems = items.map((item) => {
            const favorite = favorites?.find((f) => f.id === item.id);

            return {
              ...item,
              favouredAt: favorite?.createdAt,
            };
          });

          const favoriteItems = transformedItems
            .filter((item) => Boolean(item.favouredAt))
            .sort((a, b) => {
              const favouredAtA = a.favouredAt;
              const favouredAtB = b.favouredAt;

              const dateA = new Date(favouredAtA);
              const dateB = new Date(favouredAtB);

              if (dateA > dateB) {
                return order === "asc" ? 1 : -1;
              } else {
                return order === "asc" ? -1 : 1;
              }
            });

          const nonFavoriteItems = transformedItems
            .filter((item) => !Boolean(item.favouredAt))
            .sort((a, b) => {
              const combinationForA = a.displayId || a.name;
              const combinationForB = b.displayId || b.name;

              if (
                combinationForA.toLowerCase() > combinationForB.toLowerCase()
              ) {
                return order === "asc" ? 1 : -1;
              } else if (
                combinationForA.toLowerCase() < combinationForB.toLowerCase()
              ) {
                return order === "asc" ? -1 : 1;
              } else {
                return 0;
              }
            });

          const sortedColumn = {
            columnType,
            parentId,
            level,
            items:
              order === "asc"
                ? [...nonFavoriteItems, ...favoriteItems]
                : [...favoriteItems, ...nonFavoriteItems],
          };

          sortedColumns.push(sortedColumn);
        });
      }

      state.columns = sortedColumns;
    },
    clearColumns: (state) => {
      state.columns = [];
    },
    setTrail: (state, action) => {
      const newTrail = action.payload;
      state.trail = newTrail;
    },
    setShowDetails: (state, action) => {
      const newValue = action.payload;
      state.showDetails = newValue;
    },
    setParentId: (state, action) => {
      const newParentId = action.payload;
      state.parentId = newParentId;
      state.history = [];
    },
    goBack: (state) => {
      if (state.trail.length > 0) {
        const trailIndex = state.trail.length - 2;
        let newParentId = state.trail[trailIndex];

        if (trailIndex < 0) {
          newParentId = null;
        }

        const lastColumnIndex = state.columns.findIndex(
          (c) => c.parentId === newParentId
        );

        state.history = [...state.history, state.parentId];
        state.columns = state.columns.slice(0, lastColumnIndex);
        state.trail = state.trail.slice(0, trailIndex);
        state.parentId = newParentId;
      }
    },
    goForward: (state) => {
      if (state.history.length > 0) {
        const historyIndex = state.history.length - 1;
        const newParentId = state.history[historyIndex];
        state.columns = [...state.columns, newParentId];
        state.trail = [...state.trail, newParentId];
        state.parentId = newParentId;
        state.history = state.history.slice(0, historyIndex);
      }
    },
    setFullWidthColumnId: (state, action) => {
      const roomId = action.payload;
      state.fullWidthColumnId = roomId;
    },
    setOverflowX: (state, action) => {
      const newOverflowX = action.payload;
      state.overflowX = newOverflowX;
    },
    setDeletedResourceId: (state, action) => {
      const newDeletedResourceId = action.payload;
      state.deletedResourceId = newDeletedResourceId;
    },
    setOptimisticItem: (state, action) => {
      const newOptimisticItem = action.payload;
      state.optimisticItem = newOptimisticItem;
    },
  },
});

// Export slice and its corresponding actions
export default columnViewSlice.reducer;
export const {
  addColumn,
  setColumns,
  sortColumns,
  clearColumns,
  setTrail,
  setShowDetails,
  setParentId,
  goBack,
  goForward,
  setFullWidthColumnId,
  setOverflowX,
  setDeletedResourceId,
  setOptimisticItem,
} = columnViewSlice.actions;

// Selectors
export const selectColumns = (state) =>
  state.columnView.columns.slice().sort((a, b) => a.level - b.level);
export const selectTrail = (state) => state.columnView.trail;
export const selectShowDetails = (state) => state.columnView.showDetails;
export const selectParentId = (state) => state.columnView.parentId;
export const selectColumnViewHistory = (state) => state.columnView.history;
export const selectFullWidthColumnId = (state) =>
  state.columnView.fullWidthColumnId;
export const selectOverflowX = (state) => state.columnView.overflowX;
export const selectDeletedResourceId = (state) =>
  state.columnView.deletedResourceId;
export const selectOptimisticItem = (state) => state.columnView.optimisticItem;
