import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  useDeleteProfileImageMutation,
  useGetUserProfileImageQuery,
  useUploadProfileImageMutation,
} from "../../store/slices/api/userManagementSlice";
import { selectUser, setUser } from "../../store/slices/authSlice";
import { messageError, messageSuccess } from "../../util/notification";
import { getTranslation } from "../../util/utils";
import {
  ProfileAvatarContainer,
  RemoveImageButton,
} from "../styles/profile/ProfileDesktop.styles";
import UploadImageDesktop from "./UploadImageDesktop";
import ErrorHandling from "../common/ErrorHandling";

const ProfileAvatarDesktopHeading = () => {
  // General hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Selectors
  const user = useSelector(selectUser);

  // Queries
  const { data: selectedFile } = useGetUserProfileImageQuery(
    {
      avatarUri: user?.avatarUri,
      thumbnail: true,
    },
    {
      skip: !Boolean(user?.avatarUri),
    }
  );

  // Mutations
  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadProfileImageMutation();

  const [deleteProfileImage, { isLoading: isLoadingDeleteProfileImage }] =
    useDeleteProfileImageMutation();

  // State
  const [openConfirm, setOpenConfirm] = useState(false);

  // Handlers
  const submitUploadedImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let resp = await uploadImage({ formData, userId: user.id }).unwrap();
      dispatch(setUser({ ...user, avatarUri: resp.avatarUri }));
      messageSuccess(getTranslation("successfulUpdateUserImage", t, i18n));
    } catch (error) {
      messageError(getTranslation("failedUpdateUserImage", t, i18n));
    }
  };

  const handleDeleteProfileImage = async () => {
    try {
      await deleteProfileImage({ userId: user.id }).unwrap();
      dispatch(setUser({ ...user, avatarUri: "" }));
      messageSuccess(getTranslation("PROFILE_IMAGE_DELETED", t, i18n));
    } catch (error) {
      console.error("Failed to delete profile image");
    }
  };

  const handleConfirm = async () => {
    await handleDeleteProfileImage();
  };

  return (
    <ErrorHandling
      isLoading={isLoadingUploadImage || isLoadingDeleteProfileImage}
      isError={false}
    >
      <ConfirmAlert
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        alert={{
          content: getTranslation("ASSET_IMAGE_DELETE_MESSAGE", t, i18n),
          confirmTitle: getTranslation("DELETE_ASSET_IMAGE", t, i18n),
          closeTitle: getTranslation("CANCEL", t, i18n),
          showConfirm: true,
        }}
        label="delete-image"
        handleConfirm={handleConfirm}
      />

      <ProfileAvatarContainer>
        <UploadImageDesktop
          initialFile={user?.avatarUri ? selectedFile : null}
          submitUploadedImage={submitUploadedImage}
          uploadTitle={
            selectedFile && user?.avatarUri ? "EDIT_IMAGE" : "ADD_IMAGE"
          }
          deleteImage={handleDeleteProfileImage}
        />

        <RemoveImageButton
          disabled={!Boolean(user?.avatarUri) || !Boolean(selectedFile)}
          onClick={() => setOpenConfirm(true)}
          variant="text"
        >
          {getTranslation("REMOVE_TAG", t, i18n)}
        </RemoveImageButton>
      </ProfileAvatarContainer>
    </ErrorHandling>
  );
};

export default ProfileAvatarDesktopHeading;
