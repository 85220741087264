import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ErrorHandling from "../../common/ErrorHandling";
import { selectGlobalFontSize } from "../../../store/slices/appSlice";
import {
  Grid,
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  EmptyCell,
  ListTable,
  SecondaryContrastColorCell,
} from "../../styles/assets/asset-list/AssetListTable.styles";
import { useTranslation } from "react-i18next";
import InfiniteScrollLoader from "../../InfiniteScrollLoader";
import {
  LoadMoreWrapper,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "../../styles/types/type-list/TypeListPage.styles";
import {
  NoDataTextContainer,
  SecondaryContrastButton,
} from "../../styles/general/General.styles";
import { getTranslation, transitionDirections } from "../../../util/utils";
import { useGetTypeRecentsQuery } from "../../../store/slices/api/typesApiSlice";
import { useGetAllFunctionsQuery } from "../../../store/slices/api/assetManagementSlice";
import { selectUser } from "../../../store/slices/authSlice";
import { SecondaryText } from "../../styles/assets/ListInlineView.styles";
import { useNavigate } from "react-router-dom";
import { VIEWPORT_MEDIA_QUERIES } from "../../../util/viewport-utils";
import { CreateAssetButton } from "../../styles/assets/AssetList.styles";
import { getSvgIcon } from "../../../util/icons";
import TypeListTableRow from "./TypeListTableRow";
import TypeListInlineViewItem from "./TypeListInlineViewItem";
import {
  selectIndex,
  selectItems,
  setIndex,
  setItems,
} from "../../../store/slices/typeSlice";
import { useDispatch } from "react-redux";

const PAGE_SIZE = 20;

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: "CATEGORY",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "resource.createdBy",
  },
];

const RecentTypesAccordion = ({ handleClickItem, currentTypeId }) => {
  // General hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const tabletMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.TABLET);
  const desktopMatches = useMediaQuery(VIEWPORT_MEDIA_QUERIES.DESKTOP);
  const theme = useTheme();

  // Selectors
  const globalFontSize = useSelector(selectGlobalFontSize);
  const user = useSelector(selectUser);
  const index = useSelector(selectIndex);
  const items = useSelector(selectItems);

  // States
  const [expand, setExpand] = useState(true);
  const [fetchingItems, setFetchingItems] = useState(false);

  // Other variables
  const organizationId = user?.organizations.find((o) => o.default)?.id;
  const iconSize = globalFontSize * 1.2;

  // Queries
  const {
    data: typeRecentsData,
    isLoading: isLoadingTypeRecents,
    isFetching: isFetchingRecents,
    isError: isErrorRecents,
  } = useGetTypeRecentsQuery({
    organizationId,
    index,
    size: PAGE_SIZE,
    order: "desc",
    sortBy: "createdAt",
  });

  const { data: allFunctionsData } = useGetAllFunctionsQuery({
    organizationId: user?.organizations.find((o) => o.default).id,
  });

  // Other variables
  const recents = typeRecentsData?.data;
  const totalRecord = typeRecentsData?.totalRecord;
  const fetching = isFetchingRecents || fetchingItems;
  const pageCount = typeRecentsData?.pageCount;

  // Handlers
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const fetchMoreData = () => {
    setFetchingItems(true);
    setTimeout(() => {
      dispatch(setIndex(index + 1));
      setFetchingItems(false);
    }, 1000);
  };

  const handleNavigateToLibrary = () => {
    if (desktopMatches) {
      navigate(`/library`);
    } else {
      navigate(`/library?direction=${transitionDirections.RIGHT_TO_LEFT}`);
    }
  };

  // Effects
  useEffect(() => {
    if (typeRecentsData && allFunctionsData) {
      let result = recents?.slice() ?? [];

      if (index > 1) {
        const noDuplicatedRecents = result.filter(
          (f) => !items?.some((i) => i?.id === f?.id)
        );

        result = items?.concat(noDuplicatedRecents) ?? [];
      }

      dispatch(setItems(result));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeRecentsData, allFunctionsData, index]);

  return (
    <ErrorHandling isLoading={isLoadingTypeRecents} isError={isErrorRecents}>
      <StyledAccordion
        id="library-accordion"
        expanded={expand}
        onChange={toggleExpand}
      >
        <StyledAccordionSummary
          expandIcon={getSvgIcon(
            "ARROW_RIGHT",
            iconSize,
            iconSize,
            theme.palette.secondary.light
          )}
        >
          <SecondaryText>{`${getTranslation("LIBRARY", t, i18n)} (${
            items.length
          }/${totalRecord})`}</SecondaryText>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {items.length > 0 ? (
            desktopMatches ? (
              <TableContainer>
                <ListTable sx={{ minWidth: "0px" }} aria-label="simple table">
                  <TableRow>
                    <EmptyCell></EmptyCell>
                    {headCells.map((headCell) => (
                      <SecondaryContrastColorCell
                        key={headCell.id}
                        align={"left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                      >
                        {getTranslation(headCell.label, t, i18n)}
                      </SecondaryContrastColorCell>
                    ))}
                    <EmptyCell></EmptyCell>
                  </TableRow>
                  <TableBody>
                    {items.map((type) => (
                      <TypeListTableRow
                        key={type?.id}
                        type={type}
                        currentTypeId={currentTypeId}
                        handleClickItem={handleClickItem}
                      />
                    ))}
                  </TableBody>
                </ListTable>
              </TableContainer>
            ) : (
              <Grid
                container
                display="flex"
                justifyContent="start"
                columns={36}
                rowGap={tabletMatches ? 2 : 0}
              >
                {items?.map((row) => (
                  <TypeListInlineViewItem
                    key={row?.id}
                    row={row}
                    handleClickItem={handleClickItem}
                  />
                ))}
              </Grid>
            )
          ) : (
            <NoDataTextContainer>
              <SecondaryText align="center">
                {getTranslation("NO_RECENT_LIBRARY", t, i18n)}
              </SecondaryText>
              <CreateAssetButton onClick={handleNavigateToLibrary}>
                {getTranslation("OPEN_LIBRARY", t, i18n)}
              </CreateAssetButton>
            </NoDataTextContainer>
          )}
          {fetching ? (
            <InfiniteScrollLoader />
          ) : (
            <>
              {index < pageCount && (
                <LoadMoreWrapper>
                  <SecondaryContrastButton onClick={fetchMoreData}>
                    {getTranslation("LOAD_MORE", t, i18n)}
                  </SecondaryContrastButton>
                </LoadMoreWrapper>
              )}
            </>
          )}
        </StyledAccordionDetails>
      </StyledAccordion>
    </ErrorHandling>
  );
};

export default RecentTypesAccordion;
